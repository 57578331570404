export const TAB_INICIO = 'inicio';
export const TAB_MI_PERFIL = 'mi-perfil';
export const TAB_INFO_PERSONAL = 'info-personal';
export const TAB_ELEMENTOS_GUARDADOS = 'elementos-guardados';
export const TAB_MENSAJERIA = 'mensajeria';
export const TAB_SUBIR_OFERTA = 'subir-oferta';
export const TAB_MIS_OFERTAS = 'mis-oferta';
export const TAB_CAMBIAR_PASS = 'cambio-pass';
export const TAB_LOGOUT = 'logout';
export const TAB_CONVO = 'convocatorias';
export const TAB_ESPACIO_CULTURAL = 'espacio-cultural-datos';
export const TAB_ORGANIZACION_DETALLE = 'organizacion-datos';

export const DEFAULT_ACTIVE_TAB = TAB_INICIO;