import { ErrorMessage, Field, Form, Formik, useField, useFormikContext } from "formik";
import { Fragment, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Titulo from "../components/utiles/Titulo";
import { checkIfFileIsTooBig, getBase64, regexTelefono, setTitle, validateImageType } from "../constants/utiles/Functions";
import * as Yup from 'yup';
import { getDateNow } from "../constants/utiles/DateFunctions";
import ValidationError from "../components/utiles/ValidationError";
import { REGISTRO_DIRECTORIO, USUARIO_GET_URI } from "../api/axios/constants";
import axiosApi from "../api/axios/axiosApi";
import toast from "react-hot-toast";
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from "react-redux";

import { setIdDirectorio, setUserDB } from "../redux/features/userActiveSlice";
import FieldComuna from "../components/form/FieldComuna";
import { useGetGenerosQuery, useGetRegionesQuery, useGetTipoEspaciosCulturalesQuery, useGetTipoPersonasQuery } from "../redux/features/ApiSlice";
import { TIPO_USUARIO_PERSONA } from "../constants/Constants";


const Registro = (params) => {
    const [arrayComunas, setArrayComunas] = useState([]);
    const [loading, setLoading] = useState(true);

    const { userInfo } = useSelector((state) => state.userActive)
    const [file, setFile] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        data: regiones = [],
        isLoading: regionesLoad,
        isError: regionesIsError,
        error: regionesError
    } = useGetRegionesQuery()

    const {
        data: generos = [],
        isLoading: generosLoad,
        isError: generosIsError,
        error: generosError
    } = useGetGenerosQuery();

    const {
        data: tipoPersonas = [],
        isLoading: tipoPersonasLoad,
    } = useGetTipoPersonasQuery();

    const {
        data: tipoEspacios = [],
        isLoading: tipoEspaciosLoad,
    } = useGetTipoEspaciosCulturalesQuery();

    useEffect(() => {
        if (!regionesLoad && !generosLoad && !tipoEspaciosLoad && !tipoPersonasLoad) {
            setLoading(false)
        }
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regionesLoad, generosLoad, tipoEspaciosLoad, tipoPersonasLoad]);

    useEffect(() => {
        setTitle(params.title)
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadComunas = (region) => {
        setLoading(true);

        setArrayComunas(region)
        setLoading(false);



    }


    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const initialValues = {
        genero: '',
        fechaNacimiento: '',
        originario: '0',
        originarioDetalle: '',
        migrante: '0',
        migranteDetalle: '',
        discapacidad: '0',
        discapacidadDetalle: '',
        terceraEdad: '0',
        email: '',
        confirmEmail: '',
        telefono: '',
        confirmTelefono: '',
        region: '',
        comuna: '',
        direccion: '',
        condiciones: false,
        imagen: '',
        id_tipo_persona: '',
        tipoEspacio: '',
        espacioCultural: '0',
        web: '',
        youtube: '',
        facebook: '',
        instagram: '',
        otra_rrss: '',
    };


    const validationSchema = Yup.object({
        genero: Yup.string().required('Debe seleccionar una opción de género'),
        fechaNacimiento: Yup.date().required('Campo requerido').max(now, 'Debe seleccionar una fecha menor a la de hoy'),
        email: Yup.string().email('Correo electronico ingresado invalido').required('Campo requerido').max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        confirmEmail: Yup.string().email('Correo electronico ingresado invalido').required('Campo requerido').when("email", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("email")],
                "Ambos correos electronicos deben ser el mismo"
            )
        }).max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        telefono: Yup.string().matches(regexTelefono, 'Formato de numero de telefono invalido').required('Campo requerido').max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        confirmTelefono: Yup.string().matches(regexTelefono, 'Formato de numero de telefono invalido').required('Campo requerido').when("telefono", {
            is: val => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf(
                [Yup.ref("telefono")],
                "Ambos números de telefono deben ser el mismo"
            )
        }).max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        region: Yup.string().required('Campo requerido'),
        comuna: Yup.string().required('Campo requerido'),
        direccion: Yup.string().required('Campo requerido').max(200, 'El texto ingresado no debe sobrepasar los 200 caracteres'),
        condiciones: Yup.bool().isTrue('Necesita aceptar los terminos y condiciones'),
        originarioDetalle: Yup.string().when("originario", {
            is: '1',
            then: Yup.string().required('Campo requerido')
        }).max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        migranteDetalle: Yup.string().when("migrante", {
            is: '1',
            then: Yup.string().required('Campo requerido')
        }).max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        discapacidadDetalle: Yup.string().when("discapacidad", {
            is: '1',
            then: Yup.string().required('Campo requerido')
        }).max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        tipoPersona: Yup.string().required('Campo requerido'),
        imagen: Yup.mixed()
            .required('Imagen requerida')
            .test('is-correct-file', 'El archivo no puede sobrepasar los 5 mb', checkIfFileIsTooBig)
            .test(
                'is-big-file',
                'Solo se aceptan los siguientes formatos de archivo: .jpeg, .jpg y .gif',
                validateImageType
            ),
        tipoEspacio: Yup.string().when("espacioCultural", {
            is: '1',
            then: Yup.string().required('Campo requerido')
        }),
        web: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        youtube: Yup.string().max(200, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        facebook: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 200 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        instagram: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        otra_rrss: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),

    })

    const handleOnSubmit = (values) => {
        setLoading(true);
        const toastId = toast.loading('Cargando...');
        const data = {
            nombre: userInfo.nombre ?? null,
            rut: userInfo.rut ?? null,
            fecha_nacimiento: values.fechaNacimiento,
            pueblo_originario: values.originario === '1' ? "true" : "false",
            pueblo_originario_detalle: values.originario === '1' ? values.originarioDetalle : null,
            poblacion_migrante: values.migrante === '1' ? "true" : "false",
            poblacion_migrante_detalle: values.migrante === '1' ? values.migranteDetalle : null,
            discapacidad: values.discapacidad === '1' ? "true" : "false",
            discapacidad_detalle: values.discapacidad === '1' ? values.discapacidadDetalle : null,
            tercera_edad: values.terceraEdad === '1' ? "true" : "false",
            email: values.email,
            telefono: values.telefono,
            direccion: values.direccion,
            id_comuna: values.comuna,
            id_genero: values.genero,
            espacio_cultural: values.espacioCultural === '1' ? "true" : "false",
            id_tipo_perfil: TIPO_USUARIO_PERSONA,
            id_tipo_persona: values.tipoPersona,
            id_tipo_organizacion: null,
            id_tipo_espacio_cultural: values.espacioCultural === '1' ? values.tipoEspacio : null,
            web: values.web,
            youtube: values.youtube,
            facebook: values.facebook,
            instagram: values.instagram,
            otra_rrss: values.otra_rrss
        }


        getBase64(values.imagen)
            .then(result => {
                const base64result = result.split(',')[1];
                data['imagen_name'] = values.imagen.name;
                data['imagen_data'] = base64result;
                data['imagen_mime_type'] = values.imagen.type;
            }).then(() => {
                axiosApi.post(REGISTRO_DIRECTORIO, data
                )
                    .then(response => {
                        if (response.data.error === true) {
                            toast.error(response.data.message, {
                                id: toastId,
                            });

                        } else {
                            dispatch(setUserDB(true));

                            axiosApi.get(USUARIO_GET_URI(userInfo.rut))
                                .then(response_user => {
                                    toast.success('Registro completado', {
                                        id: toastId,
                                    });
                                    dispatch(setIdDirectorio(response_user.data.data[0].idDirectorio))
                                    setLoading(false)
                                    navigate(`/home-privado/inicio`, { replace: true });
                                })
                                .catch((error) => {
                                    setLoading(false)
                                    console.error(error)
                                });
                        }
                    }).catch((error) => {
                        toast.error('Ha ocurrido un error al registrarse', {
                            id: toastId,
                        });

                        console.error(error);
                        setLoading(false)

                    });
            })
            .catch(err => {
                console.log(err);
            });



    }

    return (
        <Fragment>
            <Titulo>
                <div className="breadcrumbs">
                    <ul>
                        <li><Link to="/">Inicio</Link></li>
                        <li><Link className="bc" style={{ borderRight: "solid 0px #ccc !important" }} >Iniciar sesión</Link></li>
                    </ul>
                </div>
                <div className="clear"></div>
                <span className="t1b mb-4">Te damos la bienvenida al Catálogo digital</span>

                <p className="p1b">El Catálogo Digital para la programación cultural local es un registro web del Ministerio de las Culturas, las
                    Artes y el Patrimonio, que busca generar contactos y redes entre agentes culturales, personas y organizaciones, programadores de
                    actividades artísticas y oferentes de obras o proyectos artísticos culturales a lo largo de todo Chile.</p>

                <p>Es una herramienta en constante construcción colaborativa, abierta a la ciudadanía y las comunidades     </p>

                <p className="fw-bold">Aquí podrás acceder con tu cuenta registrada o con clave única, si tienes dificultades de acceso escríbenos al formulario de atención.</p>

            </Titulo>


            <div className="gris2">
                <div className="container">

                    <div className="row">
                        <div className="col">
                            <span style={{ color: "#888" }} className="txt1">¿Tienes problemas para registrarte?  <Link to='/ayuda/contacto'>Consulta aquí</Link></span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form rounded mb-4">
                            <span className="tform">Regístrate en Catálogo Digital</span>

                            <div className="row justify-content-md-center mt-4">
                                <div className="col-md-9">
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleOnSubmit}

                                    >
                                        {props => (
                                            <Form className="row g-3">
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="nombre" className="form-label">Nombre completo</label>
                                                    <p>{userInfo.nombre ?? null}</p>


                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="rut" className="form-label">RUT</label>
                                                    <p>{userInfo.rut ?? null}</p>
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="genero" className="form-label">Con qué género te identificas<span className="rojo">*</span></label>
                                                    <Field name="genero" as="select" className="form-control" >
                                                        <option value='' className="text-muted">Seleccionar</option>
                                                        {
                                                            generosLoad ? "" : generos.data.map((genero, index) => <option key={index} value={genero.idGenero}>
                                                                {genero.genero}
                                                            </option>)
                                                        }
                                                    </Field>
                                                    <ErrorMessage name="genero" component={ValidationError} />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="fechaNacimiento" className="form-label">Fecha de nacimiento <span className="rojo">*</span></label>
                                                    <Field name="fechaNacimiento" type="date" className="form-control" max={getDateNow()} />
                                                    <ErrorMessage name="fechaNacimiento" component={ValidationError} />
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-6 mb-3  mt-3">
                                                        <label className="form-label">¿Espacio de cultura? <span className="rojo">*</span></label>
                                                        <div className="clear"></div>
                                                        <div className="form-check form-check-inline ">
                                                            <Field className="form-check-input" type="radio" name="espacioCultural" id="espacio_cultural_option1" value={'1'} />
                                                            <label className="form-check-label" htmlFor="espacio_cultural_option1">Si</label>
                                                        </div>
                                                        <div className="form-check form-check-inline ">
                                                            <Field className="form-check-input" type="radio" name="espacioCultural" id="espacio_cultural_option2" value={'0'} />
                                                            <label className="form-check-label" htmlFor="espacio_cultural_option2">No</label>
                                                        </div>

                                                        {props.values.espacioCultural === '1' ?
                                                            <div className="mb-0 animation_input">
                                                                <label htmlFor="tipoEspacio" className="form-label">Tipo de Espacio cultural <span className="rojo">*</span></label>
                                                                <Field name="tipoEspacio" as="select" className="form-control" >
                                                                    <option value={''}>Seleccionar</option>
                                                                    {tipoEspaciosLoad ? "" : tipoEspacios.data.map((tipo, index) => <option key={index} value={tipo.idEspacioCultural}>{tipo.espacioCultural}</option>)}

                                                                </Field>
                                                                <ErrorMessage name="tipoEspacio" component={ValidationError} />
                                                            </div> : ''
                                                        }

                                                    </div>

                                                    <div className="col-md-6 mb-3  mt-3">
                                                        <label className="form-label" >¿Perteneces a algún pueblo originario?</label>
                                                        <div className="clear"></div>
                                                        <div className="form-check form-check-inline">
                                                            <Field className="form-check-input" type="radio" name="originario" id="originario_option1" value={'1'} />
                                                            <label className="form-check-label" htmlFor="originario_option1">Si</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <Field className="form-check-input" type="radio" name="originario" id="originario_option2" value={'0'} />
                                                            <label className="form-check-label" htmlFor="originario_option2">No</label>
                                                        </div>
                                                        {props.values.originario === '1' ?
                                                            <div className="mb-0 animation_input">
                                                                <label htmlFor="originarioDetalle" className="form-label">¿Cuál? <span className="rojo">*</span></label>
                                                                <Field type="text" className='form-control' id="originarioDetalle" name='originarioDetalle' />
                                                                <ErrorMessage name="originarioDetalle" component={ValidationError} />
                                                            </div> : ''
                                                        }
                                                    </div>

                                                    <div className="col-md-6 mb-3  mt-3">
                                                        <label className="form-label">¿Perteneces a población migrante?</label>
                                                        <div className="clear"></div>
                                                        <div className="form-check form-check-inline">
                                                            <Field className="form-check-input" type="radio" name="migrante" id="migrante_option1" value={'1'} />
                                                            <label className="form-check-label" htmlFor="migrante_option1">Si</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <Field className="form-check-input" type="radio" name="migrante" id="migrante_option2" value={'0'} />
                                                            <label className="form-check-label" htmlFor="migrante_option2">No</label>
                                                        </div>

                                                        {props.values.migrante === '1' ?
                                                            <div className="mb-0 animation_input">
                                                                <label htmlFor="migranteDetalle" className="form-label">¿Cuál? <span className="rojo">*</span></label>
                                                                <Field type="text" className='form-control' id="migranteDetalle" name='migranteDetalle' />
                                                                <ErrorMessage name="migranteDetalle" component={ValidationError} />
                                                            </div> : ''
                                                        }
                                                    </div>

                                                    <div className="col-md-6 mb-3  mt-3">
                                                        <label className="form-label">¿Posees alguna discapacidad?</label>
                                                        <div className="clear"></div>
                                                        <div className="form-check form-check-inline">
                                                            <Field className="form-check-input" type="radio" name="discapacidad" id="discapacidad_option1" value={'1'} />
                                                            <label className="form-check-label" htmlFor="discapacidad_option1">Si</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <Field className="form-check-input" type="radio" name="discapacidad" id="discapacidad_option1" value={'0'} />
                                                            <label className="form-check-label" htmlFor="discapacidad_option1">No</label>
                                                        </div>

                                                        {props.values.discapacidad === '1' ?
                                                            <div className="mb-0 animation_input">
                                                                <label htmlFor="discapacidadDetalle" className="form-label">¿Cuál? <span className="rojo">*</span></label>
                                                                <Field type="text" className='form-control' id="discapacidadDetalle" name='discapacidadDetalle' />
                                                                <ErrorMessage name="discapacidadDetalle" component={ValidationError} />
                                                            </div> : ''
                                                        }
                                                    </div>



                                                    <div className="col-md-6 mb-3  mt-3">
                                                        <label className="form-label">¿Perteneces a la tercera edad?</label>
                                                        <div className="clear"></div>
                                                        <div className="form-check form-check-inline">
                                                            <Field className="form-check-input" type="radio" name="terceraEdad" id="tercera_edad_option1" value={'1'} />
                                                            <label className="form-check-label" htmlFor="tercera_edad_option1">Si</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <Field className="form-check-input" type="radio" name="terceraEdad" id="tercera_edad_option2" value={'0'} />
                                                            <label className="form-check-label" htmlFor="tercera_edad_option2">No</label>
                                                        </div>

                                                    </div>



                                                </div>


                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="email" className="form-label">Correo electrónico <span className="rojo">*</span></label>
                                                    <Field type="email" className='form-control' id="email" name='email' />
                                                    <ErrorMessage name="email" component={ValidationError} />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="confirmEmail" className="form-label">Confirma tu correo electrónico<span className="rojo">*</span></label>
                                                    <Field type="email" className='form-control' id="confirmEmail" name='confirmEmail' />
                                                    <ErrorMessage name="confirmEmail" component={ValidationError} />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="telefono" className="form-label">Teléfono <span className="rojo">*</span></label>
                                                    <Field type="text" className='form-control' id="telefono" name="telefono" />
                                                    <ErrorMessage name="telefono" component={ValidationError} />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="confirmTelefono" className="form-label">Confirma tu teléfono <span className="rojo">*</span></label>
                                                    <Field type="text" className='form-control' id="confirmTelefono" name="confirmTelefono" />
                                                    <ErrorMessage name="confirmTelefono" component={ValidationError} />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="region" className="form-label">Región <span className="rojo">*</span></label>

                                                    <Field className='form-control' id="region" name="region" as='select' >
                                                        <option value='' className="text-muted">Seleccionar</option>
                                                        {
                                                            regionesLoad ? "" : regiones.data.map((region, index) => <option key={index} value={region.idRegion}>
                                                                {region.region}
                                                            </option>)
                                                        }


                                                    </Field>
                                                    <ErrorMessage name="region" component={ValidationError} />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="comuna" className="form-label">Comuna <span className="rojo">*</span></label>
                                                    <FieldComuna className='form-control' id="comuna" name="comuna" as='select' loadcomunas={loadComunas}>
                                                        <option value='' className="text-muted">Seleccionar</option>
                                                        {
                                                            arrayComunas.map((comuna, index) => <option key={index} value={comuna.idComuna}>
                                                                {comuna.comuna}
                                                            </option>)
                                                        }

                                                    </FieldComuna>
                                                    <ErrorMessage name="comuna" component={ValidationError} />
                                                </div>


                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="tipoPersona" className="form-label">Tipo de usuario <span className="rojo">*</span></label>
                                                    <Field name="tipoPersona" as="select" className="form-control" >
                                                        <option value={''}>Seleccionar</option>
                                                        {tipoPersonasLoad ? "" : tipoPersonas.data.map((tipo, index) => <option key={index} value={tipo.idTipoPersona}>{tipo.tipoPersona}</option>)}
                                                    </Field>
                                                    <ErrorMessage name="tipoPersona" component={ValidationError} />
                                                </div>


                                                <div className="col-12 mb-3">
                                                    <label htmlFor="direccion" className="form-label">Dirección <span className="rojo">*</span></label>
                                                    <Field type="text" className='form-control' id="direccion" name="direccion" placeholder="1234 Main St" />
                                                    <ErrorMessage name="direccion" component={ValidationError} />
                                                </div>

                                                <div className="col-12 mb-3">
                                                    <label htmlFor="imagen" className="form-label">Imagen de perfil <span className="rojo">*</span></label>
                                                    <input id="imagen" name="imagen" type="file" className='form-control' onChange={(event) => {
                                                        props.touched.imagen = true;
                                                        props.setFieldValue("imagen", event.currentTarget.files[0]);
                                                        setFile(URL.createObjectURL(event.target.files[0]));
                                                    }} accept="image/png, image/jpeg" />


                                                    <ErrorMessage name="imagen" component={ValidationError} touched={props.touched.imagen} />

                                                    {
                                                        (props.errors.imagen && props.touched.imagen) || !file ? "" : <div className="col-md-3 mt-3 pt-2 pb-2 card mx-auto"> <img src={file} className="rounded-circle mx-auto" style={{ width: "90%", borderRadius: "50%", verticalAlign: "middle", width: "200px", height: "200px" }} /></div>
                                                    }

                                                </div>


                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="web" className="form-label">Página Web </label>
                                                    <Field type="text" className='form-control' id="web" name="web" placeholder="https://" />
                                                    <ErrorMessage name="web" component={ValidationError} />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="youtube" className="form-label">Canal de youtube </label>
                                                    <Field type="text" className='form-control' id="youtube" name="youtube" placeholder="https://www.youtube.com/channel/" />
                                                    <ErrorMessage name="youtube" component={ValidationError} />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <label htmlFor="facebook" className="form-label">Perfil de Facebook </label>
                                                    <Field type="text" className='form-control' id="facebook" name="facebook" placeholder="https://www.facebook.com/" />
                                                    <ErrorMessage name="facebook" component={ValidationError} />
                                                </div>

                                                <div className="col-md-6 mb-3 ">
                                                    <label htmlFor="instagram" className="form-label">Perfil de instagram </label>
                                                    <Field type="text" className='form-control' id="instagram" name="instagram" placeholder="https://www.instagram.com/" />
                                                    <ErrorMessage name="instagram" component={ValidationError} />
                                                </div>
                                                <div className="col-md-6 mb-2 ">
                                                    <label htmlFor="otra_rrss" className="form-label">Otra RRSS </label>
                                                    <Field type="text" className='form-control' id="otra_rrss" name="otra_rrss" placeholder="https://" />
                                                    <ErrorMessage name="otra_rrss" component={ValidationError} />
                                                </div>



                                                <div className="col-12 mb-3">
                                                    <div className="form-check">
                                                        <Field className="form-check-input" type="checkbox" id="condiciones" name='condiciones' />
                                                        <label className="form-check-label" htmlFor="condiciones">
                                                            He leído y acepto los términos y condiciones de las <a href="https://www.cultura.gob.cl/politica-de-privacidad/" target="_blank">políticas de privacidad</a>
                                                        </label>
                                                    </div>
                                                    <ErrorMessage name="condiciones" component={ValidationError} />
                                                </div>

                                                <div className="col-12">
                                                    <button type="submit" className="btn btn-danger btn-lg" disabled={loading} >Aceptar <i className="fa-solid fa-arrow-right"></i></button> <Link onClick={() => window.location.replace(process.env.REACT_APP_URL_LOGOUT + "?redirect=" + encodeURIComponent(process.env.REACT_APP_FRONTEND_URL))} className="btn btn-link btn-lg">Cancelar</Link>
                                                </div>
                                            </Form>
                                        )}

                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >);
}


export default Registro;

Registro.propTypes = {
    title: PropTypes.string,
}

Registro.defaultProps = {
}


