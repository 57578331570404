import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const ExportExcel = (jsonData, fileName, sheetname = 'Data') => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(jsonData);

    let cols = [];
    Object.entries(jsonData[0]).forEach((element, index) => {
        const [key, value] = element;
        let character = 'A'.charCodeAt(0);
        let column = String.fromCharCode(character + index);
        ws[column + "1"].s = {
            font: {
                bold: true,
            },
        };

        cols.push({ wch: (value.toString().length + 1 > key.length + 1) ? value.toString().length + 1 : key.length + 1 })
    });

    ws['!cols'] = cols;
    const wb = { Sheets: { [sheetname]: ws }, SheetNames: [sheetname] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
}

export default ExportExcel;