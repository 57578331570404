import { faBriefcase, faFile, faPenToSquare, faTents } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TIPO_USUARIO_ORGANIZACION, TIPO_USUARIO_PERSONA } from "../../constants/Constants";
import { getDateFormat } from "../../constants/utiles/DateFunctions";

const Perfil = ({ user }) => {
    const tokenLocalStorageBrowser = localStorage.getItem('token') || false;
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        if (user?.rut && user?.nombreCompleto) {
            setLoading(false)
        }

        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);


    return (<div className="col-md-9">

        <div className="rb">
            <div className="rbl mb-2">
                <span>Información personal <a className="edit1" href="#"></a></span>
                <Link id="editar" className="rounded-circle" to="/home-privado/info-personal"><FontAwesomeIcon icon={faPenToSquare} /></Link>
            </div>
        </div>

        <div className="clear"></div>



        <div className="infop">
            {
                loading ? <Spinner></Spinner> : <div className="animation_input">
                    <div><span className="i1">Nombre completo</span><span className="i2">{user?.nombreCompleto}</span>
                    </div>
                    <div><span className="i1">Rut</span><span className="i2">{user?.rut}</span></div>
                    <div><span className="i1">Género</span><span className="i2">{user.genero}</span></div>
                    {
                        user?.detalleDiscapacidad ? <div><span className="i1">Detalle discapacidad</span> <span className="i2">{user?.discapacidadDetalle}</span></div> : ""
                    }

                    {
                        user?.espacioCultural ? <div><span className="i1">Espacio cultural</span> <span className="i2">{user?.tipoEspacioCultural}</span></div> : ""
                    }

                    <div><span className="i1">Tipo de perfil</span> <span className="i2">{user?.tipoPerfil}</span></div>

                    {
                        user.idTipoPerfil == TIPO_USUARIO_PERSONA ? <div><span className="i1">Tipo de usuario</span> <span className="i2">{user?.tipoPersona}</span></div> : ""
                    }
                    {
                        user.idTipoPerfil == TIPO_USUARIO_ORGANIZACION ? <div><span className="i1">Tipo de organización</span> <span className="i2">{user?.tipoOrganizacion}</span></div> : ""
                    }
                    <div><span className="i1">Fecha nacimiento</span><span className="i2">{getDateFormat(user?.fechaNacimiento?.date, 'DD/MM/YYYY')}</span></div>
                    <div><span className="i1">Correo electrónico</span><span className="i2">{user?.email}</span>
                    </div>
                    <div><span className="i1">Teléfono</span><span className="i2">{user?.telefono}</span></div>
                    <div><span className="i1">Dirección</span><span className="i2" >{user?.direccion}, {user?.comuna}, {user?.region}</span>
                    </div>
                    <div><span className="i1">Perteneciente a pueblos originarios</span> <span className="i2">{user?.puebloOriginario ? "Sí" : "No"}</span></div>
                    {
                        user?.puebloOriginario ? <div><span className="i1">Detalle pueblo originario</span> <span className="i2">{user?.puebloOriginarioDetalle}</span></div> : ""
                    }
                    <div><span className="i1">Perteneciente a población migrante</span> <span className="i2">{user?.poblacionMigrante ? "Sí" : "No"}</span>
                        {
                            user?.poblacionMigrante ? <div><span className="i1">Detalle población migrante</span> <span className="i2">{user?.poblacionMigranteDetalle}</span></div> : ""
                        }
                    </div>
                    <div><span className="i1">Posee discapacidad</span> <span className="i2">{user?.discapacidad ? "Sí" : "No"}</span></div>

                    {user.web ? <div><span className="i1">Página Web</span><span className="i2" >  <a href={user.web} target="_blank">Ir a página web</a></span></div> : ""}
                    {user.youtube ? <div><span className="i1">Canal de youtube</span><span className="i2" >  <a href={user.youtube} target="_blank">Ir a canal de Youtube</a></span></div> : ""}
                    {user.facebook ? <div><span className="i1">Perfil de Facebook</span><span className="i2" >  <a href={user.facebook} target="_blank">Ir a perfil de Facebook</a></span></div> : ""}
                    {user.instagram ? <div><span className="i1">Perfil de instagram</span><span className="i2" >  <a href={user.instagram} target="_blank">Ir a perfil de Instagram</a></span></div> : ""}
                    {user.otraRrss ? <div><span className="i1">Otra RRSS</span><span className="i2" >  <a href={user.otraRrss} target="_blank">Ir</a></span></div> : ""}

                </div>
            }



        </div >

        {
            !loading && user.idTipoPerfil == TIPO_USUARIO_ORGANIZACION ? (!user.representante_nombre && !user.representante_nombreOrganizacion && !user.representante_rutOrganizacion) ? "" :
                <div className="infop animation_input"> <hr></hr>
                    <span>
                        <div style={{ fontWeight: "bold" }}> Datos representante legal</div>
                    </span>
                    <div><span className="i1">Nombre del representante legal</span><span className="i2">{user.representante_nombre || "Sin información"}</span></div>
                    <div><span className="i1">Nombre de la organización</span><span className="i2">{user.representante_nombreOrganizacion || "Sin información"}</span></div>
                    <div><span className="i1">Rut de la organización</span><span className="i2">{user.representante_rutOrganizacion || "Sin información"}</span></div>
                    <div><span className="i1">¿Cuenta con personalidad jurídica la organización a la cual representa? </span> <span className="i2">{user?.representante_personalidadJuridica ? "Sí" : "No"}</span></div>
                    <br />
                </div> : ""
        }

        {
            !loading && user.espacioCultural === true ? (!user.espacio_nombreEspacioCultural && !user.tipoEspacioCultural &&
                !user.espacio_ficha_tecnica && !user.espacio_ficha_tecnica &&
                !user.espacio_dossier && !user.espacio_web &&
                !user.espacio_facebook && !user.espacio_instagram &&
                !user.espacio_otraRrss) ? "" :
                <div className="infop animation_input"> <hr></hr>
                    <span>
                        <div style={{ fontWeight: "bold" }}> Datos Espacio Cultural</div>
                    </span>
                    {user.espacio_nombreEspacioCultural ? <div><span className="i1">Nombre del espacio de uso cultural</span><span className="i2">{user.espacio_nombreEspacioCultural || "Sin información"}</span></div> : ""}
                    {user.espacio_ficha_tecnica ? <div><span className="i1">Ficha técnica</span><span className="i2">{user.espacio_ficha_tecnica ? <a href={user.espacio_ficha_tecnica} target={'_blank'}> <FontAwesomeIcon className={'mx-1'} icon={faFile} /> Ver Ficha técnica </a> : "No especificado"}</span></div> : ""}
                    {user.espacio_dossier ? <div><span className="i1">Dossier, memoria o portafolio </span><span className="i2">{user.espacio_dossier ? <a href={user.espacio_dossier} target={'_blank'}> <FontAwesomeIcon className={'mx-1'} icon={faFile} /> Ver Dossier/Memoria/Portafolio </a> : "No especificado"}</span></div> : ""}
                    {user.espacio_web ? <div><span className="i1">Página Web</span><span className="i2" ><a href={user.espacio_web} target="_blank">Ir a página web</a></span></div> : ""}
                    {user.espacio_facebook ? <div><span className="i1">Perfil de Facebook</span><span className="i2" ><a href={user.espacio_facebook} target="_blank">Ir a perfil de Facebook</a></span></div> : ""}
                    {user.espacio_instagram ? <div><span className="i1">Perfil de instagram</span><span className="i2" ><a href={user.espacio_instagram} target="_blank">Ir a perfil de Instagram</a></span></div> : ""}
                    {user.espacio_otraRrss ? <div><span className="i1">Otra RRSS</span><span className="i2" ><a href={user.espacio_otraRrss} target="_blank">Ir</a></span></div> : ""}
                    <br />
                </div> : ""
        }


        <div className="clear"></div>
    </div >)
}

export default Perfil;