import { faPenToSquare, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import axiosApi from "../../api/axios/axiosApi";
import { MIS_OFERTAS_URI, OFERTA_DELETE_URI } from "../../api/axios/constants";
import { ESTADO_APROBADO } from "../../constants/Constants";
import { TAB_MIS_OFERTAS, TAB_SUBIR_OFERTA } from "../../constants/ConstantsTabsPrivado";

const MisOfertas = ({ user }) => {
    const [arrayOfertas, setArrayOfertas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [idEliminar, setIdEliminar] = useState(null);
    const [open, setOpen] = useState();

    useEffect(() => {
        setLoading(true)
        const cancelToken = axios.CancelToken.source();
        if (user?.idDirectorio) {
            axiosApi.get(MIS_OFERTAS_URI(user.idDirectorio), { cancelToken: cancelToken.token }).then(response => {
                setArrayOfertas(response?.data?.data)
                setLoading(false)
            }).catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error);
                } else {
                    console.error(error);
                    setLoading(false)
                }

            });
        }


        return () => {
            cancelToken.cancel();
        }

    }, [user]);

    const handleEliminarOferta = (id) => {
        const toastId = toast.loading('Cargando...');
        setLoading(true)
        setOpen(false);
        axiosApi.delete(OFERTA_DELETE_URI(id)).then(response => {
            setArrayOfertas((current) =>
                current.filter((oferta) => oferta.idOferta !== id)
            );
            setLoading(false)
            toast.success(response.data.message, {
                id: toastId,
            });

        }).catch(error => {
            console.error(error);
            setLoading(false)
        });


    }

    const formatter = new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP',

    });

    return (<div className="rb" >

        <div className="rbl">
            <span>Mis ofertas</span>
        </div>

        <div className="clear"></div>

        {
            loading ? <div className="mt-5 mb-5 ">
                <div style={{ textAlign: "center" }} >
                    <Spinner ></Spinner>
                </div>
            </div> :
                <div className="animation_input">
                    {
                        arrayOfertas?.length > 0 ?
                            arrayOfertas.map((oferta, index) => {

                                return (<div className="convocatoria rounded mt-4" key={index}>
                                    <div className="row">

                                        <div className="col-md-5">
                                            <img className="rounded img-of-1" src={oferta.imagen} alt="" />

                                        </div>
                                        <div className="col-md-7" style={{ position: "relative" }}>
                                            <span className="of-tit">{oferta.nombreOferta}</span>
                                            <ul className="cat-of">
                                                <li><a style={{ backgroundColor: oferta.idEstado == ESTADO_APROBADO ? "#008000" : "#dc3545", color: "white" }} >Estado: {oferta.estado}</a></li>
                                            </ul>
                                            <div className="clear"></div>
                                            <p className="p-of">{oferta.descripcion}</p>
                                            <p style={{ fontSize: "14px", color: "#333" }} >Fecha: {oferta.fechaCreacion} <br /> Valor Oferta: {formatter.format(oferta.precio)}</p>


                                            <ul className="of-bots">
                                                {
                                                    oferta.idEstado == ESTADO_APROBADO ? <li><Link className="rounded of-det" to={`/oferta/${oferta.idOferta}`}>Ver detalles <FontAwesomeIcon icon={faPlus} ></FontAwesomeIcon></Link></li> : ""
                                                }
                                                <li><Link className="rounded of-edit" to={`/home-privado/${TAB_SUBIR_OFERTA}/${oferta.idOferta}`} >Editar <FontAwesomeIcon icon={faPenToSquare} ></FontAwesomeIcon></Link></li>
                                                <li><Link className="rounded of-del" onClick={() => {
                                                    setIdEliminar(oferta.idOferta);
                                                    setOpen(true);
                                                }}>Eliminar <FontAwesomeIcon icon={faTrashCan} ></FontAwesomeIcon></Link></li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>)
                            }) : <span>No se encontraron ofertas registradas</span>
                    }

                    <Modal show={open} onHide={() => {
                        setIdEliminar(null);
                        setOpen(false)
                    }} backdrop="static">
                        <Modal.Header closeButton>
                            <Modal.Title>Eliminar oferta</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <span>¿Seguro desea eliminar esta oferta? Presione "Eliminar" para continuar con la operación</span>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => { setOpen(false) }}>
                                Cancelar
                            </Button>
                            <Button variant="primary" onClick={() => { handleEliminarOferta(idEliminar) }}>
                                Eliminar
                            </Button>

                        </Modal.Footer>
                        )


                    </Modal>
                </div>
        }





    </div >)
}

export default MisOfertas;