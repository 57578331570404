
import PropTypes from 'prop-types';
import { generateId } from '../../constants/utiles/Functions';

const CUButton = ({ redirect_url }) => {
    const sendToCU = () => {


        const state = generateId();
        localStorage.setItem('state', state)
        const url_cu = process.env.REACT_APP_URL_CU;
        const client_id = process.env.REACT_APP_CLIENT_ID_CU;
        const response_type = process.env.REACT_APP_RESPONSE_TYPE_CU;
        const scope = process.env.REACT_APP_SCOPE_CU;
        const redirect_url_cu = encodeURIComponent(redirect_url);

        window.location.replace(`${url_cu}/authorize/?client_id=${client_id}&response_type=${response_type}&scope=${scope}&redirect_uri=${redirect_url_cu}&state=${state}`);
    }


    return (
        <a className="btn-cu btn-l  btn-fw btn-color-estandar" onClick={sendToCU}
            title="Este es el botón Iniciar sesión de ClaveÚnica">
            <span className="cl-claveunica"></span>
            <span className="texto">Iniciar sesión</span>
        </a>
    )
}

export default CUButton;

CUButton.propTypes = {
    redirect_url: PropTypes.string,
}

CUButton.defaultProps = {
    redirect_url: process.env.REACT_APP_REDIRECT_URL_CU,
}