import { createSlice } from '@reduxjs/toolkit'

const userActiveSlice = createSlice({
  name: 'userActive',
  initialState: {
    userInfo: {}, // Guardar objeto de usuario
    isLogin: false,
    loading: false,
    timeout: null,
    userDB: false,
    idDirectorio: null,
  },
  reducers: {
    setUserIsLogin(state, action) {
      state.isLogin = action.payload
    },
    setUserActive(state, action) {
      state.userInfo = action.payload
    },
    setTimeout(state, action) {
      const dt = new Date();
      dt.setMinutes(dt.getMinutes() + 30)
      state.timeout = dt.getTime();
      localStorage.setItem('timeout', state.timeout);
    },
    userLogout(state, action) {
      state.isLogin = false;
      state.userInfo = {};
      state.timeout = null;
      state.userDB = false;
      state.idDirectorio = null;

      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('timeout');
      localStorage.removeItem('userDB');
      localStorage.removeItem('idDirectorio');

    },
    setUserDB(state, action) {
      state.userDB = action.payload

      localStorage.setItem('userDB', action.payload);
    },
    setIdDirectorio(state, action) {
      state.idDirectorio = action.payload

      localStorage.setItem('idDirectorio', action.payload);
    }
  }
})

export const {
  setUserIsLogin,
  setUserActive,
  setTimeout,
  userLogout,
  setUserDB,
  setIdDirectorio
} = userActiveSlice.actions
export default userActiveSlice.reducer