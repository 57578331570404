import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { getMonths, getYearsSinceStartYear } from "../../constants/utiles/DateFunctions";
import { useGetCategoriaNoticiasQuery } from "../../redux/features/ApiSlice";

const Filter = ({ handleBuscador }) => {
    const [mes, setMes] = useState("");
    const [anio, setAnio] = useState("");
    const [categoria, setCategoria] = useState("");


    const { data: categorias = [],
        isSuccess } = useGetCategoriaNoticiasQuery();

    const years = getYearsSinceStartYear(process.env.REACT_APP_FILTER_YEAR || 2023);
    const months = getMonths();

    const handleSubmit = () => {


        const data = {
            mes: mes,
            anio: anio,
            id_categoria: categoria
        }
        handleBuscador(data);
    }

    return (
        <div className="container">

            <div className="row f-blog">
                <ul>
                    <li>
                        <label htmlFor="inputPassword6" className="col-form-label">Filtrar por</label></li>
                    <li>
                        <select className="form-select" aria-label="Default select example" onChange={e => setMes(e.target.value)}>
                            <option value={""} >Mes</option>
                            {months.map((values, index) => <option value={index + 1} key={index}>{values.charAt(0).toUpperCase() + values.slice(1)}</option>)}
                        </select>
                    </li>
                    <li>
                        <select className="form-select" aria-label="Default select example" onChange={e => setAnio(e.target.value)}>
                            <option value={""}>Año</option>
                            {years.map((value, index) => <option value={value} key={index}>{value}</option>)}
                        </select>
                    </li>
                    <li>
                        <select className="form-select" aria-label="Default select example" onChange={e => setCategoria(e.target.value)}>
                            <option value={""}>Categoría</option>
                            {
                                isSuccess ? categorias.data.map((values, index) => <option key={index} value={values.idCategoriaNoticia}>{values.categoriaNoticia}</option>) : ""
                            }
                        </select>
                    </li>
                    <li>
                        <button type="submit" onClick={handleSubmit} className="btn btn-danger"><FontAwesomeIcon icon={faFilter} /> Filtrar</button>
                    </li>
                </ul>
            </div>



        </div>
    )
}

export default Filter;