import { useGetComunasRegionQuery } from "../../redux/features/ApiSlice";

const InputComuna = ({ region, handleComuna, comuna  }) => {
    let skip = false;
    if (!region) {
        skip = true;
    }
    const {
        data: data = [],
        isSuccess
    } = useGetComunasRegionQuery(region, { skip })

    return (<select className="form-select" aria-label="Default select example" value={comuna} onChange={e => handleComuna(e.target.value)}>
        <option value={''}>Comuna</option>
        {isSuccess ? data.data.map((values, index) => <option value={values.idComuna} key={index}>{values.comuna}</option>) : ""}

    </select>);
} 

export default InputComuna;