import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import axiosApi from "../../api/axios/axiosApi";
import {  CONTACTO_URI } from "../../api/axios/constants";
import * as Yup from 'yup';
import { regexTelefono } from "../../constants/utiles/Functions";
import ValidationError from "../utiles/ValidationError";
import FieldComuna from "../form/FieldComuna";
import { useGetRegionesQuery } from "../../redux/features/ApiSlice";

const Contacto = () => {
    const [arrayRegiones, setArrayRegiones] = useState([]);

    const [arrayComunas, setArrayComunas] = useState([]);
    const [loading, setLoading] = useState(true);
    const {
        data: regiones = [],
        isLoading: regionesLoad,
        isError: regionesIsError,
        error: regionesError
    } = useGetRegionesQuery()

    useEffect(() => {
        if (!regionesLoad) {
            setLoading(false)
        }
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regionesLoad]);

    const loadComunas = (region) => {
        setArrayComunas(region)
    }

    const initialValues = {
        nombre: '',
        email: '',
        telefono: '',
        region: '',
        comuna: '',
        mensaje: ''
    };

    const validationSchema = Yup.object({
        nombre: Yup.string().required('Campo requerido').max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        email: Yup.string().email('Correo electronico ingresado invalido').required('Campo requerido').max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        telefono: Yup.string().matches(regexTelefono, 'Formato de numero de telefono invalido').required('Campo requerido').max(50, 'El texto ingresado no debe sobrepasar los 50 caracteres'),
        region: Yup.string().required('Campo requerido'),
        comuna: Yup.string().required('Campo requerido'),
        mensaje: Yup.string().required('Campo requerido').max(65000, 'El texto ingresado no debe sobrepasar los 65000 caracteres'),
    })

    const handleOnSubmit = (values, { resetForm }) => {
        setLoading(true);
        const toastId = toast.loading('Cargando...');
        const data = {
            nombre: values.nombre,
            email: values.email,
            telefono: values.telefono,
            id_comuna: values.comuna,
            id_region: values.region,
            mensaje: values.mensaje
        };
        axiosApi.post(CONTACTO_URI,
            data)
            .then(response => {
                if (response.data.error === true) {
                    toast.error(response.data.message, {
                        id: toastId,
                    });
                } else {
                    toast.success('Solicitud enviada', {
                        id: toastId,
                    });
                    resetForm()
                }
                setLoading(false)
            }).catch((error) => {
                toast.error('Ha ocurrido un error al registrarse', {
                    id: toastId,
                });
                console.error(error);
                setLoading(false)

            });

    }

    return (

        <div className="row">
            <div className="form rounded mb-4">

                <span className="tform">Formulario de contacto</span>
                <span className="pform"></span>

                <div className="row justify-content-md-center mt-4 animation_input">
                    <div className="col-md-7">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleOnSubmit}>
                            <Form>
                                <div className="mb-3">
                                    <label htmlFor="nombre" className="form-label">Nombre <span className="rojo">*</span></label>
                                    <Field type="text" className='form-control' id="nombre" name="nombre" />
                                    <ErrorMessage name="nombre" component={ValidationError} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="region" className="form-label">Región <span className="rojo">*</span></label>

                                    <Field className='form-control' id="region" name="region" as='select' >
                                        <option value='' className="text-muted">Seleccionar</option>
                                        {
                                            regionesLoad ? "" : regiones.data.map((region, index) => <option key={index} value={region.idRegion}>
                                                {region.region}
                                            </option>)
                                        }

                                    </Field>
                                    <ErrorMessage name="region" component={ValidationError} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="comuna" className="form-label">Comuna <span className="rojo">*</span></label>
                                    <FieldComuna className='form-control' id="comuna" name="comuna" as='select' loadcomunas={loadComunas}>
                                        <option value='' className="text-muted">Seleccionar</option>
                                        {
                                            arrayComunas.map((comuna, index) => <option key={index} value={comuna.idComuna}>
                                                {comuna.comuna}
                                            </option>)
                                        }

                                    </FieldComuna>
                                    <ErrorMessage name="comuna" component={ValidationError} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Correo electrónico <span className="rojo">*</span></label>
                                    <Field type="email" className='form-control' id="email" name='email' />
                                    <ErrorMessage name="email" component={ValidationError} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="telefono" className="form-label">Teléfono <span className="rojo">*</span></label>
                                    <Field type="text" className='form-control' id="telefono" name="telefono" />
                                    <ErrorMessage name="telefono" component={ValidationError} />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="mensaje" className="form-label">Mensaje <span className="rojo">*</span></label>
                                    <Field as="textarea" className='form-control' id="mensaje" name="mensaje" />
                                    <ErrorMessage name="mensaje" component={ValidationError} />
                                </div>

                                <div className="d-grid gap-2">
                                    <button type="submit" className="btn btn-danger btn-lg" disabled={loading}>Enviar <FontAwesomeIcon icon={faArrowRight} ></FontAwesomeIcon></button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Contacto;