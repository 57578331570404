import React, { useRef, useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import axios from 'axios';
import { SEARCH_MAP_API } from '../../api/axios/constants';


const Map = ({ direccion }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [zoom] = useState(12);
    const [API_KEY] = useState(process.env.REACT_APP_TOKEN_MAPTILES);

    useEffect(() => {
        if (map.current) return; //stops map from intializing more than once
        axios.get(SEARCH_MAP_API(direccion))
            .then(response => {
                map.current = new maplibregl.Map({
                    container: mapContainer.current,
                    style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${API_KEY}`,
                    center: response.data.features[0].center || [-70.650438, -33.439953],
                    zoom: zoom
                });

                map.current.addControl(new maplibregl.NavigationControl(), 'top-right');

                new maplibregl.Marker({ color: "#FF0000" })
                    .setLngLat(response.data.features[0].center || [-70.650438, -33.439953])
                    .addTo(map.current);
            })
            .catch((error) => console.log(error));

    }, []);

    return (
        <div className="map-wrap">
            <div ref={mapContainer} className="map" />
        </div>
    );
}

export default Map;