import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import axiosApi from "../api/axios/axiosApi";
import { BUSQUEDA_NOTICIAS_URI, NOTICIAS_GET_URI } from "../api/axios/constants";
import Filter from "../components/catalogoNoticias/Filter";
import HeaderNoticias from "../components/catalogoNoticias/HeaderNoticias";
import NoticiaPrincipal from "../components/catalogoNoticias/NoticiaPrincipal";
import ResultadosBusqueda from "../components/catalogoNoticias/ResultadosBusqueda";

const CatalogoNoticias = () => {
    const [loading, setLoading] = useState(true);
    const [arrayNoticias, setArrayNoticias] = useState([]);

    const handleBuscador = (values) => {
        setLoading(true)
        axiosApi.get(BUSQUEDA_NOTICIAS_URI, { params: values }).then(response => {
            setArrayNoticias(response?.data?.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }



    useEffect(() => {
        setLoading(true)
        const cancelToken = axios.CancelToken.source();
        axiosApi.get(NOTICIAS_GET_URI, { cancelToken: cancelToken.token }).then(response => {
            setArrayNoticias(response?.data?.data)
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log(error);
            } else {
                console.error(error);
                setLoading(false)
            }

        });
        return () => {
            cancelToken.cancel();
        }
    }, [])


    return (
        <Fragment>
            <HeaderNoticias>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="breadcrumbs">
                                <ul>
                                    <li><NavLink to="/">Inicio</NavLink></li>
                                    <li><NavLink className="bc-w" style={{ borderRight: "solid 0px #ccc !important" }} >Noticias</NavLink></li>
                                </ul>
                            </div>
                            <div className="clear"></div>
                            <span className="t1b">Noticias</span>
                            <p>Descubre las novedades que tenemos para ofrecerte y mantente informado sobre el mundo cultural y artístico.</p>
                        </div>
                    </div>
                </div>
                <Filter handleBuscador={handleBuscador}></Filter>
            </HeaderNoticias>


            {
                loading ? <div className="mt-5 mb-5 ">
                    <div style={{ textAlign: "center" }} >
                        <Spinner ></Spinner>
                    </div>
                </div> : <div>
                    {
                        //TODO:Implementar noticia principal
                        arrayNoticias[0] ? <NoticiaPrincipal
                            imagen={arrayNoticias[0].imagen_destacada}
                            descripcion={arrayNoticias[0].bajada.substring(0, 255)}
                            titulo={arrayNoticias[0].titulo}
                            fechaTiempo={arrayNoticias[0].fechaCreacion}
                            tiempoLectura={arrayNoticias[0].minutosLectura}
                            categoria={arrayNoticias[0].categoria}
                            idNoticia={arrayNoticias[0].idNoticia}
                        ></NoticiaPrincipal> : ""


                    }

                    <ResultadosBusqueda arrayNoticias={arrayNoticias.filter(value => value.idNoticia != arrayNoticias[0].idNoticia)}></ResultadosBusqueda>

                </div>
            }





        </Fragment>);

}

export default CatalogoNoticias;