
import { Fragment, useEffect, useRef, useState } from "react";
import { Link, NavLink, useSearchParams } from "react-router-dom";
import Titulo from "../components/utiles/Titulo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import '../css/cu.min.css';
import { generateId, setTitle, sha256 } from "../constants/utiles/Functions";
import CUButton from "../components/cu/CUButton";
import { TOKEN_CU, USER_INFO_CU, USUARIO_GET_URI } from "../api/axios/constants";
import toast from "react-hot-toast";
import axiosApi from "../api/axios/axiosApi";
import { useDispatch } from "react-redux";
import { setIdDirectorio, setTimeout, setUserActive, setUserDB, setUserIsLogin } from '../redux/features/userActiveSlice';
import { setLoading } from '../redux/features/PrincipalSlice';
import { useNavigate } from "react-router-dom";
import { format } from 'rut.js';

const Login = (params) => {
    const [queryParameters] = useSearchParams();
    const effectRan = useRef(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        setTitle(params.title)

        if (effectRan.current === false) {
            if (queryParameters.has("code")) {
                dispatch(setLoading(true))
                const state = localStorage.getItem('state')
                if (queryParameters.get("state") != state) {
                    console.log("alo");
                    dispatch(setLoading(false))
                    toast.error("Ocurrio un error en los parametros de inicio de sesion (STATE), favor contactar con el administrador");
                    localStorage.removeItem('state')

                } else {
                    const toastId = toast.loading('Cargando...');
                    localStorage.removeItem('state')
                    axiosApi.post(TOKEN_CU, {}, { params: { code: queryParameters.get("code"), state: queryParameters.get("state") } })
                        .then(response => {
                            if (response.data.error === true) {
                                toast.error(response.data.message, {
                                    id: toastId,
                                });
                                dispatch(setLoading(false))
                            } else {
                                localStorage.setItem('token', response.data.token)

                                axiosApi.post(USER_INFO_CU, null, { params: { token: response.data.token } })
                                    .then(response_info => {
                                        if (response_info.data.error === true) {
                                            localStorage.removeItem('token')
                                            toast.error(response.data.message, {
                                                id: toastId,
                                            });
                                            dispatch(setLoading(false))
                                        } else {
                                            const userInfo = {
                                                nombre: `${response_info.data.data.name.nombres.join(' ')} ${response_info.data.data.name?.apellidos.join(' ')}`,
                                                rut: format(`${response_info.data.data.RolUnico.numero}${response_info.data.data.RolUnico.DV}`)
                                            };

                                            localStorage.setItem('userInfo', JSON.stringify(userInfo));
                                            dispatch(setUserActive(userInfo));
                                            dispatch(setUserIsLogin(true));
                                            dispatch(setTimeout());

                                            //TODO: llamada a api de datos bd de usuario para redirigir a registro o a otra pagina
                                            axiosApi.get(USUARIO_GET_URI(userInfo.rut)).then(responseDB => {
                                                dispatch(setLoading(false))
                                                if (responseDB.data.data === null) {
                                                    toast.success('Sesión iniciada', {
                                                        id: toastId,
                                                    });
                                                    navigate(`/registrarse`);
                                                } else {
                                                    dispatch(setUserDB(true));
                                                    dispatch(setIdDirectorio(responseDB.data.data[0].idDirectorio))
                                                    toast.success('Sesión iniciada', {
                                                        id: toastId,
                                                    });
                                                    navigate(`/home-privado/inicio`);
                                                }
                                            }).catch(error => {
                                                dispatch(setLoading(false))
                                                toast.error('Ha ocurrido un error, favor contactar con soporte', {
                                                    id: toastId,
                                                });
                                            })
                                        }

                                    }).catch(error => {
                                        toast.error('Error al iniciar sesión', {
                                            id: toastId,
                                        });
                                        localStorage.removeItem('token')
                                        localStorage.removeItem('userInfo')
                                        dispatch(setLoading(false))
                                    });
                            }


                        }).catch((error) => {
                            toast.error('Ha ocurrido un error al inciar sesión', {
                                id: toastId,
                            });

                            console.error(error);
                            dispatch(setLoading(false))

                        });
                }
            }
        }

        return () => {
            effectRan.current = true;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Titulo>
                <div className="breadcrumbs">
                    <ul>
                        <li><Link to="/">Inicio</Link></li>
                        <li><Link className="bc" style={{ borderRight: "solid 0px #ccc !important" }} >Iniciar sesión</Link></li>
                    </ul>
                </div>
                <div className="clear"></div>
                <span className="t1b mb-4">Te damos la bienvenida al Catálogo digital</span>

                <p className="p1b">El Catálogo Digital para la programación cultural local es un registro web del Ministerio de las Culturas, las
                    Artes y el Patrimonio, que busca generar contactos y redes entre agentes culturales, personas y organizaciones, programadores de
                    actividades artísticas y oferentes de obras o proyectos artísticos culturales a lo largo de todo Chile.</p>

                <p>Es una herramienta en constante construcción colaborativa, abierta a la ciudadanía y las comunidades     </p>


                <p className="fw-bold">Aquí podrás acceder con tu cuenta registrada o con clave única, si tienes dificultades de acceso escríbenos al formulario de atención.</p>

            </Titulo>



            <div className="menu2">
                <div className="container">
                    <ul>
                        <li><NavLink to="/login-usuario"><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faUserPlus}></FontAwesomeIcon> Iniciar sesión</NavLink></li>
                        <li><NavLink className="m2c"><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faCircleCheck}></FontAwesomeIcon> Usar clave única</NavLink></li>
                        <li><NavLink to="/registrar-usuario"><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faUserPlus}></FontAwesomeIcon> Registrarme</NavLink></li>
                    </ul>
                </div>
            </div>


            <div className="gris2">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <span style={{ color: "#888" }} className="txt1">¿Tienes problemas para iniciar sesión?  <Link to='/ayuda/contacto'>Consulta aquí</Link></span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form rounded mb-4">
                            <span className="tform">Iniciar sesión en Catálogo digital</span>
                            <span className="pform">Ingresa con tu clave única</span>

                            <div className="row justify-content-md-center mt-4">
                                <div className="col-md-5">
                                    <CUButton />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >);
}


export default Login;