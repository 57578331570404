import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import ConvocatoriaItem from "./ConvocatoriaItem";
import { Spinner } from "react-bootstrap";



const ConvocatoriasInteresInicio = ({ listaConvocatorias, isLoading }) => {
    return (
        <div className="rb" >

            <div className="rbl">
                <span>Convocatorias de interés</span>
            </div>

            <div className="clear"></div>

            {isLoading ? <div className='row mt-3'><Spinner className="mx-auto" /></div> :
                listaConvocatorias.length == 0 ? <div className='mt-3'> No se encontraron resultados</div> :
                listaConvocatorias.slice(0, 3).map((convocatoria, index) => <ConvocatoriaItem
                    key={index}
                    convocatoria={convocatoria}
                />
                )
            }
        </div>);
}

export default ConvocatoriasInteresInicio;

ConvocatoriasInteresInicio.propTypes = {
    listaConvo: PropTypes.array.isRequired,
    isLoading: PropTypes.bool
}

ConvocatoriasInteresInicio.defaultProps = {
    listaConvo: [],
    isLoading: true
}