import { Link } from "react-router-dom";
import Map from "../utiles/Map";
import { ID_AMPLIFICACION, ID_ILUMINACION, ID_OTRO, ID_REPRO_VIDEO_CINE } from "../../constants/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowPointer, faFileLines, faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

const OfertaDetalles = ({ oferta }) => {
    return (<div className="cont-not">
        <div className="row">
            <div className="col-md-6 mt-4">
                <p><strong className="mb-2">Precio de la oferta</strong> {oferta.precio}<br />
                    Incluye producción: {oferta.precioIncluyeProduccion ? "Sí" : "No"}</p>

                <p><strong>Público</strong> {oferta.publicoDirigidio}</p>

                <p><strong>Duración de la oferta</strong> {oferta.duracionOferta}</p>

                <p><strong>Permanencia de la oferta</strong> {oferta.permanenciaOferta}</p>

                <p><strong>Pasajeros</strong> {oferta.pasajeros ? "Sí" : "No"}</p>

                <p><strong>Carga</strong> {oferta.carga ? "Sí" : "No"}</p>

                <p><strong>Espacio</strong> {oferta.espacio}</p>

                <p><strong>Cuenta con actividad de mediación</strong> {oferta.mediacion ? "Sí" : "No"}</p>

                <p><strong>Requiere reproducción de video/cine</strong> {oferta.espacioRequeridos.find(i => i.idespacio == ID_REPRO_VIDEO_CINE) ? "Sí" : "No"}</p>

                <p><strong>Requiere iluminación</strong> {oferta.espacioRequeridos.find(i => i.idespacio == ID_ILUMINACION) ? "Sí" : "No"}</p>

                <p><strong>Requiere amplificación</strong> {oferta.espacioRequeridos.find(i => i.idespacio == ID_AMPLIFICACION) ? "Sí" : "No"}</p>

                <p><strong>Requiere otro</strong> {oferta.espacioRequeridos.find(i => i.idespacio == ID_OTRO) ? <span>Sí <br /> {oferta.espacioRequeridoOtro}</span> : "No"}</p>

                {
                    oferta.dossier_pdf ?
                        <p><strong>Documento dossier</strong>
                            <FontAwesomeIcon className={'mx-1'} icon={faFileLines} /> <a href={oferta.dossier_pdf} target={'_blank'}> Descargar
                            </a></p> : ""
                }

                {
                    oferta.videoPromocional ?
                        <p><strong>Link video promocional youtube</strong>
                            <FontAwesomeIcon className={'mx-1'} icon={faVideoCamera} /> <a href={oferta.videoPromocional} target={'_blank'}> Ver
                            </a></p> : ""
                }
            </div>


            <div className="col-md-6 mt-4">
                {oferta.listadoParticipantes.length > 0 ?

                    <div><strong className="mb-2">Participantes</strong> {oferta.participantes} <br />
                        <ul className="participantes">
                            {oferta.listadoParticipantes.map((values, index) =>
                                <li key={index}>{values.nombreParticipante}
                                    {values.web ? <a href={values.web} target="_blank"><FontAwesomeIcon icon={faArrowPointer} /></a> : ""}
                                    {values.facebook ? <a href={values.facebook} target="_blank"><FontAwesomeIcon icon={faFacebook} /></a> : ""}
                                    {values.instagram ? <a href={values.instagram} target="_blank"><FontAwesomeIcon icon={faInstagram} /></a> : ""}
                                </li>
                            )}

                        </ul>
                    </div> : " "}



                <p><strong>Ubicación de la oferta</strong> <br />
                    {oferta.comuna}, {oferta.region} <br /></p>

                <Map direccion={`${oferta.comuna}, ${oferta.region}`}></Map>

            </div>

            <div className="col-12 mt-4">
                <ul className="of-bots">
                    <li><Link className="rounded of-cont" to={`/detalle-usuario/${oferta.idDirectorio}`} >Contactar <i className="fa-solid fa-envelope"></i></Link></li>

                </ul>
            </div>
        </div>
    </div>



    )
}

export default OfertaDetalles;