import { ErrorMessage, useFormikContext } from "formik";
import { useState } from "react";
import ValidationError from "../../utiles/ValidationError";

const ImagenOferta = ({ defaultImage }) => {
    const [file, setFile] = useState(defaultImage || null);

    let {
        errors,
        setFieldValue,
        touched
    } = useFormikContext();

    return (<div className="card">
        <div className="card-header">
            <strong>La imagen no debe contener textos, tamaño máximo 5MB en formato jpg, gif o png.</strong>
        </div>
        <div className="card-body">

            <input id="imagen" name="imagen" type="file" className={`form-control  ${errors.imagen && touched.imagen ? "is-invalid" : ""}`} onChange={(event) => {
                touched.imagen = true;
                setFieldValue("imagen", event.currentTarget.files[0]);
                setFile(URL.createObjectURL(event.target.files[0]));
            }} accept="image/png, image/jpeg, image/gif, image/jpg" />

            {
                (errors.imagen && touched.imagen) || !file ? "" : <div className="mt-3 pt-2 pb-2 card mx-auto" >
                    <img src={file} className=" mx-auto" style={{ width: "90%", verticalAlign: "middle" }} />
                </div>
            }

            <ErrorMessage name="imagen" component={ValidationError} touched={touched.imagen} />
        </div>


    </div>)

}

export default ImagenOferta;