import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import CarteleraBuscador from "./CarteleraBuscador";
import CarteleraItem from "./CarteleraItem";
import cine from "../../img/cine.jpg";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const Cartelera = (props) => {
    const [actividades, setActividades] = useState([]);
    const [region, setRegion] = useState('');
    const [comuna, setComuna] = useState('');
    const [disciplina, setDisciplina] = useState('');
    const [Actividad, setActividad] = useState('');

    return (
        <div className="elige">
            <div className="container">

                <div className="row">
                    <div className="col">
                        <span className="t1b">Cartelera Elige Cultura</span>
                        <p className="p1b">Explora el calendario de actividades.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <Link to="#" className="masofertas rounded">Más eventos <FontAwesomeIcon style={{ marginLeft: '10px' }} icon={faArrowRight} ></FontAwesomeIcon></Link>
                    </div>
                </div>


                <div className="row">
                    <CarteleraBuscador
                        setRegion={setRegion}
                        setComuna={setComuna}
                        setDisciplina={setDisciplina}
                        setActividad={setActividad}
                    />

                    <div className="col-md-10">
                        <div className="row">

                            {

                                //TODO: actividades.map de 
                            }

                            <CarteleraItem
                                url='/'
                                img={cine}
                                nombre='Títeres del Bosque Encantado'
                                agente='Organización'
                                fecha='Ejemplo'
                                diasSemana='Ejemlpo'
                                horasDia='Ejemplo'
                                estado='Entrada Liberada'
                            />

                            <CarteleraItem
                                url='/'
                                img={cine}
                                nombre='Títeres del Bosque Encantado'
                                agente='Organización'
                                fecha='Ejemplo'
                                diasSemana='Ejemlpo'
                                horasDia='Ejemplo'
                                estado='Entrada Liberada'
                            />
                            <CarteleraItem
                                url='/'
                                img={cine}
                                nombre='Títeres del Bosque Encantado'
                                agente='Organización'
                                fecha='Ejemplo'
                                diasSemana='Ejemlpo'
                                horasDia='Ejemplo'
                                estado='Entrada Liberada'
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Cartelera;

Cartelera.propTypes = {
    arrayCarteleras: PropTypes.array.isRequired,

}

Cartelera.defaultProps = {
    arrayCarteleras: [],
}