import { faAddressCard, faBuildingColumns, faFileLines, faShieldHalved, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Accordion, Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import axiosApi from "../api/axios/axiosApi";
import { USUARIO_GET_URI_ID_DIR } from "../api/axios/constants";
import SesionModal from "../components/convocatoriaAssets/SesionModal";
import Titulo from "../components/utiles/Titulo"
import { TIPO_USUARIO_ORGANIZACION, TIPO_USUARIO_PERSONA } from "../constants/Constants";
import { useAddPerfilesGuardadosMutation, useGetPerfilesGuardadosQuery } from "../redux/features/ApiSlice";

const UsuarioDetalle = () => {
    const { id_directorio } = useParams();
    const [user, setUser] = useState({});
    const [open, setOpen] = useState(false);

    const { idDirectorio } = useSelector((state) => state.userActive);
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [addPerfilesGuardados] = useAddPerfilesGuardadosMutation();

    let skip = false;
    if (!idDirectorio) {
        skip = true;
    }
    const { data = [], isFetching } = useGetPerfilesGuardadosQuery(idDirectorio, { skip });
    useEffect(() => {

        const cancelTokenGen = axios.CancelToken.source();
        if (id_directorio) {
            axiosApi.get(USUARIO_GET_URI_ID_DIR(id_directorio), { cancelToken: cancelTokenGen.token })
                .then(response => {
                    setUser(response?.data?.data[0]);
                    setLoading(false);
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log("cancelado");
                    } else {
                        console.error(error)
                        setLoading(false);
                    }
                });

        }

        return () => {
            cancelTokenGen.cancel();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id_directorio]);



    const handleGuardarPerfil = () => {
        if (idDirectorio && user.idDirectorio) {
            setLoadingButton(true);
            const toastId = toast.loading('Cargando...');
            addPerfilesGuardados({
                id_perfil_guardar: user.idDirectorio,
                id_directorio: idDirectorio
            }).unwrap().then((response) => {
                toast.success(response.message, {
                    id: toastId,
                })
            }).catch(error => {
                toast.error(error.message, {
                    id: toastId,
                })
            }).finally(() => setLoadingButton(false));
        } else {
            setOpen(true)
        }
    }


    return (
        <div>
            <Titulo>
                <div className="breadcrumbs">
                    <ul>
                        <li><Link to="/">Inicio</Link></li>
                        <li><Link to="/directorio">Directorio</Link></li>
                        <li><a className="bc" style={{ borderRight: "solid 0px #ccc !important" }}>Miembro Directorio</a></li>
                    </ul>
                </div>
                <div className="clear"></div>
                <span className="t1b">Directorio</span>
            </Titulo>

            <div className="gris2">
                <div className="container">
                    <div className="row  justify-content-md-center">
                        <div className="col-md-12">
                            <div className="form rounded mb-4">
                                <div className="container">
                                    {
                                        loading ? <Spinner></Spinner> : <div className="row">
                                            <div className="col-md-3">
                                                <img src={user.imagen_perfil} alt="" className="rounded-circle" style={{ width: "90%", border: "solid 6px #917cfd" }} />
                                            </div>
                                            <div className="col-md-9">



                                                <div className="mb-4 row">
                                                    <div className="col-md-9"><h2>{user.nombreCompleto} </h2></div>
                                                    <div className="col-md-3  col-12 ms-auto ">
                                                        <Link className="btn btn-danger btn-sm float-end"  to='/directorio'><i className="fa-solid fa-arrow-left"></i> Volver a directorio</Link>
                                                    </div>
                                                </div>


                                                <Accordion defaultActiveKey={[0, 1, 2]} alwaysOpen>

                                                    <Accordion.Item key={0} eventKey={0} className='animation_input'>
                                                        <Accordion.Header>
                                                            <span className="icon-acc i-morado rounded-circle"> {user.idTipoPerfil == TIPO_USUARIO_PERSONA ? <FontAwesomeIcon icon={faUser} /> : <FontAwesomeIcon icon={faShieldHalved} />}</span>
                                                            <span className="acc-tit">{user.tipoPerfil}</span>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="infop">
                                                                <div><span className="i1">Nombre completo</span><span className="i2">{user.nombreCompleto}</span></div>
                                                                <div><span className="i1">Correo electrónico</span><span className="i2">{user.email}</span></div>
                                                                {/*<div><span className="i1">Teléfono</span><span className="i2">{user.telefono}</span></div>*/}
                                                                <div><span className="i1">Región</span><span className="i2">{user.region}</span></div>
                                                                <div><span className="i1">Comuna</span><span className="i2">{user.comuna}</span></div>
                                                                {user.web ? <div><span className="i1">Página Web</span><span className="i2" >  <a href={user.web} target="_blank">Ir a página web</a></span></div> : ""}
                                                                {user.youtube ? <div><span className="i1">Canal de youtube</span><span className="i2" >  <a href={user.youtube} target="_blank">Ir a canal de Youtube</a></span></div> : ""}
                                                                {user.facebook ? <div><span className="i1">Perfil de Facebook</span><span className="i2" >  <a href={user.facebook} target="_blank">Ir a perfil de Facebook</a></span></div> : ""}
                                                                {user.instagram ? <div><span className="i1">Perfil de instagram</span><span className="i2" >  <a href={user.instagram} target="_blank">Ir a perfil de Instagram</a></span></div> : ""}
                                                                {user.otraRrss ? <div><span className="i1">Otra RRSS</span><span className="i2" >  <a href={user.otraRrss} target="_blank">Ir</a></span></div> : ""}
                                                                <div><span className="i1">Corresponde a un espacio cultural</span> <span className="i2">{user?.espacioCultural ? "Sí" : "No"}</span></div>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>


                                                    {
                                                        user.idTipoPerfil == TIPO_USUARIO_ORGANIZACION ? (!user.representante_nombre && !user.representante_nombreOrganizacion) ? ""
                                                            : <Accordion.Item key={1} eventKey={1} className='animation_input'>
                                                                <Accordion.Header>
                                                                    <span className="icon-acc i-verde rounded-circle"> <FontAwesomeIcon icon={faAddressCard} /></span>
                                                                    <span className="acc-tit">Datos representante legal</span>
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className="infop">
                                                                        {user.representante_nombre ? <div><span className="i1">Nombre del representante legal</span><span className="i2">{user.representante_nombre} </span></div> : ""}
                                                                        {user.representante_nombreOrganizacion ? <div><span className="i1">Nombre de la organización</span><span className="i2">{user.representante_nombreOrganizacion} </span></div> : ""}
                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item> : ""
                                                    }

                                                    {
                                                        user.espacioCultural === true ? (!user.espacio_nombreEspacioCultural && !user.tipoEspacioCultural &&
                                                            !user.espacio_ficha_tecnica && !user.espacio_ficha_tecnica &&
                                                            !user.espacio_dossier && !user.espacio_web &&
                                                            !user.espacio_facebook && !user.espacio_instagram &&
                                                            !user.espacio_otraRrss) ? ""
                                                            : <Accordion.Item key={2} eventKey={2} className='animation_input'>
                                                                <Accordion.Header>
                                                                    <span className="icon-acc i-rojo rounded-circle"> <FontAwesomeIcon icon={faBuildingColumns} /></span>
                                                                    <span className="acc-tit">Datos Espacio Cultural</span>
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className="infop">
                                                                        {user.espacio_nombreEspacioCultural ? <div><span className="i1">Nombre del espacio de uso cultural</span><span className="i2">{user.espacio_nombreEspacioCultural}</span></div> : ""}
                                                                        {user.tipoEspacioCultural ? <div><span className="i1">Tipo de Espacio</span><span className="i2">{user.tipoEspacioCultural}</span></div> : ""}
                                                                        {user.espacio_ficha_tecnica ? <div><span className="i1">Ficha técnica</span><span className="i2"> <FontAwesomeIcon className={'mx-1'} icon={faFileLines} />  <a href={user.espacio_ficha_tecnica} target={'_blank'}> Ver Ficha técnica </a></span></div> : ""}
                                                                        {user.espacio_dossier ? <div><span className="i1">Dossier, memoria o portafolio </span><span className="i2"> <FontAwesomeIcon className={'mx-1'} icon={faFileLines} /> <a href={user.espacio_dossier} target={'_blank'}> Ver Dossier/memoria/portafolio </a> </span></div> : ""}
                                                                        {user.espacio_web ? <div><span className="i1">Página Web</span><span className="i2" ><a href={user.espacio_web} target="_blank">Ir a página web</a></span></div> : ""}
                                                                        {user.espacio_facebook ? <div><span className="i1">Perfil de Facebook</span><span className="i2" ><a href={user.espacio_facebook} target="_blank">Ir a perfil de Facebook</a></span></div> : ""}
                                                                        {user.espacio_instagram ? <div><span className="i1">Perfil de instagram</span><span className="i2" ><a href={user.espacio_instagram} target="_blank">Ir a perfil de Instagram</a></span></div> : ""}
                                                                        {user.espacio_otraRrss ? <div><span className="i1">Otra RRSS</span><span className="i2" ><a href={user.espacio_otraRrss} target="_blank">Ir</a></span></div> : ""}
                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item> : ""
                                                    }


                                                </Accordion>

                                                {isFetching || loadingButton ? <button className="btn btn-info mt-2" > <Spinner animation="grow" size="sm" /></button> :
                                                    data.find(element => element == user.idDirectorio) ?
                                                        <button className="btn btn-info mt-2" >Perfil Guardado</button> :
                                                        <button onClick={() => handleGuardarPerfil()} className="btn btn-info mt-2">Guardar Perfil <i style={{ margin: "0 0 0 10px" }} className="fa-solid fa-arrow-right"></i></button>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SesionModal open={open} setOpen={setOpen} />

        </div>
    )
}

export default UsuarioDetalle;