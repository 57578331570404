import { ErrorMessage, Field, useFormikContext } from "formik";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useGetCriteriosQuery, useGetPdfCriteriosQuery } from "../../../redux/features/ApiSlice";
import ValidationError from "../../utiles/ValidationError";


const CriterioOferta = (props) => {
    const {
        values: { criterio },
    } = useFormikContext();

    const {
        data: arrayCriterios = [],
        isLoading: criteriosLoad,
    } = useGetCriteriosQuery();

    const {
        data: pdf,
        isLoading: pdfLoad,
    } = useGetPdfCriteriosQuery();

    return (

        <div className="card">

            <div className="card-header">
                <strong>Seleccionar criterio</strong>
            </div>
            <div className="card-body">
                <div className="col-md-12">
                    <div >   <label htmlFor="criterio" className="form-label float-start">Criterio <span className="rojo">*</span> </label>
                        {!pdfLoad ? <a href={pdf.pdf} className="float-end btn btn-secondary btn-sm" style={{ marginTop: '-5px' }} download target="_blank">Descargar Criterios</a> : ""}
                    </div>


                    <Field className='form-control' id="criterio" name="criterio" as='select' >
                        <option value='' className="text-muted">Seleccionar</option>
                        {
                            criteriosLoad ? "" : arrayCriterios.data.map((region, index) => <option key={index} value={region.idCriterio}>
                                {region.criterio}
                            </option>)
                        }


                    </Field>
                    <ErrorMessage name="criterio" component={ValidationError} />
                </div>

                {
                    criterio ? <div className="mt-3 " >
                        <div>
                            <p className="fw-semibold">Descripción</p>
                        </div>
                        <div >
                            <p dangerouslySetInnerHTML={{ __html: (criteriosLoad) ? "" : arrayCriterios.data.find((value) => value.idCriterio == criterio).descripcion }}></p>

                        </div>
                    </div> : ""
                }
            </div>
        </div>)

}

export default CriterioOferta;