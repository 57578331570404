import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import axiosApi from "../api/axios/axiosApi";
import { OFERTA_GET_URI } from "../api/axios/constants";
import OfertaDetalles from "../components/ofertaAssets/OfertaDetalles";
import OfertaHeader from "../components/ofertaAssets/OfertaHeader";
import OfertaSociales from "../components/ofertaAssets/OfertaSociales";
import OfertasRelacionadas from "../components/ofertaAssets/OfertasRelacionadas";
import { setTitle, truncateString } from "../constants/utiles/Functions";
import { useSelector } from "react-redux";

const Oferta = (props) => {
    const { id_oferta } = useParams();
    const { oferta } = useSelector((state) => state.SSRSlice)

    const [ofertaCSR, setOfertaCSR] = useState({});
    const [loading, setLoading] = useState(typeof window === 'undefined' ? false : true);

    useEffect(() => {
        setTitle(props.title)
        const cancelToken = axios.CancelToken.source();


        axiosApi.get(OFERTA_GET_URI(id_oferta), { cancelToken: cancelToken.token }).then(response => {
            setOfertaCSR(response?.data?.data[0]);
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log(error);
            } else {
                console.error(error);
            }
        });
        return () => {
            cancelToken.cancel();
        }

    }, [id_oferta])

    return (
        <div>
            {loading ? <div className="mt-5 mb-5 ">
                <div style={{ textAlign: "center" }}>
                    <Spinner></Spinner>
                </div>
            </div> :
                <div className="animation_input">
                    <div className="gris g-blog">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="breadcrumbs">
                                        <ul>
                                            <li><Link to="/">Inicio</Link></li>
                                            <li><Link to={'/busqueda'}>Ofertas</Link></li>
                                            <li><a className="bc-w"
                                                style={{ borderRight: "solid 0px #ccc !important" }}>{truncateString(oferta.nombreOferta || ofertaCSR.nombreOferta, 50)}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="clear"></div>
                                    <span className="t1b">Ofertas Culturales</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <OfertaHeader oferta={typeof window === 'undefined' ? oferta : ofertaCSR} />

                    <hr />
                    <div className="container mt-4">
                        <OfertaSociales oferta={typeof window === 'undefined' ? oferta : ofertaCSR} />
                        <OfertaDetalles oferta={typeof window === 'undefined' ? oferta : ofertaCSR} />

                    </div>


                    <div className="gris2 mt-4">
                        <div className="container">
                            <OfertasRelacionadas id_region={typeof window === 'undefined' ? oferta?.idRegion : ofertaCSR.idRegion} id_oferta={id_oferta} />
                        </div>
                    </div>
                </div>}
        </div>
    )
}

export default Oferta;