import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { truncateString } from "../../constants/utiles/Functions";
const ResultadoItem = ({ id, img, nombre, agente, categoria, region }) => {
    return (
        <div className="col-md-4 mb-4 animation_input" style={{ minHeight: "340px" }} >
            <Link to={`/oferta/${id}`} className="oferta rounded">
                <img src={img} alt="" className="rounded-top" />
                <div className="ic">
                    <span className="tipoc1 rounded">{agente}</span>
                    <div className="clear"></div>
                    <span className="tc1">{truncateString(nombre,45)}</span>
                    <span className="pc1">{categoria}</span>
                    <span className="rc1">{region}</span>
                </div>
            </Link>
        </div>
    );
}

export default ResultadoItem;

ResultadoItem.propTypes = {
    id: PropTypes.number.isRequired,
    img: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
    agente: PropTypes.string.isRequired,
    categoria: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
}

ResultadoItem.defaultProps = {
    id: 0,
    img: '',
    nombre: '',
    agente: '',
    categoria: '',
    region: ''
}