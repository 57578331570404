import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ConvocatoriaItem = ({ id, fechaInicio, horaInicio, fechaPlazo, horaPlazo, estado, nombre, categoria }) => {
    const fecha_inicio = dayjs(fechaInicio, 'DD/MM/YYYY').locale("es");
    return (
        <div className="col-md-4 animation_input">
            <Link className="text-decoration-none" to={`/convocatoria/${id}`}>
                <div className="convocatoria rounded">
                    <div className="ct">
                        <div className="fecha rounded">

                            <span>{fecha_inicio.format('DD')}</span>
                            <p>{fecha_inicio.format('MMM')}</p>
                        </div>
                        <div className="info">
                            <span>{nombre}</span>
                            <p>{categoria}</p>
                        </div>
                    </div>
                    <div className="cb">
                        <div className="row">
                            <div className="col">
                                <span>Inicio</span>
                                <p>{fechaInicio} a las {horaInicio}</p>
                            </div>
                            <div className="col">
                                <span>Plazo</span>
                                <p>{fechaPlazo} a las {horaPlazo}</p>
                            </div>
                            <div className="col">
                                <span>Estado</span>
                                <p>{estado}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default ConvocatoriaItem;

ConvocatoriaItem.propTypes = {
    id: PropTypes.number.isRequired,
    fechaInicio: PropTypes.string.isRequired,
    horaInicio: PropTypes.string.isRequired,
    fechaPlazo: PropTypes.string.isRequired,
    horaPlazo: PropTypes.string.isRequired,
    estado: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
    categoria: PropTypes.string.isRequired,
}

ConvocatoriaItem.defaultProps = {
    id: 0,
    fechaInicio: '',
    horaInicio: '',
    fechaPlazo: '',
    horaPlazo: '',
    estado: '',
    nombre: '',
    categoria: '',
}