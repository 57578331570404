import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom'

const ProtectedRoute = () => {
    const isLogin = localStorage.getItem('userInfo') ? true : false;

    const navigate = useNavigate();
    // show unauthorized screen if no user is found in redux store
    useEffect(() => {
        if (!isLogin) {
            navigate('/login', { replace: true })
        }
    }, [isLogin])


    // returns child route elements
    return <Outlet />
}
export default ProtectedRoute