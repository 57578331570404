import { faCircleCheck, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Titulo from "../components/utiles/Titulo";
import * as Yup from 'yup';
import { useState } from "react";
import { toast } from "react-hot-toast";
import { ErrorMessage, Field, Form, Formik } from "formik";
import ValidationError from "../components/utiles/ValidationError";
import { handleInputRut, setTitle } from "../constants/utiles/Functions";
import { useEffect } from "react";
import { format, validate } from 'rut.js';
import axiosApi from "../api/axios/axiosApi";
import { LOGIN_URI } from "../api/axios/constants";
import { useDispatch } from "react-redux";
import { setIdDirectorio, setTimeout, setUserActive, setUserDB, setUserIsLogin } from "../redux/features/userActiveSlice";

const LoginUsuario = (params) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialValues = {
        rut: '',
        pass: ''
    };

    const validationSchema = Yup.object({
        rut: Yup.string().required('Campo requerido').test('rut-formato', 'Rut con formato invalido',
            (value) => {
                return validate(value)
            }
        ),

        pass: Yup.string().required('Campo requerido'),
    })

    const handleOnSubmit = (values, { resetForm }) => {
        setLoading(true);
        const toastId = toast.loading('Cargando...');
        const data = {
            rut: values.rut,
            password: values.pass
        };

        axiosApi.post(LOGIN_URI, data)
            .then(response => {
                if (response.data.error) {
                    setError(response.data.message)
                    toast.error('Ocurrió un error', {
                        id: toastId,
                    });
                } else {
                    const userInfo = {
                        nombre: response.data.data.nombreCompleto,
                        rut: response.data.data.rut
                    }

                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    dispatch(setUserActive(userInfo));
                    dispatch(setUserIsLogin(true));
                    dispatch(setUserDB(true));
                    dispatch(setIdDirectorio(response.data.data.idDirectorio))
                    dispatch(setTimeout());
                    toast.success('Sesión iniciada', {
                        id: toastId,
                    });

                    navigate(`/home-privado/inicio`);

                }
                setLoading(false);
            }).catch(error => {
                toast.error('Ocurrió un error', {
                    id: toastId,
                });
                setError("Ocurrio un error inesperado, favor contactar con administrador")
                setLoading(false);

            })



    }
    useEffect(() => {
        setTitle(params.title)
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (<Fragment>
        <Titulo>
            <div className="breadcrumbs">
                <ul>
                    <li><Link to="/">Inicio</Link></li>
                    <li><Link className="bc" style={{ borderRight: "solid 0px #ccc !important" }} >Iniciar sesión</Link></li>
                </ul>
            </div>
            <div className="clear"></div>
            <span className="t1b mb-4">Te damos la bienvenida al Catálogo digital</span>

            <p className="p1b">El Catálogo Digital para la programación cultural local es un registro web del Ministerio de las Culturas, las
                Artes y el Patrimonio, que busca generar contactos y redes entre agentes culturales, personas y organizaciones, programadores de
                actividades artísticas y oferentes de obras o proyectos artísticos culturales a lo largo de todo Chile.</p>

            <p>Es una herramienta en constante construcción colaborativa, abierta a la ciudadanía y las comunidades     </p>

            <p className="fw-bold">Aquí podrás acceder con tu cuenta registrada o con clave única, si tienes dificultades de acceso escríbenos al formulario de atención.</p>

        </Titulo>



        <div className="menu2">
            <div className="container">
                <ul>
                    <li><NavLink className="m2c" to=""><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faUserPlus}></FontAwesomeIcon> Iniciar sesión</NavLink></li>
                    <li><NavLink to="/login"><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faCircleCheck}></FontAwesomeIcon> Usar clave única</NavLink></li>
                    <li><NavLink to="/registrar-usuario"><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faUserPlus}></FontAwesomeIcon> Registrarme</NavLink></li>
                </ul>
            </div>
        </div>


        <div className="gris2">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <span style={{ color: "#888" }} className="txt1">¿Tienes problemas para iniciar sesión?  <Link to='/ayuda/contacto'>Consulta aquí</Link></span>
                    </div>
                </div>
                <div className="row">
                    <div className="form rounded mb-4">
                        <span className="tform">Iniciar sesión en Catálogo digital</span>
                        <span className="pform">Ingresa con tu perfil de usuario</span>


                        <div className="row justify-content-md-center mt-4">

                            <div className="col-md-5">

                                {error ? <div className="alert alert-danger" role="alert">
                                    {error}
                                </div> : ""}

                                <Formik
                                    validateOnChange={false}
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleOnSubmit}>
                                    {({
                                        values,
                                        handleBlur,
                                        setFieldValue,
                                    }) => (
                                        <Form >
                                            <div className="mb-3">
                                                <label htmlFor="rut" className="form-label">RUT *</label>
                                                <Field type="rut" className="form-control" id="rut" name="rut" aria-describedby="emailHelp" maxLength={13}
                                                    placeholder="Ej: 12.345.678-K"
                                                    pattern="[0-9]{1,3}.[0-9]{3}.[0-9]{3}-[0-9Kk]{1}"
                                                    onBlur={(event) => {
                                                        const formatted = format(values.rut);
                                                        setFieldValue("rut", formatted);
                                                        handleBlur(event);
                                                    }}

                                                    onKeyDown={handleInputRut}
                                                />
                                                <ErrorMessage name="rut" component={ValidationError} />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="pass" className="form-label">Contraseña *</label>
                                                <Field type="password" className="form-control" id="pass" name="pass" />
                                                <ErrorMessage name="pass" component={ValidationError} />
                                                <div id="emailHelp" className="form-text"><Link className="lm" to="/recuperar">¿Olvidaste la contraseña?</Link></div>
                                            </div>


                                            <div className="d-grid gap-2">
                                                <button className="btn btn-danger btn-lg" type="submit" disabled={loading}>Ingresar <i className="fa-solid fa-arrow-right"></i></button>
                                            </div>

                                            <div id="emailHelp" className="form-text txt-centro mt-4">¿Aún no tienes cuenta? <Link className="lm" to="/registrar-usuario">Regístrate aquí.</Link></div>

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </Fragment >);
}

export default LoginUsuario;