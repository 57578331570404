import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

const DirectorioItem = ({ id_directorio, img, nombre, agente }) => {
    return (
        <div>
            <Link to={`/detalle-usuario/${id_directorio}`} className="oferta rounded animation_input" style={{ minHeight: "340px" }}>
                <img src={img} alt="" className="rounded-top" />
                <div className="ic">
                    <span className="tc1">{nombre}</span>
                    <span className="pc1">{agente}</span>
                </div>
            </Link>
        </div>
    );
}

export default DirectorioItem;

DirectorioItem.propTypes = {
    id_directorio: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
    agente: PropTypes.string.isRequired,
}

DirectorioItem.defaultProps = {
    id: 0,
    img: '',
    nombre: '',
    agente: '',
}