import { createSlice } from '@reduxjs/toolkit'

const PrincipalSlice = createSlice({
  name: 'PrincipalSlice',
  initialState: {
    loading: false
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
  }
})

export const { setLoading } = PrincipalSlice.actions
export default PrincipalSlice.reducer