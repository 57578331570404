import { faFacebook, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";

const OfertaSociales = ({ oferta }) => {
    let url;
    if (typeof window === 'undefined') {
        url = process.env.REACT_APP_FRONTEND_URL + 'oferta/' + oferta.idOferta;
    } else {
        url = window?.location?.href;
    }




    return (<div className="comparte">
        <span>Comparte</span>
        <ul>
            <li><TwitterShareButton url={url} title={oferta.nombreOferta} via={"culturas_cl"}> <Link><FontAwesomeIcon icon={faTwitter} /></Link> </TwitterShareButton> </li>
            {/*<li><Link ><FontAwesomeIcon icon={faInstagram} /></Link></li>*/}
            <li><FacebookShareButton url={url} quote={oferta.nombreOferta}><Link><FontAwesomeIcon icon={faFacebook} /></Link></FacebookShareButton></li>
            <li><LinkedinShareButton url={url} title={oferta.nombreOferta} summary={oferta.descripcion} source={"Ministerio de cultura"}><Link><FontAwesomeIcon icon={faLinkedin} /></Link></LinkedinShareButton></li>

        </ul>
        <div className="clear"></div>
    </div>);
}

export default OfertaSociales;



OfertaSociales.propTypes = {
    oferta: PropTypes.object,
}

OfertaSociales.defaultProps = {
    oferta: {}
}