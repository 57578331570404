import CategoriaItem from "./CategoriaItem";
import backgroundSecciones1 from "../../img/ft1.png";
import Slider from "react-slick";
import PropTypes from 'prop-types';
import { Spinner } from "react-bootstrap";



const Categorias = (props) => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 6,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },

            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,

                }
            }
        ]

    };

    return (
        <div className="gris" style={{ overflow: 'hidden' }}>


            <div className="container">
                <div className="row">
                    <div className="col align-self-start">
                        <span className=" tg1" style={{ zIndex: 999, position: 'relative' }} >
                            Categorías
                        </span>
                    </div>
                </div>

                <div className="clear"></div>

                <div className="row"  >
                    <Slider {...settings}>
                        {
                            props.categorias.length === 0 ? <div className="mt-4 mb-5 ">
                                <div style={{ textAlign: "center" }} >
                                    <Spinner variant="light" ></Spinner>
                                </div>
                            </div> :
                                props.categorias.map((categorias, index) =>
                                    <CategoriaItem
                                        key={index}
                                        nombre={categorias.categoria}
                                        img={categorias.imagen}
                                        id={categorias.idCategoria}
                                    />
                                )
                        }
                    </Slider>
                </div>
            </div>

            <img style={{ margin: '-435px 0 0 0' }} src={backgroundSecciones1} alt="" />
        </div>

    );
}

export default Categorias;

Categorias.propTypes = {
    categorias: PropTypes.array.isRequired,
}

Categorias.defaultProps = {
    categorias: [],
}