import ConvocatoriaElementos from "./MisElementosComponents/ConvocatoriasElementos";
import PerfilesElementos from "./MisElementosComponents/PerfilesElementos";

const MisElementos = (props) => {



    return (
        <div className="col-md-9">
            <ConvocatoriaElementos user={props.user} />
            <PerfilesElementos user={props.user} />
        </div>
    )
}

export default MisElementos;