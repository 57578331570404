import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useGetCategoriaConvoQuery } from '../../redux/features/ApiSlice';
import { Spinner } from 'react-bootstrap';


const FiltroForm = (params) => {
    const [categoria, setCategoria] = useState(params.categoria || '');
    const [palabra, setPalabra] = useState(params.palabra || '');
    const [reload, setReload] = useState(false);

    const navigate = useNavigate();

    useEffect(() => { if (reload === true) { handleSubmit(); setReload(false); } }, [reload])

    const {
        data: arrayCategorias = [],
        isSuccess: categoriasSuccess
    } = useGetCategoriaConvoQuery();


    const handleLimpiar = () => {
        setCategoria('');
        setPalabra('');

        setReload(true);
    }

    const handleSubmit = () => {
        let data = {}
        if (categoria) {
            data.categoria = categoria
        }
        if (palabra) {
            data.palabra = encodeURIComponent(palabra)
        }
        params.setOrder('desc');
        navigate({
            pathname: "/convocatorias",
            search: createSearchParams(data).toString()
        });
    }

    const handleTags = () => {

        return (<ul>
            {
                params.filtros ? params.filtros.map((values, index) => {
                    return <li key={index} className="rounded">{values.nombre} <a onClick={() => removeTag(values.input)} style={{ cursor: 'pointer' }}>x</a></li>
                }) : ""
            }
        </ul>)
    }

    const removeTag = (input) => {
        switch (input) {
            case "categoria":
                setCategoria('');
                break;
            default:
                break;
        }

        setReload(true);

    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className="filtros rounded">
            <div className="ft">
                <span><i className="fa-solid fa-filter"></i> Filtros</span>
            </div>

            <div className='animation_input'>
                <div className="loading_class" style={{ display: params.loading ? "" : "none" }}>
                    <div style={{ position: "relative", top: "50%" }}>
                        <div style={{ textAlign: "center" }} >
                            <Spinner ></Spinner>
                        </div>
                    </div>
                </div>
                <div className="ft2">
                    <p>Palabra clave</p>
                    <input type="text" className="form-control" name="palabra" id="palabra" placeholder="Texto a buscar" value={palabra} onChange={e => setPalabra(e.target.value)} onKeyDown={handleKeyDown} />
                </div>
                <div className="ft2">
                    <span onClick={handleSubmit} style={{ cursor: 'pointer' }} >Aplicar filtros</span>
                    <a className="ft2l pe-auto" style={{ cursor: 'pointer' }} onClick={handleLimpiar} >Limpiar todo</a>
                    <div className="clear"></div>
                    {
                        handleTags()
                    }

                    <div className="clear"></div>
                </div>
                <div className="ft3">
                    <select className="form-select" aria-label="Default select example" value={categoria} onChange={e => { setCategoria(e.target.value); }}>
                        <option value={''}>Categoría</option>
                        {
                            categoriasSuccess ? arrayCategorias.data.map((categorias, index) =>
                                <option key={index}
                                    value={categorias.idCategoria}
                                >{categorias.categoria}</option>
                            ) : ""
                        }
                    </select>
                </div>
            </div>
        </div>
    );
}

export default FiltroForm;

FiltroForm.propTypes = {
    arrayCategorias: PropTypes.array,
    loading: PropTypes.bool
}

FiltroForm.defaultProps = {
    arrayCategorias: [],
    loading: false
}