import { faLaptop, faMobileScreen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';
import { useGetTotalesQuery } from "../../redux/features/ApiSlice";


const Estadisticas = () => {
    const {
        data: totales = [],
    } = useGetTotalesQuery();


    return (
        <div className="gris2">
            <div className="container">
                <div className="row datos">
                    <div className="col-md-4">
                        <FontAwesomeIcon icon={faMobileScreen} className='datos-icon' style={{ color: '#ff285c' }}></FontAwesomeIcon>
                        <span>{totales.data?.totalRegistrados ?? 0}</span>
                        <p>Agentes culturales registrados <br />en la plataforma</p>
                    </div>
                    <div className="col-md-4">
                        <FontAwesomeIcon icon={faLaptop} className='datos-icon' style={{ color: '#917cfd' }}></FontAwesomeIcon>
                        <span>{totales.data?.totalEspacios ?? 0}</span>
                        <p>Espacios culturales</p>
                    </div>
                    <div className="col-md-4">
                        <FontAwesomeIcon icon={faLaptop} className='datos-icon' style={{ color: '#56ebff' }}></FontAwesomeIcon>
                        <span>{totales.data?.totalOfertas ?? 0}</span>
                        <p>Ofertas registradas</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Estadisticas;

Estadisticas.propTypes = {
}

Estadisticas.defaultProps = {
}