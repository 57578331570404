import ItemNoticia from "./ItemNoticia";
import PropTypes from 'prop-types';
import Paginacion from "../utiles/Paginacion";
import { useState } from "react";
import { useEffect } from "react";
import { setTitle } from "../../constants/utiles/Functions";

const ResultadosBusqueda = ({ arrayNoticias, ...props }) => {
    useEffect(() => {
        setTitle(props.title)
    }, []);
    const [currentPage, setCurrentPage] = useState(1);

    const [itemList, setItemList] = useState(arrayNoticias);


    const handlePageClick = (data) => {
        const page = data.selected + 1;

        setCurrentPage(page);
    }

    const filterBySearch = (event) => {
        // Access input value
        const query = event.target.value;
        // Create copy of item list
        var updatedList = [...arrayNoticias];
        // Include all elements which includes the search query
        updatedList = updatedList.filter((item) => {
            return item.titulo.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(query.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) !== -1;
        });
        // Trigger render with updated values
        setItemList(updatedList);
    };

    return (
        <div className="noticias">
            <div className="container">
                {
                    arrayNoticias.length === 0 ? <div className="row animation_input">
                        <div className="col-12 mt-4 mb-4">
                            <span className="t-not">Todos los resultados</span>
                        </div>
                        <p>No se encontraron noticias</p>
                    </div>
                        :
                        <div className="row">
                            <div className="col-12 mt-4 mb-4">
                                <span className="t-not">Todos los resultados</span>
                                <div className="b-not">
                                    <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Buscar" onChange={filterBySearch} />
                                </div>
                            </div>




                            {
                                itemList.map((values, index) => <ItemNoticia
                                    key={index}
                                    imagen={values.imagen_destacada}
                                    descripcion={values.bajada.substring(0, 150) + "..."}
                                    titulo={values.titulo}
                                    fechaTiempo={values.fechaCreacion}
                                    tiempoLectura={values.minutosLectura}
                                    categoria={values.categoria}
                                    idNoticia={values.idNoticia}


                                />
                                )
                            }

                            <Paginacion
                                pageCount={Math.ceil(arrayNoticias.length / 6)}
                                currentpage={currentPage}
                                handlePageClick={handlePageClick}
                            />







                        </div>}

            </div>
        </div>
    );

}

export default ResultadosBusqueda;

ResultadosBusqueda.defaultProps = {
    arrayNoticias: [],
}
ResultadosBusqueda.propTypes = {
    arrayNoticias: PropTypes.array,
}

