import { useState } from "react"
import { useNavigate } from "react-router"
import { Button, Modal } from "react-bootstrap";

const SesionModal = ({ open, setOpen }) => {

    const navigate = useNavigate();

    const handleRedirigir = () => {
        navigate('/login');
    }

    return (<Modal show={open} onHide={() => { setOpen(false) }} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>¡Aviso!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Para usar esta funcionalidad, debe iniciar sesión en la página web.</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => { setOpen(false) }}>
                Cancelar
            </Button>
            <Button variant="primary" onClick={() => { handleRedirigir() }}>
                Iniciar sesión / Registrarse
            </Button>
        </Modal.Footer>
    </Modal>)
}

export default SesionModal;