import { useState } from "react";
import OfertaItem from "./OfertaItem";

import PropTypes from 'prop-types';

import { Spinner } from "react-bootstrap";


const Ofertas = ({ classDiv, children, backgroundStyle, backgroundImg, arrayOfertas }) => {


    return (
        <div className={classDiv}>
            <div className="container">
                {children}
                <div className="row">
                    {
                        arrayOfertas.length == 0 ? <div className="mt-5 mb-5 ">
                            <div style={{ textAlign: "center" }} >
                                <Spinner ></Spinner>
                            </div>
                        </div> : arrayOfertas.slice(0, 4).map((values, index) =>
                            <div className="col-sm-3 animation_input" key={index}>
                                <OfertaItem
                                    id={values.idOferta}
                                    nombre={values.nombreOferta}
                                    agente={values.tipoPerfil}
                                    img={values.imagen}
                                />
                            </div>
                        )
                    }


                </div>
            </div>
            {
                (backgroundImg != null) ? <img style={backgroundStyle} src={backgroundImg} alt="" /> : ""
            }
        </div>
    );
}
export default Ofertas;

Ofertas.propTypes = {
    classDiv: PropTypes.string.isRequired,
    backgroundStyle: PropTypes.object,
    backgroundImg: PropTypes.string,
    ofertas: PropTypes.array
}

Ofertas.defaultProps = {
    classDiv: 'blanco',
    arrayOfertas: []
}