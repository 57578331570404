import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { TAB_MENSAJERIA } from "../../constants/ConstantsTabsPrivado";
import PropTypes from 'prop-types';

const UltimosMensajesInicio = ({ arrayMensajes }) => {
    return (
        <Fragment style={{ marginTop: "50px" }}>
            <div className="rb">

                <div className="rbl">
                    <span>Ultimos mensajes</span>
                </div>
                <div className="rbr">
                    <Link to={`/home-privado/${TAB_MENSAJERIA}`}>Ver mas</Link>
                </div>

            </div>



            <div className="clear"></div>

            {
                arrayMensajes.map((mensaje) => <div className="msg shadow rounded mt-4">
                    <span className="letra rounded-circle">{mensaje.inicial_usuario}</span>
                    <div className="info1">
                        <Link to={`/usuario/${mensaje.id_usuario}`}>{mensaje.nombre_usuario}</Link>
                        <p>{mensaje.mensaje}</p>
                    </div>
                    <div className="info2">
                        <span>{mensaje.hora}</span> <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                </div>)
            }




            <div className="msg shadow rounded mt-4">
                <span className="letra rounded-circle">E</span>
                <div className="info1">
                    <Link to="#">Eric Hofman</Link>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio illo sunt harum, dolore voluptas a obcaecati praesentium nulla veritatis repudiandae saepe nesciunt eaque odio officia cupiditate omnis optio facilis voluptate!</p>
                </div>
                <div className="info2">
                    <span>3:32 pm</span> <FontAwesomeIcon icon={faCaretDown} />
                </div>
            </div>


            <div className="msg shadow rounded mt-4">
                <span className="letra rounded-circle">E</span>
                <div className="info1">
                    <Link to="#">Eric Hofman</Link>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio illo sunt harum, dolore voluptas a obcaecati praesentium nulla veritatis repudiandae saepe nesciunt eaque odio officia cupiditate omnis optio facilis voluptate!</p>
                </div>
                <div className="info2">
                    <span>3:32 pm</span> <FontAwesomeIcon icon={faCaretDown} />
                </div>
            </div>
            <div className="msg shadow rounded mt-4">
                <span className="letra rounded-circle">E</span>
                <div className="info1">
                    <Link to="#">Eric Hofman</Link>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio illo sunt harum, dolore voluptas a obcaecati praesentium nulla veritatis repudiandae saepe nesciunt eaque odio officia cupiditate omnis optio facilis voluptate!</p>
                </div>
                <div className="info2">
                    <span>3:32 pm</span> <FontAwesomeIcon icon={faCaretDown} />
                </div>
            </div>
        </Fragment>
    );
}

export default UltimosMensajesInicio;

UltimosMensajesInicio.propTypes = {
    arrayMensajes: PropTypes.array.isRequired
}

UltimosMensajesInicio.defaultProps = {
    arrayMensajes: []
}