import { createSlice } from '@reduxjs/toolkit'

const SSRSlice = createSlice({
  name: 'SSRSlice',
  initialState: {
    oferta: {}
  },
  reducers: {
    setOferta(state, action) {
      state.oferta = action.payload
    },
  }
});

export const { setOferta } = SSRSlice.actions
export default SSRSlice.reducer