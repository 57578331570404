import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

const OfertaItem = ({ id, img, nombre, agente }) => {
    return (
        <Link to={`/oferta/${id}`} className="oferta rounded animation_input">
            <img src={img} alt="" className="rounded-top" />
            <div className="ic">
                <span className="tc1">{nombre}</span>
                <span className="pc1">{agente}</span>
            </div>
        </Link>
    );
}

export default OfertaItem;

OfertaItem.propTypes = {
    id: PropTypes.number.isRequired,
    img: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
    agente: PropTypes.string.isRequired,
}

OfertaItem.defaultProps = {
    id: 0,
    img: '',
    nombre: '',
    agente: '',
}