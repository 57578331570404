import { createSearchParams, Link, useNavigate } from "react-router-dom"
import PropTypes from 'prop-types';

const CategoriaItem = ({ img, nombre, id }) => {
    const navigate = useNavigate();
    const handleSubmit = () => {
        navigate({
            pathname: "/busqueda",
            search: createSearchParams({ categoria: id, modo_categoria: true }).toString()
        });

    }

    return (<div>
        <a onClick={handleSubmit} style={{ cursor: 'pointer' }} className={'animation_input'}>
            <div className="categoria rounded" style={{ backgroundImage: `url(${img})` }}>
                <span className="rounded-bottom">{nombre}</span>
            </div>
        </a>
    </div>)
}
export default CategoriaItem;

CategoriaItem.propTypes = {
    id: PropTypes.number.isRequired,
    img: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
}

CategoriaItem.defaultProps = {
    id: 0,
    img: '',
    nombre: '',
}