import { NavLink } from "react-router-dom";

const Menu = () => {
    return (<div className="menu1 d-none d-lg-block ">
        <div className="container">
            <ul>
                <li><NavLink to="/">Inicio</NavLink></li>
                <li><NavLink to="/directorio">Directorio</NavLink></li>
                <li><NavLink to="/convocatorias">Convocatorias</NavLink></li>
                <li><NavLink to="/busqueda">Ofertas</NavLink></li>
                <li><NavLink to="/catalogos">Catálogos</NavLink></li>
                <li><NavLink to="/catalogo-noticias">Noticias</NavLink></li>
                <li><a href="http://eligecultura.gob.cl/events" target={'_blank'} title="Cartelera Chile Cultura">Cartelera</a></li>
            </ul>
        </div>
    </div>);
}

export default Menu;