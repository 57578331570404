import { faCircleQuestion, faEnvelope, faFileInvoice } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment } from "react"
import { Link, NavLink, useNavigate, useParams } from "react-router-dom"
import Contacto from "../components/componentsAyuda/Contacto"
import Criterios from "../components/componentsAyuda/Criterios"
import Faq from "../components/componentsAyuda/Faq"
import Titulo from "../components/utiles/Titulo"
import { TAB_CONTACTO, TAB_CONTACTO_TEL, TAB_FAQ, TAB_OFICINAS, TAB_CRITERIOS } from "../constants/ConstantsTabsAyuda"
import { setTitle } from "../constants/utiles/Functions"

const Ayuda = () => {

    const { active_tab } = useParams();
    const navigate = useNavigate();


    const handleTabChange = () => {
        switch (active_tab) {
            case TAB_CONTACTO_TEL:
                setTitle('Ayuda - Contacto telefónico');
                return <p>Contacto telefónico</p>
            case TAB_CONTACTO:
                setTitle('Ayuda - Contacto');
                return <Contacto />
            case TAB_OFICINAS:
                setTitle('Ayuda - Oficinas');
                return <p>Oficinas</p>
            case TAB_CRITERIOS:
                setTitle('Ayuda - Criterios de ingreso');
                return <Criterios />
            case TAB_FAQ:
                setTitle('Ayuda - FAQ');
                return <Faq />
            default:
                return '';

        }

    }

    return (<Fragment>
        <Titulo>
            <div className="breadcrumbs">
                <ul>
                    <li><Link to="/">Inicio</Link></li>
                    <li><Link className="bc" style={{ borderRight: 'solid 0px #ccc !important' }} to="/soporte">¿Necesitas ayuda?</Link></li>
                </ul>
            </div>
            <span className="t1b mb-4">¿Necesitas ayuda?</span>
            <p className="p1b">Aquí podrás resolver tus preguntas y encontrar la información para poder contactarte con nosotros. Estamos para ayudarte, puedes agendar una cita telefónica o enviarnos un correo electrónico con tus dudas.</p>
        </Titulo>

        <div className="menu2">
            <div className="container">
                <ul>
                    {/*<li><NavLink to={`/ayuda/${TAB_CONTACTO_TEL}`} className={active_tab === TAB_CONTACTO_TEL ? " m2c" : ""}><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faPhone} ></FontAwesomeIcon> Contacto telefónico</NavLink></li>*/}
                    <li><NavLink to={`/ayuda/${TAB_CONTACTO}`} className={active_tab === TAB_CONTACTO ? " m2c" : ""}><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faEnvelope} ></FontAwesomeIcon> Contacto</NavLink></li>
                    {/*<li><NavLink to={`/ayuda/${TAB_OFICINAS}`} className={active_tab === TAB_OFICINAS ? "m2c" : ""}><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faBuilding} ></FontAwesomeIcon> Oficinas</NavLink></li>*/}
                    <li><NavLink to={`/ayuda/${TAB_CRITERIOS}`} className={active_tab === TAB_CRITERIOS ? "m2c" : ""}><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faFileInvoice} ></FontAwesomeIcon> Criterios</NavLink></li>
                    <li><NavLink to={`/ayuda/${TAB_FAQ}`} className={active_tab === TAB_FAQ ? "m2c" : ""}><FontAwesomeIcon style={{ marginRight: "7px" }} icon={faCircleQuestion} ></FontAwesomeIcon> FAQ</NavLink></li>
                </ul>
            </div>
        </div>


        <div className="gris2">
            <div className="container">

                <div className="row">
                    <div className="col">


                    </div>
                </div>

                {handleTabChange()}

            </div>
        </div>
    </Fragment>)
}
export default Ayuda;