import axios from "axios";
import { Fragment, useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { useIdleTimer } from "react-idle-timer"
import { useDispatch } from "react-redux";
import { userLogout } from "../../redux/features/userActiveSlice";
const IdleTimerComponent = () => {

    const dispatch = useDispatch();
    // Set timeout values
    const timeout = Number(process.env.REACT_APP_TIMEOUT) || 1000 * 60 * 30;
    const promptTimeout = Number(process.env.REACT_APP_PROMPT_TIMEOUT) || 1000 * 30;

    // Modal open state
    const [open, setOpen] = useState(false)

    // Time before idle
    const [remaining, setRemaining] = useState(0)


    const onPrompt = () => {
        //TODO: inciar modal
        setOpen(true);
        setRemaining(promptTimeout);

    }

    const onIdle = () => {
        //TODO: Cerrar modal - cerrar sesión
        setOpen(false);
        setRemaining(0);

        const redirect_url_cu = encodeURIComponent(process.env.REACT_APP_REDIRECT_URL_CU);
        const url_cu_logout = process.env.REACT_APP_URL_LOGOUT + "?redirect=" + redirect_url_cu;
        axios.get(url_cu_logout);
        dispatch(userLogout())
        window.location.replace('/');
    }

    const onActive = (event) => {
        // TODO: Cerrar modal - refrescar timeout
        setOpen(false);
        setRemaining(0);
    }

    const onAction = (event) => {
        const dt = new Date();
        dt.setMinutes(dt.getMinutes() + 30)
        localStorage.setItem('timeout', dt.getTime());

    }


    const { getRemainingTime, isPrompted, activate } = useIdleTimer({
        timeout,
        promptTimeout,
        onPrompt,
        onIdle,
        onActive,
        crossTab: true,
        leaderElection: true,
        onAction,
        events: [
            'keydown',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange'
        ]
    })

    const handleStillHere = () => {
        setOpen(false);
        activate();
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000));
            }


        }, 1000)
        return () => {
            clearInterval(interval);
        }
    }, [getRemainingTime, isPrompted])



    return (
        <Fragment>
            <Modal show={open} onHide={onIdle} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>¡Aviso!</Modal.Title>
                </Modal.Header>
                <Modal.Body>Cerrando sesión en {remaining} segundos</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onIdle}>
                        Cerrar sesión
                    </Button>
                    <Button variant="primary" onClick={handleStillHere}>
                        Mantener sesión
                    </Button>
                </Modal.Footer>
            </Modal>

        </Fragment>
    )
}
export default IdleTimerComponent;