import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

const CarteleraItem = ({ url, img, nombre, agente, fecha, diasSemana, horasDia, estado }) => {
    return (
        <div className="col-md-4">
            <Link href={url} className="oferta rounded">
                <img src={img} alt="" className="rounded-top" />
                <div className="ic">
                    <span className="tc1">{nombre}</span>
                    <span className="pc1">{agente}</span>
                    <div className="row mt-2 cd">
                        <div className="col-md-4">
                            {fecha} <br />{fecha}
                        </div>
                        <div className="col-md-4">
                            {diasSemana} <br />{horasDia}
                        </div>
                        <div className="col-md-4">
                            {estado}
                        </div>
                    </div>
                </div>

            </Link>
        </div>
    );
}

export default CarteleraItem;

CarteleraItem.propTypes = {
    id: PropTypes.number.isRequired,
    img: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
    agente: PropTypes.string.isRequired,
    fecha: PropTypes.string.isRequired,
    diasSemana: PropTypes.string.isRequired,
    horasDia: PropTypes.string.isRequired,
    estado: PropTypes.string.isRequired,
}

CarteleraItem.defaultProps = {
    id: 0,
    img: '',
    nombre: '',
    agente: '',
    fecha: '',
    diasSemana: '',
    horasDia: '',
    estado: ''
}