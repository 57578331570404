import axios from "axios";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import axiosApi from "../../api/axios/axiosApi";
import { NOTICIAS_REL_URI } from "../../api/axios/constants";
import ItemNoticia from "../catalogoNoticias/ItemNoticia";

const NoticiasRelacionadas = ({ id_noticia, id_categoria }) => {
    const [loading, setLoading] = useState(true);
    const [arrayRelacionados, setArrayRelacionados] = useState([]);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        setLoading(true);
        axiosApi.get(NOTICIAS_REL_URI(id_categoria)).then(response => {
            const rel_noticias = response?.data?.data.filter(value => value.idNoticia != id_noticia)
            setArrayRelacionados(rel_noticias)
            setLoading(false);
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log(error);
            } else {
                console.error(error);
                setLoading(false)
            }
        })

        return () => {
            cancelToken.cancel();
        }
    }, [id_noticia])


    return (
        <div className="noticias">
            <div className="container">
                <div className="row">
                    <div className="col-12 mt-4 mb-4">
                        <span className="t-not">Contenido relacionado</span>

                    </div>

                    {
                        loading ? <div className="mt-5 mb-5 ">
                            <div style={{ textAlign: "center" }} >
                                <Spinner ></Spinner>
                            </div>
                        </div> : arrayRelacionados.length == 0 ? <span>No hay noticias relacionadas</span> : arrayRelacionados.slice(0, 3).map((values, index) => <ItemNoticia
                            key={index}
                            imagen={values.imagen_destacada}
                            descripcion={values.bajada}
                            titulo={values.titulo}
                            fechaTiempo={values.fechaCreacion}
                            tiempoLectura={values.minutosLectura}
                            categoria={values.categoria}
                            idNoticia={values.idNoticia}

                        />
                        )
                    }

                </div>
            </div>
        </div>)
}

export default NoticiasRelacionadas;