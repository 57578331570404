import {
  USER_INFO_CU, USUARIO_GET_URI,
} from '../../api/axios/constants';

import axiosApi from '../../api/axios/axiosApi';

import { setIdDirectorio, setTimeout, setUserActive, setUserDB, setUserIsLogin, userLogout } from '../features/userActiveSlice';
import axios from 'axios';
import { setLoading } from '../features/PrincipalSlice';
import { format } from 'rut.js';


export const callUserActive = (dispatch) => {
  const dt = new Date();

  const tokenLocalStorageBrowser = localStorage.getItem('token') || false;
  const timeoutLocalStorageBrowser = Number(localStorage.getItem('timeout')) || false;
  const userDBLocalStorageBrowser = localStorage.getItem('userDB') || false;
  const userInfoLocalStorageBrowser = JSON.parse(localStorage.getItem('userInfo')) || false;
  const idDirectorioStorageBrowser = localStorage.getItem('idDirectorio') || false;

  if (userInfoLocalStorageBrowser) {
    dispatch(setLoading(true));

    if (typeof timeoutLocalStorageBrowser === 'number' && timeoutLocalStorageBrowser <= dt.getTime()) {

      dispatch(userLogout());
      dispatch(setLoading(false));


      const redirect_url_cu = encodeURIComponent(process.env.REACT_APP_REDIRECT_URL_CU);
      const url_cu_logout = process.env.REACT_APP_URL_LOGOUT + "?redirect=" + redirect_url_cu;

      axios.get(url_cu_logout);
      return;
    }

    if (userInfoLocalStorageBrowser) {
      dispatch(setUserActive(userInfoLocalStorageBrowser))
      dispatch(setUserIsLogin(true));
      dispatch(setTimeout());
      if (userDBLocalStorageBrowser && idDirectorioStorageBrowser) {
        dispatch(setUserDB(true));
        dispatch(setIdDirectorio(idDirectorioStorageBrowser))

      }

      dispatch(setLoading(false));
    } else if (tokenLocalStorageBrowser) {
      const cancelToken = axios.CancelToken.source();
      //TODO: Implementar api usuario de backend

      axiosApi.post(USER_INFO_CU, null, { params: { token: tokenLocalStorageBrowser, cancelToken: cancelToken.token } })
        .then(response_info => {
          if (response_info.data.error === true) {
            localStorage.removeItem('token')
            localStorage.removeItem('userInfo')
          } else {
            const userInfo = {
              nombre: `${response_info.data.data.name.nombres.join(' ')} ${response_info.data.data.name?.apellidos.join(' ')}`,
              rut: format(`${response_info.data.data.RolUnico.numero}${response_info.data.data.RolUnico.DV}`)
            };
            localStorage.setItem('userInfo', JSON.stringify(response_info.data.data));
            dispatch(setUserActive(userInfo));
            dispatch(setUserIsLogin(true));
            axiosApi.get(USUARIO_GET_URI(format(`${response_info.data.data.RolUnico.numero}${response_info.data.data.RolUnico.DV}`))).then(responseDB => {
              dispatch(setLoading(false))
              if (responseDB.data.data !== null) {
                dispatch(setUserDB(true));
                dispatch(setIdDirectorio(responseDB.data.data.idDirectorio))
              }
              dispatch(setLoading(false))
            }).catch(error => {
              dispatch(setLoading(false))
            })
          }
          dispatch(setLoading(false));
        }).catch(error => {
          if (axios.isCancel(error)) {
            console.log("cancelado");
          } else {
            console.error(error)
          }
          localStorage.removeItem('token')
          localStorage.removeItem('userInfo')

          dispatch(setLoading(false));
        });
    } else {

    }

  } else {
    return dispatch(setUserIsLogin(false));

  }


}




