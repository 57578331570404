import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import axiosApi from "../../api/axios/axiosApi";
import { UPDATE_REGISTRO_DIRECTORIO } from "../../api/axios/constants";
import { getDateFormat, getDateNow } from "../../constants/utiles/DateFunctions";
import { checkIfFileIsTooBig, getBase64, regexTelefono, validateImageType } from "../../constants/utiles/Functions";
import { useGetGenerosQuery, useGetRegionesQuery, useGetTipoEspaciosCulturalesQuery, useGetTipoOrganizacionesQuery, useGetTipoPerfilesQuery, useGetTipoPersonasQuery } from "../../redux/features/ApiSlice";

import * as Yup from 'yup';
import { toast } from "react-hot-toast";
import ValidationError from "../utiles/ValidationError";
import FieldComuna from "../form/FieldComuna";
import { setUserActive } from "../../redux/features/userActiveSlice";
import { TIPO_USUARIO_ORGANIZACION, TIPO_USUARIO_PERSONA } from "../../constants/Constants";
import { Spinner } from "react-bootstrap";

const EditProfile = ({ user }) => {
    const tokenLocalStorageBrowser = localStorage.getItem('token') || false;
    const [file, setFile] = useState();
    const [userLoad, setUserLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [arrayComunas, setArrayComunas] = useState([]);
    const {
        data: regiones = [],
        isLoading: regionesLoad,

    } = useGetRegionesQuery()

    const {
        data: generos = [],
        isLoading: generosLoad,
    } = useGetGenerosQuery();


    const {
        data: perfiles = [],
        isLoading: perfilesLoad,
    } = useGetTipoPerfilesQuery();

    const {
        data: tipoPersonas = [],
        isLoading: tipoPersonasLoad,
    } = useGetTipoPersonasQuery();
    const {
        data: tipoEspacios = [],
        isLoading: tipoEspaciosLoad,
    } = useGetTipoEspaciosCulturalesQuery();

    const {
        data: tipoOrg = [],
        isLoading: tipoOrgLoad,
    } = useGetTipoOrganizacionesQuery();

    const dispatch = useDispatch();



    useEffect(() => {
        if (!regionesLoad && !generosLoad && !perfilesLoad && !tipoPersonasLoad && !tipoEspaciosLoad && !tipoOrgLoad && user.idDirectorio) {
            setFile(user.imagen_perfil);
            setLoading(false);
            setUserLoad(false);
        }
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regionesLoad, generosLoad, perfilesLoad, tipoPersonasLoad, tipoEspaciosLoad, tipoOrgLoad, user]);



    const loadComunas = (region) => {
        setArrayComunas(region)
    }


    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const initialValues = {
        nombre: user.nombreCompleto,
        genero: user.idGenero,
        fechaNacimiento: getDateFormat(user?.fechaNacimiento?.date, 'YYYY-MM-DD'),
        originario: user.puebloOriginario ? "1" : "0",
        originarioDetalle: user.puebloOriginarioDetalle ?? '',
        migrante: user.poblacionMigrante ? "1" : "0",
        migranteDetalle: user.poblacionMigranteDetalle ?? '',
        discapacidad: user.discapacidad ? "1" : "0",
        discapacidadDetalle: user.discapacidadDetalle ?? '',
        terceraEdad: user.terceraEdad ? "1" : "0",
        email: user.email,
        telefono: user.telefono,
        region: user.idRegion,
        comuna: user.idComuna,
        direccion: user.direccion,
        espacioCultural: user.espacioCultural ? "1" : "0",
        tipoPerfil: user.idTipoPerfil,
        tipoPersona: user.idTipoPerfil == TIPO_USUARIO_PERSONA ? user.idTipoPersona : "",
        tipoOrg: user.idTipoPerfil == TIPO_USUARIO_ORGANIZACION ? user.idTipoOrganizacion : "",
        tipoEspacio: user.espacioCultural ? user.idEspacioCultural : "",
        imagen: null,
        web: user.web,
        youtube: user.youtube,
        facebook: user.facebook,
        instagram: user.instagram,
        otra_rrss: user.otraRrss,
    };


    const validationSchema = Yup.object({
        nombre: Yup.string().required('Campo requerido').max(200, 'El texto ingresado no debe sobrepasar los 200 caracteres'),
        genero: Yup.string().required('Debe seleccionar una opción de género'),
        fechaNacimiento: Yup.date().required('Campo requerido').max(now, 'Debe seleccionar una fecha menor a la de hoy'),
        email: Yup.string().email('Correo electronico ingresado invalido').required('Campo requerido').max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        telefono: Yup.string().matches(regexTelefono, 'Formato de numero de telefono invalido').required('Campo requerido').max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        region: Yup.string().required('Campo requerido'),
        comuna: Yup.string().required('Campo requerido'),
        direccion: Yup.string().required('Campo requerido').max(200, 'El texto ingresado no debe sobrepasar los 200 caracteres'),
        originarioDetalle: Yup.string().when("originario", {
            is: '1',
            then: Yup.string().required('Campo requerido')
        }).max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        migranteDetalle: Yup.string().when("migrante", {
            is: '1',
            then: Yup.string().required('Campo requerido')
        }).max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        discapacidadDetalle: Yup.string().when("discapacidad", {
            is: '1',
            then: Yup.string().required('Campo requerido')
        }).max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        tipoPerfil: Yup.string(),
        tipoPersona: Yup.string().when("tipoPerfil", {
            is: TIPO_USUARIO_PERSONA,
            then: Yup.string().required('Campo requerido')
        }),
        tipoOrg: Yup.string().when("tipoPerfil", {
            is: TIPO_USUARIO_ORGANIZACION,
            then: Yup.string().required('Campo requerido')
        }),
        tipoEspacio: Yup.string().when("espacioCultural", {
            is: '1',
            then: Yup.string().required('Campo requerido')
        }),
        imagen: Yup.mixed()
            .nullable()
            .test('is-correct-file', 'The file is too large', checkIfFileIsTooBig)
            .test(
                'is-big-file',
                'Solo se aceptan los siguientes formatos de archivo: .png, .gif, jpeg y .jpg',
                validateImageType
            ),
        web: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        youtube: Yup.string().max(200, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        facebook: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 200 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        instagram: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        otra_rrss: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
    })

    const handleOnSubmit = (values) => {
        setLoading(true);
        const toastId = toast.loading('Cargando...');
        const data = {
            nombre: values.nombre,
            rut: user.rut,
            fecha_nacimiento: values.fechaNacimiento,
            pueblo_originario: values.originario === '1' ? "true" : "false",
            pueblo_originario_detalle: values.originario === '1' ? values.originarioDetalle : null,
            poblacion_migrante: values.migrante === '1' ? "true" : "false",
            poblacion_migrante_detalle: values.migrante === '1' ? values.migranteDetalle : null,
            discapacidad: values.discapacidad === '1' ? "true" : "false",
            discapacidad_detalle: values.discapacidad === '1' ? values.discapacidadDetalle : null,
            tercera_edad: values.terceraEdad === '1' ? "true" : "false",
            email: values.email,
            telefono: values.telefono,
            direccion: values.direccion,
            id_comuna: values.comuna,
            id_genero: values.genero,
            id_tipo_perfil: values.tipoPerfil,
            espacio_cultural: values.espacioCultural === '1' || values.espacioCultural == true ? "true" : "false",
            id_tipo_persona: values.tipoPerfil == TIPO_USUARIO_PERSONA ? values.tipoPersona : null,
            id_tipo_organizacion: values.tipoPerfil == TIPO_USUARIO_ORGANIZACION ? values.tipoOrg : null,
            id_tipo_espacio_cultural: values.espacioCultural === '1' ? values.tipoEspacio : null,
            web: values.web,
            youtube: values.youtube,
            facebook: values.facebook,
            instagram: values.instagram,
            otra_rrss: values.otra_rrss
        }

        if (values.imagen) {
            data.imagen_name = values.imagen.name;
            data.imagen_mime_type = values.imagen.type;
            data.imagen_data = null;
            getBase64(values.imagen)
                .then(result => {
                    const base64result = result.split(',')[1];
                    data['imagen_data'] = base64result;

                    postData(data, toastId);
                })
                .catch(err => {
                    console.log(err);
                });

        } else {
            postData(data, toastId);
        }


    }

    const postData = (data, toastId) => {
        axiosApi.put(UPDATE_REGISTRO_DIRECTORIO(user.idDirectorio), data
        )
            .then(response => {
                if (response.data.error === true) {
                    toast.error(response.data.message, {
                        id: toastId,
                    });

                } else {
                    toast.success(response.data.message, {
                        id: toastId,
                    });

                    const userInfo = {
                        nombre: data.nombre,
                        rut: user.rut
                    }

                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    dispatch(setUserActive(userInfo));


                }
                setLoading(false)
            }).catch((error) => {
                toast.error('Ha ocurrido un error al registrarse', {
                    id: toastId,
                });

                console.error(error);
                setLoading(false)

            });
    }



    return (<div className="col-md-9">

        <div className="rb">
            <div className="rbl mb-4">
                <span>Información personal <a className="edit1" href="#"></a></span>
            </div>
        </div>

        <div className="clear"></div>

        {
            userLoad ? <div className="mt-1 mb-5 ">
                <div style={{ textAlign: "center" }} >
                    <Spinner ></Spinner>
                </div>
            </div> :

                <div className="animation_input">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleOnSubmit}
                        validationSchema={validationSchema}

                    >
                        {props => (
                            <Form className="">
                                <div className="row mb-2">
                                    <label className="col-sm-5 col-form-label">Rut</label>
                                    <span className="col-sm-7 i2">{user?.rut}</span>
                                </div>

                                {
                                    tokenLocalStorageBrowser ? <div className="row mb-2">
                                        <label className="col-sm-5 col-form-label">Nombre</label>
                                        <span className="col-sm-7 i2">{user?.nombreCompleto}</span>
                                    </div> : <div>

                                        <div className="row mb-2">
                                            <label htmlFor="nombre" className="col-sm-5 col-form-label">Nombre <span className="rojo">*</span></label>
                                            <div className="col-sm-7">
                                                <Field type="text" className='form-control' id="nombre" name='nombre' />
                                            </div>
                                            <ErrorMessage name="nombre" component={ValidationError} />
                                        </div>

                                    </div>

                                }
                                <div className="row mb-2">
                                    <label htmlFor="genero" className="col-sm-5 col-form-label">Género<span className="rojo">*</span></label>
                                    <div className="col-sm-7">
                                        <Field name="genero" as="select" className="form-control" >
                                            <option value='' className="text-muted">Seleccionar</option>
                                            {
                                                generosLoad ? "" : generos.data.map((genero, index) => <option key={index} value={genero.idGenero}>
                                                    {genero.genero}
                                                </option>)
                                            }
                                        </Field>
                                    </div>
                                    <ErrorMessage name="genero" component={ValidationError} />
                                </div>

                                <div>

                                    <div className="row mb-2">
                                        <label className="col-sm-5 col-form-label">¿Corresponde a espacio cultural?</label>
                                        <div className="col-sm-7 pt-2">
                                            <div className="form-check form-check-inline">
                                                <Field className="form-check-input" type="radio" name="espacioCultural" id="espacioCultural_option1" value={'1'} />
                                                <label className="form-check-label" htmlFor="espacioCultural_option1">Si</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Field className="form-check-input" type="radio" name="espacioCultural" id="espacioCultural_option2" value={'0'} />
                                                <label className="form-check-label" htmlFor="espacioCultural_option2">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {props.values.espacioCultural === '1' ?
                                    <div className="row mb-2 animation_input">
                                        <label htmlFor="tipoEspacio" className="col-sm-5 col-form-label">Tipo de Espacio cultural <span className="rojo">*</span></label>
                                        <div className="col-sm-7">
                                            <Field name="tipoEspacio" as="select" className="form-control" >
                                                <option value={''}>Seleccionar</option>
                                                {tipoEspaciosLoad ? "" : tipoEspacios.data.map((tipo, index) => <option key={index} value={tipo.idEspacioCultural}>{tipo.espacioCultural}</option>)}

                                            </Field>
                                        </div>
                                        <ErrorMessage name="tipoEspacio" component={ValidationError} />
                                    </div> : ''
                                }


                                {
                                    tokenLocalStorageBrowser ? '' : <div>

                                        <div className="row mb-2">
                                            <label className="col-sm-5 col-form-label" htmlFor="tipoPerfil" >Tipo de perfil</label>
                                            <div className="col-sm-7">
                                                <Field name="tipoPerfil" as="select" className="form-control" >
                                                    {perfilesLoad ? "" : perfiles.data.map((agentes, index) => <option key={index} value={agentes.idTipoPerfil}>{agentes.tipoPerfil}</option>)}

                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {props.values.tipoPerfil == TIPO_USUARIO_PERSONA ?
                                    <div className="row mb-2 animation_input">
                                        <label htmlFor="tipoPersona" className="col-sm-5 col-form-label">Tipo de usuario<span className="rojo">*</span></label>
                                        <div className="col-sm-7">
                                            <Field name="tipoPersona" as="select" className="form-control" >
                                                <option value={''}>Seleccionar</option>
                                                {tipoPersonasLoad ? "" : tipoPersonas.data.map((tipo, index) => <option key={index} value={tipo.idTipoPersona}>{tipo.tipoPersona}</option>)}

                                            </Field>
                                        </div>
                                        <ErrorMessage name="tipoPersona" component={ValidationError} />
                                    </div> : ''
                                }
                                {props.values.tipoPerfil == TIPO_USUARIO_ORGANIZACION ?
                                    <div className="row mb-2 animation_input">
                                        <label htmlFor="tipoOrg" className="col-sm-5 col-form-label">Tipo de organización<span className="rojo">*</span></label>
                                        <div className="col-sm-7">
                                            <Field name="tipoOrg" as="select" className="form-control" >
                                                <option value={''}>Seleccionar</option>
                                                {tipoOrgLoad ? "" : tipoOrg.data.map((tipo, index) => <option key={index} value={tipo.idTipoOrganizacion}>{tipo.tipoOrganizacion}</option>)}

                                            </Field>
                                        </div>
                                        <ErrorMessage name="tipoOrg" component={ValidationError} />
                                    </div> : ''
                                }

                                <div className="row mb-2">
                                    <label htmlFor="fechaNacimiento" className="col-sm-5 col-form-label">Fecha de nacimiento <span className="rojo">*</span></label>
                                    <div className="col-sm-7">
                                        <Field name="fechaNacimiento" type="date" className="form-control" max={getDateNow()} value={props.values.fechaNacimiento} />
                                    </div>
                                    <ErrorMessage name="fechaNacimiento" component={ValidationError} />
                                </div>

                                <div className="row mb-2">
                                    <label className="col-sm-5 col-form-label" >¿Perteneces a algún pueblo originario?</label>
                                    <div className="col-sm-7 pt-2">
                                        <div className="form-check form-check-inline">
                                            <Field className="form-check-input" type="radio" name="originario" id="originario_option1" value={'1'} />
                                            <label className="form-check-label" htmlFor="originario_option1">Si</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <Field className="form-check-input" type="radio" name="originario" id="originario_option2" value={'0'} />
                                            <label className="form-check-label" htmlFor="originario_option2">No</label>
                                        </div>
                                    </div>

                                </div>

                                {props.values.originario === '1' ?
                                    <div className="row mb-2 animation_input ">
                                        <label htmlFor="originarioDetalle" className="col-sm-5 col-form-label">¿Cuál? <span className="rojo">*</span></label>
                                        <div className="col-sm-7">
                                            <Field type="text" className='form-control' id="originarioDetalle" name='originarioDetalle' />
                                        </div>
                                        <ErrorMessage name="originarioDetalle" component={ValidationError} />
                                    </div> : ''
                                }

                                <div className="row mb-2">
                                    <label className="col-sm-5 col-form-label">¿Perteneces a población migrante?</label>
                                    <div className="col-sm-7 pt-2">
                                        <div className="form-check form-check-inline">
                                            <Field className="form-check-input" type="radio" name="migrante" id="migrante_option1" value={'1'} />
                                            <label className="form-check-label" htmlFor="migrante_option1">Si</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <Field className="form-check-input" type="radio" name="migrante" id="migrante_option2" value={'0'} />
                                            <label className="form-check-label" htmlFor="migrante_option2">No</label>
                                        </div>
                                    </div>


                                </div>
                                {props.values.migrante === '1' ?
                                    <div className="row mb-2  animation_input">
                                        <label htmlFor="migranteDetalle" className="col-sm-5 col-form-label">¿Cuál? <span className="rojo">*</span></label>
                                        <div className="col-sm-7">
                                            <Field type="text" className='form-control' id="migranteDetalle" name='migranteDetalle' />
                                        </div>
                                        <ErrorMessage name="migranteDetalle" component={ValidationError} />
                                    </div> : ''
                                }
                                <div className="row mb-2">
                                    <label className="col-sm-5 col-form-label">¿Posees alguna discapacidad?</label>
                                    <div className="col-sm-7 pt-2">
                                        <div className="form-check form-check-inline">
                                            <Field className="form-check-input" type="radio" name="discapacidad" id="discapacidad_option1" value={'1'} />
                                            <label className="form-check-label" htmlFor="discapacidad_option1">Si</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <Field className="form-check-input" type="radio" name="discapacidad" id="discapacidad_option1" value={'0'} />
                                            <label className="form-check-label" htmlFor="discapacidad_option1">No</label>
                                        </div>
                                    </div>


                                </div>

                                {props.values.discapacidad === '1' ?
                                    <div className="row mb-2  animation_input">
                                        <label htmlFor="discapacidadDetalle" className="col-sm-5 col-form-label">¿Cuál? <span className="rojo">*</span></label>
                                        <div className="col-sm-7">
                                            <Field type="text" className='form-control' id="discapacidadDetalle" name='discapacidadDetalle' />
                                        </div>
                                        <ErrorMessage name="discapacidadDetalle" component={ValidationError} />
                                    </div> : ''
                                }

                                <div className="row mb-2">
                                    <label className="col-sm-5 col-form-label">¿Perteneces a la tercera edad?</label>
                                    <div className="col-sm-7 pt-2">
                                        <div className="form-check form-check-inline">
                                            <Field className="form-check-input" type="radio" name="terceraEdad" id="tercera_edad_option1" value={'1'} />
                                            <label className="form-check-label" htmlFor="tercera_edad_option1">Si</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <Field className="form-check-input" type="radio" name="terceraEdad" id="tercera_edad_option2" value={'0'} />
                                            <label className="form-check-label" htmlFor="tercera_edad_option2">No</label>
                                        </div>
                                    </div>
                                </div>






                                <div className="row mb-2">
                                    <label htmlFor="email" className="col-sm-5 col-form-label">Correo electrónico <span className="rojo">*</span></label>
                                    <div className="col-sm-7">
                                        <Field type="email" className='form-control' id="email" name='email' />
                                    </div>
                                    <ErrorMessage name="email" component={ValidationError} />
                                </div>


                                <div className="row mb-2">
                                    <label htmlFor="telefono" className="col-sm-5 col-form-label">Teléfono <span className="rojo">*</span></label>
                                    <div className="col-sm-7">
                                        <Field type="text" className='form-control' id="telefono" name="telefono" />
                                    </div>
                                    <ErrorMessage name="telefono" component={ValidationError} />
                                </div>


                                <div className="row mb-2">
                                    <label htmlFor="region" className="col-sm-5 col-form-label">Región <span className="rojo">*</span></label>
                                    <div className="col-sm-7">
                                        <Field className='form-control' id="region" name="region" as='select' >
                                            <option value='' className="text-muted">Seleccionar</option>
                                            {
                                                regionesLoad ? "" : regiones.data.map((region, index) => <option key={index} value={region.idRegion}>
                                                    {region.region}
                                                </option>)
                                            }


                                        </Field>
                                    </div>
                                    <ErrorMessage name="region" component={ValidationError} />
                                </div>

                                <div className="row mb-2">
                                    <label htmlFor="comuna" className="col-sm-5 col-form-label">Comuna <span className="rojo">*</span></label>
                                    <div className="col-sm-7">
                                        <FieldComuna className='form-control' id="comuna" name="comuna" as='select' loadcomunas={loadComunas} >
                                            <option value='' className="text-muted">Seleccionar</option>
                                            {
                                                arrayComunas.map((comuna, index) => <option key={index} value={comuna.idComuna} >
                                                    {comuna.comuna}
                                                </option>)

                                            }

                                        </FieldComuna>
                                    </div>
                                    <ErrorMessage name="comuna" component={ValidationError} />
                                </div>

                                <div className="row mb-2">
                                    <label htmlFor="direccion" className="col-sm-5 col-form-label" >Dirección <span className="rojo">*</span></label>
                                    <div className="col-sm-7">
                                        <Field type="text" className='form-control' id="direccion" name="direccion" placeholder="1234 Main St" />
                                    </div>
                                    <ErrorMessage name="direccion" component={ValidationError} />
                                </div>


                                <div className="col-12 mb-3">
                                    <label htmlFor="imagen" className="form-label">Imagen de perfil <span className="rojo">*</span></label>
                                    <input id="imagen" name="imagen" type="file" className={`form-control  ${props.errors.imagen ? "is-invalid" : ""}`} onChange={(event) => {
                                        props.setFieldValue("imagen", event.currentTarget.files[0]);
                                        setFile(URL.createObjectURL(event.target.files[0]));
                                    }} accept="image/png, image/jpeg, image/gif, image/jpg" />

                                    {
                                        props.errors.imagen ? "" : <div className="mt-3 pt-2 pb-2 card mx-auto" >
                                            <img src={file} className="rounded-circle mx-auto" style={{ width: "90%", borderRadius: "50%", verticalAlign: "middle", width: "200px", height: "200px" }} />
                                        </div>
                                    }

                                    <ErrorMessage name="imagen" component={ValidationError} />

                                </div>

                                <hr />
                                <span>
                                    <div style={{ fontWeight: "bold" }}> Datos redes sociales</div>
                                </span>

                                <div className="row mb-2">
                                    <label htmlFor="web" className="col-sm-5 col-form-label" >Página Web </label>
                                    <div className="col-sm-7">
                                        <Field type="text" className='form-control' id="web" name="web" placeholder="https://" />
                                    </div>
                                    <ErrorMessage name="web" component={ValidationError} />
                                </div>
                                <div className="row mb-2">
                                    <label htmlFor="youtube" className="col-sm-5 col-form-label" >Canal de youtube </label>
                                    <div className="col-sm-7">
                                        <Field type="text" className='form-control' id="youtube" name="youtube" placeholder="https://www.youtube.com/channel/" />
                                    </div>
                                    <ErrorMessage name="youtube" component={ValidationError} />
                                </div>
                                <div className="row mb-2">
                                    <label htmlFor="facebook" className="col-sm-5 col-form-label" >Perfil de Facebook </label>
                                    <div className="col-sm-7">
                                        <Field type="text" className='form-control' id="facebook" name="facebook" placeholder="https://www.facebook.com/" />
                                    </div>
                                    <ErrorMessage name="facebook" component={ValidationError} />
                                </div>
                                <div className="row mb-2">
                                    <label htmlFor="instagram" className="col-sm-5 col-form-label" >Perfil de instagram</label>
                                    <div className="col-sm-7">
                                        <Field type="text" className='form-control' id="instagram" name="instagram" placeholder="https://www.instagram.com/" />
                                    </div>
                                    <ErrorMessage name="instagram" component={ValidationError} />
                                </div>
                                <div className="row mb-2">
                                    <label htmlFor="otra_rrss" className="col-sm-5 col-form-label" >Otra RRSS </label>
                                    <div className="col-sm-7">
                                        <Field type="text" className='form-control' id="otra_rrss" name="otra_rrss" placeholder="https://" />
                                    </div>
                                    <ErrorMessage name="otra_rrss" component={ValidationError} />
                                </div>

                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger btn-lg" disabled={loading}>Guardar <i className="fa-solid fa-arrow-right"></i></button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div >
        }


        <div className="clear"></div>
    </div >)
}

export default EditProfile;