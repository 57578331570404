import { ErrorMessage, Field } from "formik";
import { useState } from "react";
import { useGetRegionesQuery } from "../../../redux/features/ApiSlice";
import FieldComuna from "../../form/FieldComuna";
import ValidationError from "../../utiles/ValidationError";

const UbicacionOferta = (props) => {
    const [arrayComunas, setArrayComunas] = useState([]);
    const {
        data: regiones = [],
        isLoading: regionesLoad,
        isError: regionesIsError,
        error: regionesError
    } = useGetRegionesQuery()



    const loadComunas = (region) => {
        setArrayComunas(region)
    }
    return (<div className="card">
        <div className="card-header">
            <strong>Detalle Información</strong>
        </div>
        <div className="card-body">
            <div className="row g-3">
                <div className="col-md-12">
                    <label htmlFor="region" className="form-label">Región <span className="rojo">*</span></label>

                    <Field className='form-control' id="region" name="region" as='select' >
                        <option value='' className="text-muted">Seleccionar</option>
                        {
                            regionesLoad ? "" : regiones.data.map((region, index) => <option key={index} value={region.idRegion}>
                                {region.region}
                            </option>)
                        }


                    </Field>
                    <ErrorMessage name="region" component={ValidationError} />
                </div>

                <div className="col-md-12">
                    <label htmlFor="comuna" className="form-label">Comuna <span className="rojo">*</span></label>
                    <FieldComuna className='form-control' id="comuna" name="comuna" as='select' loadcomunas={loadComunas}>
                        <option value='' className="text-muted">Seleccionar</option>
                        {
                            arrayComunas.map((comuna, index) => <option key={index} value={comuna.idComuna}>
                                {comuna.comuna}
                            </option>)
                        }

                    </FieldComuna>
                    <ErrorMessage name="comuna" component={ValidationError} />
                </div>


            </div>
        </div>
    </div>)

}

export default UbicacionOferta;