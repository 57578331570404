import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ESTADO_ABIERTO_STRING } from '../../constants/Constants';

const ConvocatoriaItem = ({ convocatoria }) => {
    const fecha_inicio = dayjs(convocatoria.fechaInicio, 'DD/MM/YYYY').locale("es");
    return (
        <div className="convocatoria rounded mt-3 animation_input">
            <div className='row'>
                <div className='col-md-6'>
                    <div className="ct">
                        <div className="fecha rounded">
                            <span>{fecha_inicio.format('DD')}</span>
                            <p>{fecha_inicio.format('MMM')}</p>
                        </div>
                        <div className="info">
                            <span>{convocatoria.convocatoriaNombre}</span>
                            <p>{convocatoria.categoria}</p>
                        </div>
                    </div>
                    <div className="cb">
                        <div className="row">
                            <div className="col">
                                <span>Inicio</span>
                                <p>{convocatoria.fechaInicio} a las {convocatoria.HoraInicio}</p>
                            </div>
                            <div className="col">
                                <span>Plazo</span>
                                <p>{convocatoria.fechaPlazo} a las {convocatoria.HoraFin}</p>
                            </div>
                            <div className="col">
                                <span>Estado</span>
                                <p>{ESTADO_ABIERTO_STRING}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6" >
                    <div className="">
                        <p className="small">{convocatoria.descripcion}</p>
                        <Link to={`/convocatoria/${convocatoria.idConvocatoria}`} className="btn btn-info">Ver convocatoria <FontAwesomeIcon style={{ margin: "0 0 0 10px" }} icon={faArrowRight} /></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConvocatoriaItem;

ConvocatoriaItem.propTypes = {
    id: PropTypes.number.isRequired,
    fechaInicio: PropTypes.string.isRequired,
    horaInicio: PropTypes.string.isRequired,
    fechaPlazo: PropTypes.string.isRequired,
    horaPlazo: PropTypes.string.isRequired,
    estado: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
    categoria: PropTypes.string.isRequired,
}

ConvocatoriaItem.defaultProps = {
    id: 0,
    fechaInicio: '',
    horaInicio: '',
    fechaPlazo: '',
    horaPlazo: '',
    estado: '',
    nombre: '',
    categoria: '',
}