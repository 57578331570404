import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';

const Paginacion = (props) => {
    return (
        <div className="d-flex justify-content-center">
            <ReactPaginate
                pageRangeDisplayed={4}
                marginPagesDisplayed={2}
                previousLabel="Anterior"
                nextLabel="Siguente"
                onPageChange={props.handlePageClick}
                disableInitialCallback={true}
                pageCount={props.pageCount}
                initialPage={props.currentPage}
                forcePage={props.currentPage}
                page={props.currentPage}
                breakClassName={'page-item disabled'}
                breakLinkClassName={'page-link disabled'}
                disabledClassName={'disabled'}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                activeClassName={'active'}
            />
        </div>
    );
}

export default Paginacion;

Paginacion.propTypes = {
    handlePageClick: PropTypes.func.isRequired,
    pageCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number,
}

Paginacion.defaultProps = {
    pageCount: 0,
}