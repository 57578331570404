import PropTypes from 'prop-types';

const SelectOrden = (params) => {
    return (
        <div className="rbr">
            <div className="row g-3 align-items-center">
                <div className="col-auto">
                    <label htmlFor="inputPassword6" className="col-form-label" style={params.styleOrder}>Ordenar por</label>
                </div>
                <div className="col-auto">
                    <select style={{ width: "250px" }} className="form-select form-select-sm" onChange={(e) => params.handleOrdering(e.target.value)} aria-label="Default select example" value={params.order}>
                        <option value='desc'>Más nuevo</option>
                        <option value="asc">Más antiguo</option>
                    </select>
                </div>

            </div>
        </div>
    );
}

export default SelectOrden;

SelectOrden.propTypes = {
    handleOrdering: PropTypes.func.isRequired,
}
