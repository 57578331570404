import { faDownload, faFilePdf, faFileWord } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';

const FaqAdjuntos = ({ arrayAdjuntos }) => {

    return (
        <div>
            {arrayAdjuntos.length === 0 ? "" :
                <div className="animation_input">

                    <ul className="ul-recursos">
                        {arrayAdjuntos.map((adjunto, index) => {
                            return (<li key={index}><a href={adjunto.adjunto} target={'_blank'} style={{ width: "100%" }}>
                                <div style={{ margin: "0" }} className="col-md-11">
                                    <FontAwesomeIcon icon={adjunto.extension === 'pdf' ? faFilePdf : faFileWord} className="i-1"></FontAwesomeIcon>
                                    <div>
                                        <span>{adjunto.titulo}</span>
                                        <p >{adjunto.descripcion}</p>
                                    </div>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faDownload} className="i-2"></FontAwesomeIcon>
                                </div>
                            </a></li>)
                        })}
                    </ul>
                </div>}
        </div>)
}

export default FaqAdjuntos;

FaqAdjuntos.propTypes = {
    arrayAdjuntos: PropTypes.array
}

FaqAdjuntos.defaultProps = {
    arrayAdjuntos: []
}