import logger from 'redux-logger'
import { configureStore } from '@reduxjs/toolkit';


import userActiveSlice from './features/userActiveSlice';
import PrincipalSlice from './features/PrincipalSlice';
import { ApiSlice } from './features/ApiSlice';
import SSRSlice from './features/SSRSlice';


export const store = configureStore({
    reducer: {
        userActive: userActiveSlice,
        PrincipalSlice: PrincipalSlice,
        SSRSlice: SSRSlice,
        [ApiSlice.reducerPath]: ApiSlice.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(ApiSlice.middleware).concat(logger),
    devTools: process.env.NODE_ENV !== 'production'
})




