import { ErrorMessage, Field, useFormikContext } from "formik";
import ValidationError from "../../utiles/ValidationError";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";

const InfoExtraOferta = ({ dossierDefault }) => {
    const [file, setFile] = useState(dossierDefault || null);

    let {
        errors,
        setFieldValue,
        touched
    } = useFormikContext();

    return (<div className="card">
        <div className="card-header">
            <strong>Detalle Información</strong>
        </div>
        <div className="card-body">
            <div className="col-md-12">
                <label htmlFor="youtube" className="col-sm-5 col-form-label">Link video promocional youtube</label>
                <Field type="text" className='form-control' id="youtube" name="youtube" placeholder="https://www.youtube.com/watch?v=" />
                <ErrorMessage name="youtube" component={ValidationError} />
            </div>


            <div className="col-md-12">
                <label htmlFor="youtube" className="col-sm-5 col-form-label">Dossier Obra</label>

                <input id="respaldo" name="respaldo" type="file" className={`form-control  ${errors.dossier ? "is-invalid" : ""}`} onChange={(event) => {
                    touched.dossier = true;
                    setFieldValue("dossier", event.currentTarget.files[0]);
                    setFile(URL.createObjectURL(event.target.files[0]));
                }} accept="application/pdf, application/x-pdf" />

                {
                    errors.dossier || !file ? "" : <div className="mt-3 pt-2 pb-2 mx-auto" >
                        <a href={file} target={'_blank'}> <FontAwesomeIcon className={'mx-1'} icon={faFile} /> Documento dossier </a>
                    </div>
                }

                <ErrorMessage name="dossier" component={ValidationError} touched={touched.dossier} />

            </div>
        </div>
    </div>)

}

export default InfoExtraOferta;