import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, useFormikContext } from "formik";
import { useState } from "react";
import ValidationError from "../../utiles/ValidationError";

const RespaldoOferta = ({respaldoDefault}) => {
    const [file, setFile] = useState(respaldoDefault || null);

    let {
        errors,
        setFieldValue,
        touched
    } = useFormikContext();

    return (<div className="card">
        <div className="card-header">
            <strong>Tamaño máximo 5MB en formato PDF</strong>
        </div>
        <div className="card-body">

            <div className="row g-3">
                <div className="col-md-12">
                    <input id="respaldo" name="respaldo" type="file" className={`form-control  ${errors.respaldo ? "is-invalid" : ""}`} onChange={(event) => {
                        touched.respaldo = true;
                        setFieldValue("respaldo", event.currentTarget.files[0]);
                        setFile(URL.createObjectURL(event.target.files[0]));
                    }} accept="application/pdf, application/x-pdf" />

                    {
                        errors.respaldo || !file ? "" : <div className="mt-3 pt-2 pb-2 mx-auto" >
                            <a href={file} target={'_blank'}> <FontAwesomeIcon className={'mx-1'} icon={faFile}/> Documento de respaldo </a>
                        </div>
                    }

                    <ErrorMessage name="respaldo" component={ValidationError} touched={touched.respaldo} />

                </div>
            </div>
        </div>
    </div>)

}

export default RespaldoOferta;