import { useState } from "react";
import toast from "react-hot-toast";
import axiosApi from "../api/axios/axiosApi";
import { NEWSLETTER_POST_URI } from "../api/axios/constants";
import { validadorEmail } from "../constants/utiles/Functions";

const Footer = () => {
    const [correo, setCorreo] = useState('');
    const [loadButton, setLoadButton] = useState(false);




    const handleSubmitNewsletter = (e) => {
        e.preventDefault();
        setLoadButton(true);
        const toastId = toast.loading('Cargando...');

        if (validadorEmail(correo)) {
            axiosApi.post(NEWSLETTER_POST_URI, null, { params: { email: correo } })
                .then(response => {
                    if (response.data.error === true) {
                        toast.error(response.data.message, {
                            id: toastId,
                        });

                    } else {
                        toast.success('Correo registrado correctamente', {
                            id: toastId,
                        });

                    }
                    setLoadButton(false)
                }).catch((error) => {
                    toast.error('Ha ocurrido un error al registrar el correo', {
                        id: toastId,
                    });

                    console.error(error);
                    setLoadButton(false)

                });

        } else {
            toast.error('Debe ingresar un correo válido', {
                id: toastId,
            });

            setLoadButton(false);
        }

    }

    return (
        <div className="gris">
            <div className="container">

                <div className="newsletter rounded">
                    <span>Newsletter</span>
                    <p>Suscríbete para recibir las últimas novedades.</p>

                    <form>
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">Correo electrónico</label>
                            <input type="text" className="form-control" id="exampleInputPassword1" onChange={e => setCorreo(e.target.value)} value={correo} />
                        </div>
                        <button type="submit" className="btn btn-danger" onClick={handleSubmitNewsletter} disabled={loadButton}>Suscribirme</button>
                    </form>

                </div>


                <div className="footer">
                    <img src="/img/logo-mincap.png" alt="" />
                    <div id="p-footer">
                        <p>
                            <strong><a href="https://www.cultura.gob.cl/" target="_blank">Ministerio de las Culturas, las Artes y el Patrimonio</a></strong> <br />
                            <a href="https://www.gob.cl/" target="_blank">Gobierno de Chile</a><br />
                            Dirección Valparaíso: Plaza Sotomayor 233. Teléfono: (32) 2326400<br />
                            Dirección Santiago: Paseo Ahumada 48, Pisos 4, 5, 6, 7, 8 y 11.<br />
                            Teléfonos: 226189000 – 226189001<br />
                            <a href="https://siac.cultura.gob.cl/formulariosiac" target="_blank">Formulario de atención ciudadana Subsecretaría de las Culturas y las Artes</a><br />
                            <a href="https://www.cultura.gob.cl/politica-de-privacidad/" target="_blank">Política de Privacidad </a>
                        </p>
                    </div>

                </div>

                <div className="clear"></div>
            </div>
        </div>
    );
}

export default Footer;