import { faCalendar, faClock, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faArrowAltCircleRight, faLocationPin, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from "dayjs";
import { Link } from 'react-router-dom';
import { CONVO_ESTADO_APROBADO, CONVO_ESTADO_REVISION, ESTADO_APROBADO } from '../../constants/Constants';

const ConvocatoriaCreadaItem = ({ convocatoria, setIdEliminar, setOpen, handleSelectedId }) => {
    const fecha_inicio = dayjs(convocatoria.fechaInicio, 'DD/MM/YYYY').locale("es");

    let background_estado = "#dc3545";
    
    if(convocatoria.estado === CONVO_ESTADO_APROBADO){
        background_estado = "#008000"
    }else if(convocatoria.estado === CONVO_ESTADO_REVISION){
        background_estado = "#EF6024"
    }
    return (
        <div className="convocatoria rounded mt-4 animation_input">
            <div className="row">

                <div className="col-12"><span style={{ fontWeight: "bold", fontSize: "20px", display: "block", margin: "0 0 10px 0" }}>{convocatoria.convocatoriaNombre}</span></div>
                <div className="col-md-6">
                    <div className="imagen rounded">
                        <span className="fecha rounded"><span>{fecha_inicio.format('DD')}</span> <br /> <span>{fecha_inicio.format('MMM')}</span></span>
                    </div>

                    <div className="info">
                        <span className="cat rounded ">{convocatoria.categoria}</span>
                        

                        <ul className='mt-1'>
                            <li><FontAwesomeIcon icon={faLocationPin}></FontAwesomeIcon> {convocatoria.region}</li>
                            <li><FontAwesomeIcon icon={faClock}></FontAwesomeIcon> {convocatoria.HoraInicio} - {convocatoria.HoraFin}</li>
                            <li><FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon> {convocatoria.fechaInicio} - {convocatoria.fechaFin}</li>
                        </ul>

                        <ul className="cat-of " style={{ overflow: "hidden" }}>
                            <li><a style={{ backgroundColor: background_estado, color: "white" }} >ESTADO: {convocatoria.estado}</a></li>
                        </ul>
                    </div>

                </div>
                <div className="col-md-6" style={{ position: "relative" }}>
                    <div className="info2">
                        <p className="small">{convocatoria.descripcion}</p>
                        {
                            convocatoria.aprobada ? <Link type="submit" className="btn btn-info" to={`/convocatoria/${convocatoria.idConvocatoria}`}>Ver convocatoria <FontAwesomeIcon style={{ margin: "0 0 0 10px" }} icon={faArrowAltCircleRight} ></FontAwesomeIcon></Link> : ""

                        }
                    </div>
                    <Link className="del-conv" onClick={() => {
                        setIdEliminar(convocatoria.idConvocatoria);
                        setOpen(true);
                    }}><FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon></Link>
                    <Link onClick={() => handleSelectedId(convocatoria.idConvocatoria)} className="edit-conv"><FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon></Link>
                </div>
            </div>
        </div>
    )
}

export default ConvocatoriaCreadaItem;