import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import ValidationError from '../../utiles/ValidationError';
import PropTypes from 'prop-types';

const ParticipantesModal = ({ open, setOpen, handleAddParticipante, nombre, webUrl, facebookUrl, instagramUrl }) => {

    const initialValues = {
        nombre_participante: nombre || '',
        web: webUrl || '',
        facebook: facebookUrl || '',
        instagram: instagramUrl || '',
    };


    const validationSchema = Yup.object({
        nombre_participante: Yup.string().required('Campo necesario'),
        web: Yup.string().url('Formato incorrecto de URL (Debe iniciar con http:// o https://)'),
        facebook: Yup.string().url('Formato incorrecto de URL (Debe iniciar con http:// o https://)'),
        twitter: Yup.string().url('Formato incorrecto de URL (Debe iniciar con http:// o https://)'),
        instagram: Yup.string().url('Formato incorrecto de URL (Debe iniciar con http:// o https://)'),
        otro: Yup.string().url('Formato incorrecto de URL (Debe iniciar con http:// o https://)'),
    })

    const handleOnSubmit = (values) => {

        const data = {
            nombre_participante: values.nombre_participante,
            web: values.web || null,
            facebook: values.facebook || null,
            instagram: values.instagram || null,
        }


        handleAddParticipante(data)

        setOpen(false)

    }

    return (<Modal show={open} onHide={() => { setOpen(false) }} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>Ingresar participante</Modal.Title>
        </Modal.Header>
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}

        >
            {props => (
                <Form className="">
                    <Modal.Body>

                        <div className="row g-3">
                            <div className="col-md-12">
                                <label htmlFor="nombre_participante" className="form-label">Nombre participante <span className="rojo">*</span></label>
                                <Field type="text" className='form-control' id="nombre_participante" name='nombre_participante' />
                                <ErrorMessage name="nombre_participante" component={ValidationError} />
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="web" className="form-label">Página web </label>
                                <Field type="text" className='form-control' id="web" name='web' placeholder='https://' />
                                <ErrorMessage name="web" component={ValidationError} />
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="facebook" className="form-label">Facebook </label>
                                <Field type="text" className='form-control' id="facebook" name='facebook' placeholder='https://' />
                                <ErrorMessage name="facebook" component={ValidationError} />
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="instagram" className="form-label">Instagram </label>
                                <Field type="text" className='form-control' id="instagram" name='instagram' placeholder='https://' />
                                <ErrorMessage name="instagram" component={ValidationError} />
                            </div>

                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setOpen(false) }}>
                            Cancelar
                        </Button>
                        <Button type='submit' variant="primary">
                            Guardar
                        </Button>

                    </Modal.Footer>
                </Form>)
            }
        </Formik>
    </Modal>)
}

export default ParticipantesModal;

ParticipantesModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    handleAddParticipante: PropTypes.func.isRequired,
    nombre: PropTypes.string,
    webUrl: PropTypes.string,
    facebookUrl: PropTypes.string,
    instagramUrl: PropTypes.string,

}

ParticipantesModal.defaultProps = {
    open: false,
    idParticipante: null,
    nombre: '',
    webUrl: '',
    facebookUrl: '',
    twitterUrl: '',
    instagramUrl: '',
    otroUrl: '',
}