import { ErrorMessage, Field } from "formik";
import { useGetPublicoQuery } from "../../../redux/features/ApiSlice";
import ValidationError from "../../utiles/ValidationError";

const PublicoOferta = (props) => {
    const {
        data: publicoArray = [],
        isLoading,
    } = useGetPublicoQuery()


    //TODO: ENDPOINNT
    return (<div className="card">
        <div className="card-header">
            <strong>Detalle Información</strong>
        </div>
        <div className="card-body">
            <div className="row g-3">
                <div className="col-md-12">
                    <label htmlFor="publico" className="form-label">Público <span className="rojo">*</span></label>
                    <Field name="publico" as="select" className="form-control" >
                        <option value=''>Seleccionar</option>
                        {!isLoading ? publicoArray.data.map((value, index) => <option key={index} value={value.idPublico}>{value.publico}</option>)
                            : ""}
                    </Field>
                    <ErrorMessage name="publico" component={ValidationError} />
                </div>

            </div>
        </div>
    </div>)

}

export default PublicoOferta;