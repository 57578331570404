import { faArrowRight, faPhoneVolume, faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { truncateString } from "../../constants/utiles/Functions";

const FondoConvoItem = ({ id, categoria, nombre, descripcion, fechaInicio, fechaFin }) => {
    const handleIconCategory = (categoria) => {
        switch (categoria) {
            case 'convocatoria':
                return <FontAwesomeIcon icon={faPhoneVolume} transform={{ rotate: -45 }} />
            case 'fondos':
                return <FontAwesomeIcon icon={faSackDollar} />
            default:
                return '';
        }

    }

    return (
        <div className="col-md-4 mb-4 animation_input" >
            <div className="oferta rounded" style={{maxHeight: "320.5px"}}>
                <div className="ic" >
                    <span className="tcon"><span className="ico rounded-circle">
                        {handleIconCategory(categoria)}
                    </span>{truncateString(nombre,20)}</span>
                    <span>{categoria}</span>
                    <div className="clear"></div>
                    <div className="fechas">
                        <div className="fe1">
                            <span>Fecha inicio</span>
                            <p>{fechaInicio}</p>
                        </div>
                        <div className="fe2">
                            <span>Fecha fin</span>
                            <p>{fechaFin}</p>
                        </div>
                    </div>
                    <div className="clear"></div>
                    <p className="fep">{truncateString(descripcion , 190)}</p>
                    <Link to={`/convocatoria/${id}`} className="cona rounded">Ver convocatoria <FontAwesomeIcon style={{ margin: '0 0 0 10px' }} icon={faArrowRight} /></Link>
                </div>
            </div>
        </div>
    );
}

export default FondoConvoItem;

FondoConvoItem.propTypes = {
    id: PropTypes.number.isRequired,
    categoria: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
    descripcion: PropTypes.string.isRequired,
    fechaInicio: PropTypes.string.isRequired,
    fechaFin: PropTypes.string.isRequired,
}

FondoConvoItem.defaultProps = {
    id: 0,
    categoria: '',
    nombre: '',
    descripcion: '',
    fechaInicio: '',
    fechaFin: '',
}