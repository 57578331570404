import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Titulo from "../components/utiles/Titulo";
import * as Yup from 'yup';
import { useState } from "react";
import { toast } from "react-hot-toast";
import { ErrorMessage, Field, Form, Formik } from "formik";
import ValidationError from "../components/utiles/ValidationError";
import { setTitle } from "../constants/utiles/Functions";
import { useEffect } from "react";
import axiosApi from "../api/axios/axiosApi";
import { RECUPERAR_PASSWORD_URI } from "../api/axios/constants";
import { useDispatch } from "react-redux";

const Password = (params) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialValues = {
        email: '',
    };

    const validationSchema = Yup.object({
        email: Yup.string().email('Correo electronico ingresado invalido').required('Campo requerido'),
    })

    const handleOnSubmit = (values, { resetForm }) => {
        setLoading(true);
        const toastId = toast.loading('Cargando...');

        axiosApi.get(RECUPERAR_PASSWORD_URI(values.email))
            .then(response => {
                if (response.data.error) {
                    setError(response.data.message)
                    toast.error(response.data.message, {
                        id: toastId,
                    });
                } else {
                    toast.success(response.data.message, {
                        id: toastId,
                    });

                    navigate(`/login-usuario`);

                }
                setLoading(false);
            }).catch(error => {
                toast.error('Ocurrió un error', {
                    id: toastId,
                });
                setError("Ocurrio un error inesperado, favor contactar con administrador")
                setLoading(false);

            })



    }
    useEffect(() => {
        setTitle(params.title)
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (<Fragment>
        <Titulo>
            <div className="breadcrumbs">
                <ul>
                    <li><Link to="/">Inicio</Link></li>
                    <li><Link className="bc" style={{ borderRight: "solid 0px #ccc !important" }} >Iniciar sesión</Link></li>
                </ul>
            </div>
            <div className="clear"></div>
            <span className="t1b mb-4">Te damos la bienvenida al Catálogo digital</span>

            <p className="p1b">El Catálogo Digital para la programación cultural local es un registro web del Ministerio de las Culturas, las
                Artes y el Patrimonio, que busca generar contactos y redes entre agentes culturales, personas y organizaciones, programadores de
                actividades artísticas y oferentes de obras o proyectos artísticos culturales a lo largo de todo Chile.</p>

            <p>Es una herramienta en constante construcción colaborativa, abierta a la ciudadanía y las comunidades     </p>

        </Titulo>



        <div className="gris2">
            <div className="container">

                <div className="row">
                    <div className="form rounded mb-4">
                        <span className="tform">Recuperación de contraseña</span>
                        <span className="pform">Ingresa tu correo electronico</span>


                        <div className="row justify-content-md-center mt-4">

                            <div className="col-md-5">

                                <Formik
                                    validateOnChange={false}
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={handleOnSubmit}>
                                    {({
                                        values,
                                        handleBlur,
                                        setFieldValue,
                                    }) => (
                                        <Form >

                                            <div className="mb-3">
                                                <label htmlFor="email" className="form-label">Correo electronico *</label>
                                                <Field type="email" className="form-control" id="email" name="email" />
                                                <ErrorMessage name="email" component={ValidationError} />
                                            </div>


                                            <div className="d-grid gap-2">
                                                <button className="btn btn-danger btn-lg" type="submit" disabled={loading}>Ingresar <i className="fa-solid fa-arrow-right"></i></button>
                                            </div>

                                            <div id="emailHelp" className="form-text txt-centro mt-4">¿Aún no tienes cuenta? <Link className="lm" to="/registrar-usuario">Regístrate aquí.</Link></div>

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </Fragment >);
}

export default Password;