import { ErrorMessage, Field } from "formik";
import ValidationError from "../../utiles/ValidationError";

const PrecioOferta = (props) => {
    return (<div className="card">
        <div className="card-header">
            <strong>Detalle Información</strong>
        </div>
        <div className="card-body">
            <div className="row g-3">
                <div className="col-md-6">
                    <label htmlFor="valorOferta" className="col-form-label">Valor de la oferta (contempla IVA)<span className="rojo">*</span></label>
                    <Field type="text" className='form-control' id="valorOferta" name='valorOferta' />
                    <ErrorMessage name="valorOferta" component={ValidationError} />
                    {/*
                    <div className="mt-3">
                        <label className="form-check-label" ><Field className="form-check-input" type="checkbox" name="exentoIVA" /> Exento de IVA</label>
                        <ErrorMessage name="exentoIVA" component={ValidationError} />
                </div>*/}
                </div>

                <div className="col-md-6">
                    <label htmlFor="ofertaProduccion" className="col-form-label">¿Valor incluye producción?<span className="rojo">*</span></label>
                    <Field name="ofertaProduccion" as="select" className="form-control" >
                        <option value=''>Seleccionar</option>
                        <option value={'1'}>Si</option>
                        <option value={'0'}>No</option>
                    </Field>
                    <ErrorMessage name="ofertaProduccion" component={ValidationError} />


                </div>
            </div>
        </div>
    </div>)

}

export default PrecioOferta;