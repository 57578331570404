import { Field, useField, useFormikContext } from "formik";
import { Fragment, useEffect } from "react";
import PropTypes from 'prop-types';
import { useGetComunasRegionQuery } from "../../redux/features/ApiSlice";

const FieldComuna = ({ loadcomunas, ...props }) => {
    const {
        values: { region },
        touched,
        setFieldValue,
        initialValues
    } = useFormikContext();
    const [field, meta] = useField(props);

    let skip = false;
    if (!region) {
        skip = true;
    }
    const {
        data: data,
        isSuccess
    } = useGetComunasRegionQuery(region, { skip })

    useEffect(() => {
        if (region == initialValues.region) {
            setFieldValue(props.name, initialValues.comuna ?? '');
        } else {
            setFieldValue(props.name, '');
        }

        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [region, touched.region, setFieldValue, props.name]);

    useEffect(() => {

        loadcomunas(data?.data ?? []);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <Fragment>
            <Field {...props} {...field} />
        </Fragment>
    );
};


FieldComuna.propTypes = {
    loadcomunas: PropTypes.func.isRequired,
}

FieldComuna.defaultProps = {
}

export default FieldComuna