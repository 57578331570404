import axios from "axios";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router"
import { userLogout } from "../redux/features/userActiveSlice";

const Wrapper = () => {
    const dispatch = useDispatch();
    if (typeof window !== 'undefined') {
        const userDB = localStorage.getItem('userDB') ? true : false;
        const isLogin = localStorage.getItem('userInfo') ? true : false;
       

        if ((!userDB && isLogin)) {
            const redirect_url_cu = encodeURIComponent(process.env.REACT_APP_FRONTEND_URL);
            const url_cu_logout = process.env.REACT_APP_URL_LOGOUT + "?redirect=" + redirect_url_cu;
            axios.get(url_cu_logout).then(() => { }).catch((error) => console.log(error));
            dispatch(userLogout())
        }
    }
    return <Outlet />
}

export default Wrapper;