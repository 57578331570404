import { Link } from "react-router-dom";
import { getDateFormatNoticia } from "../../constants/utiles/DateFunctions";

const NoticiaPrincipal = ({ imagen, descripcion, titulo, fechaTiempo, tiempoLectura, categoria, idNoticia }) => {


    return (

        <div className="not-dest animation_input">
            <div className="container">
                <div className="row">

                    <div className="col-md-6">
                        <Link to={`/noticia/${idNoticia}`} >
                            <img className="not-dest-img" src={imagen} alt="" />
                        </Link >
                    </div>

                    <div className="col-md-6">
                        <Link to={`/noticia/${idNoticia}`} className="text-decoration-none" >
                            <span className="t-dest">{titulo}</span>
                        </Link >
                        <p className="p-dest">{descripcion}</p>
                        <div className="info-dest">
                            <span>{getDateFormatNoticia(fechaTiempo)} / {tiempoLectura} min de lectura <span className="rounded">{categoria}</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoticiaPrincipal;