import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types';

const CarteleraBuscador = (props) => {

    //TODO: implementar funcionalidad de filtro
    return (
        <div className="col-md-2">
            <div className="ef rounded">
                <select id="disabledSelect" className="form-select form-select-sm" onChange={e => props.setRegion(e.target.value)}>
                    <option value={''}>Región</option>
                </select>

                <select id="disabledSelect" className="form-select form-select-sm" onChange={e => props.setComuna(e.target.value)}>
                    <option value={''}>Comuna</option>
                </select>

                <select id="disabledSelect" className="form-select form-select-sm" onChange={e => props.setDisciplina(e.target.value)}>
                    <option value={''}>Disciplina</option>
                </select>

                <select id="disabledSelect" className="form-select form-select-sm" onChange={e => props.setActividad(e.target.value)}>
                    <option value={''}>Actividad</option>
                </select>
            </div>

            <div className="d-grid gap-2">
                <button className="btn btn-primary mt-2" type="button"><FontAwesomeIcon icon={faMagnifyingGlass} /> Buscar</button>
            </div>
        </div>
    );
}

export default CarteleraBuscador;

CarteleraBuscador.propTypes = {
    setRegion: PropTypes.func.isRequired,
    setComuna: PropTypes.func.isRequired,
    setDisciplina: PropTypes.func.isRequired,
    setActividad: PropTypes.func.isRequired,

}

CarteleraBuscador.defaultProps = {
}