import { ErrorMessage, Field } from "formik";
import ValidationError from "../../utiles/ValidationError";

const MediadorOferta = (props) => {
    return (<div className="card">
        <div className="card-header">
            <strong>Detalle Información</strong>
        </div>
        <div className="card-body">
            <div className="row g-3">
                <div className="col-md-12">
                    <label htmlFor="mediador" className="form-label">¿Cuenta con actividad de mediación? <span className="rojo">*</span></label>
                    <Field name="mediador" as="select" className="form-control" >
                        <option value=''>Seleccionar</option>
                        <option value={'1'}>Si</option>
                        <option value={'0'}>No</option>
                    </Field>
                    <ErrorMessage name="mediador" component={ValidationError} />
                </div>

            </div>
        </div>
    </div>)

}

export default MediadorOferta;