import { Fragment, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosApi from "../api/axios/axiosApi";
import { BUSQUEDA_CATEGORIA_URI, BUSQUEDA_GENERAL_URI } from "../api/axios/constants";
import FiltroForm from "../components/busquedaAssets/FiltroForm";
import ResultadoItem from "../components/busquedaAssets/ResultadoItem";
import SelectOrden from "../components/busquedaAssets/SelectOrden";
import Paginacion from "../components/utiles/Paginacion";
import Titulo from "../components/utiles/Titulo";
import { setTitle } from "../constants/utiles/Functions";
import PropTypes from 'prop-types'
import axios from "axios";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";

const ResultadosBusqueda = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [resultadoBusqueda, setResultadoBusqueda] = useState([]);
    const [order, setOrder] = useState('desc');
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState([]);
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setTitle(props.title)
        setOrder('desc');
        const cancelToken = axios.CancelToken.source();
        setLoading(true);
        axiosApi.get(BUSQUEDA_GENERAL_URI, { cancelToken: cancelToken.token, params: handleInitialParams() })
            .then(response => {
                setResultadoBusqueda(response.data.data ?response.data.data.sort((a, b) => (a.idOferta < b.idOferta) ? 1 : (a.idOferta > b.idOferta) ? -1 : 0) : []);
                setFiltros(handleFilterTags(response.data));
                setCurrentPage(1);
                
                setLoading(false);
            }).catch((error) => {
                if (axios.isCancel(error)) {
                    console.log("cancelado");
                } else {
                    console.error(error)


                }
            });


        if (searchParams.get('modo_categoria') == "true") {
            axiosApi.get(BUSQUEDA_CATEGORIA_URI(searchParams.get('categoria')), { cancelToken: cancelToken.token })
                .then(response => {
                    const result = response.data.data ? response.data.data.sort((a, b) => (a.idOferta < b.idOferta) ? 1 : (a.idOferta > b.idOferta) ? -1 : 0) : []
                    setResultadoBusqueda(result);
                    setFiltros(handleFilterTags(response.data));
                    setCurrentPage(1);
                    setLoading(false);
                }).catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log("cancelado");
                    } else {
                        console.error(error)
                            ;
                        dispatch(setLoading(false));
                    }
                });
        }
        return () => {
            cancelToken.cancel()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);



    useEffect(() => {
        if (resultadoBusqueda) {
            let orderResult;
            if (order === "desc") {
                orderResult = resultadoBusqueda.sort((a, b) => (a.idOferta < b.idOferta) ? 1 : (a.idOferta > b.idOferta) ? -1 : 0);
            } else if (order === "asc") {
                orderResult = resultadoBusqueda.sort((a, b) => (a.idOferta > b.idOferta) ? 1 : (a.idOferta < b.idOferta) ? -1 : 0);
            }
            setResultadoBusqueda([...orderResult]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);



    const handleFilterTags = (data) => {
        let filters = [];
        if (data.idCategoria) {
            filters.push({ id: data.idCategoria, nombre: data.categoria, input: "categoria" });
        }
        if (data.idRegion) {
            filters.push({ id: data.idRegion, nombre: data.region, input: "region" });
        }
        if (data.idComuna) {
            filters.push({ id: data.idComuna, nombre: data.comuna, input: "comuna" });
        }
        if (data.idTipoPerfil) {
            filters.push({ id: data.idtipoPerfil, nombre: data.tipo_perfil, input: "tipoPerfil" });
        }
        if (data.idRango) {
            filters.push({ id: data.idRango, nombre: data.rangoPrecio, input: "rangoPrecio" });
        }

        return filters;
    }

    const handleInitialParams = () => {
        return {
            texto_buscar: searchParams.get('palabra') ? decodeURIComponent(searchParams.get('palabra')) : null,
            id_tipo_perfil: searchParams.get('agente'),
            id_categoria: searchParams.get('categoria'),
            id_region: searchParams.get('id_region'),
            id_comuna: searchParams.get('comuna'),
            id_rango_precio: searchParams.get('rango_precio'),
        }

    }

    const handlePageClick = (data) => {
        const page = data.selected + 1;

        setCurrentPage(page);
    }



    return (
        <Fragment>
            <Titulo>
                <div className="breadcrumbs">
                    <ul>
                        <li><Link to="/">Inicio</Link></li>
                        <li><Link className="bc" style={{ borderRight: 'solid 0px #ccc !important' }} to="/busqueda">Resultados de búsqueda</Link></li>
                    </ul>
                </div>
                <div className="clear"></div>
                <span className="t1b">Resultados de búsqueda de Ofertas</span>

            </Titulo>


            <div className="gris2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <FiltroForm
                                comuna={searchParams.get('comuna')}
                                region={searchParams.get('id_region')}
                                categoria={searchParams.get('categoria')}
                                palabra={searchParams.get('palabra') ? decodeURIComponent(searchParams.get('palabra')) : null}
                                agente={searchParams.get('agente')}
                                rangoPrecio={searchParams.get('rango_precio')}
                                setOrder={setOrder}
                                filtros={filtros}
                                loading={loading}
                            />
                        </div>
                        <div className="col-md-9">
                            <div>
                                <div className="rb">
                                    <div className="rbl">
                                        <span>Resultados de busqueda</span>
                                        <p>{resultadoBusqueda.length} resultados</p>
                                    </div>
                                    <SelectOrden handleOrdering={setOrder} order={order} />
                                </div>
                                <div className="clear"></div>
                                <div>
                                    {loading ? <div className="mt-4">
                                        <div style={{ textAlign: "center" }} >
                                            <Spinner ></Spinner>
                                        </div>
                                    </div> : resultadoBusqueda ? <div className="row mt-4">
                                        {resultadoBusqueda.slice((currentPage - 1) * 9, currentPage * 9).map((oferta, index) => <ResultadoItem
                                            key={index}
                                            id={oferta.idOferta}
                                            img={oferta.imagen}
                                            nombre={oferta.nombreOferta}
                                            agente={oferta.tipoPerfil}
                                            categoria={oferta.categoria}
                                            region={oferta.region}

                                        />)}
                                        {
                                            resultadoBusqueda.length > 0 ?
                                                <Paginacion
                                                    pageCount={Math.ceil(resultadoBusqueda.length / 9)}
                                                    currentpage={currentPage}
                                                    handlePageClick={handlePageClick}
                                                /> : ""
                                        }
                                    </div>
                                        : ""}

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </Fragment>
    );
}

export default ResultadosBusqueda;


ResultadosBusqueda.propTypes = {
    title: PropTypes.string.isRequired,
    palabra_clave: PropTypes.string,
}

ResultadosBusqueda.defaultProps = {
}
