import { Fragment, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axiosApi from "../api/axios/axiosApi";
import { BUSQUEDA_CONVO_URI, CATEGORIAS_GET_URI, CONVOCATORIAS_URI } from "../api/axios/constants";

import SelectOrden from "../components/busquedaAssets/SelectOrden";
import FondoConvoItem from "../components/fondoConvocatoriaAssets/FondoConvoItem";
import Paginacion from "../components/utiles/Paginacion";
import Titulo from "../components/utiles/Titulo";
import { setTitle } from "../constants/utiles/Functions";
import PropTypes from 'prop-types';
import axios from "axios";
import FiltroForm from "../components/fondoConvocatoriaAssets/FiltroForm";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";

const Convocatorias = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [resultadoBusqueda, setResultadoBusqueda] = useState([]);
    const [order, setOrder] = useState('desc');
    const [currentPage, setCurrentPage] = useState(1);
    const [filtros, setFiltros] = useState([]);
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTitle(props.title)
        setOrder('desc');
        const cancelToken = axios.CancelToken.source();
        setLoading(true);
        axiosApi.get(BUSQUEDA_CONVO_URI, { cancelToken: cancelToken.token, params: handleInitialParams() })
            .then(response => {
                const result = response.data.data ? response.data.data.sort((a, b) => (a.idConvocatoria < b.idConvocatoria) ? 1 : (a.idConvocatoria > b.idConvocatoria) ? -1 : 0) : [];
                setResultadoBusqueda(result);
                setFiltros(handleFilterTags(response.data));
                setCurrentPage(1);
                setLoading(false);
            }).catch((error) => {
                if (axios.isCancel(error)) {
                    console.log("cancelado");
                } else {
                    console.error(error)

                    dispatch(setLoading(false));
                }
            });

        return () => cancelToken.cancel();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    useEffect(() => {
        if (resultadoBusqueda) {
            let orderResult;
            if (order === "desc") {
                orderResult = resultadoBusqueda.sort((a, b) => (a.idConvocatoria < b.idConvocatoria) ? 1 : (a.idConvocatoria > b.idConvocatoria) ? -1 : 0);
            } else if (order === "asc") {
                orderResult = resultadoBusqueda.sort((a, b) => (a.idConvocatoria > b.idConvocatoria) ? 1 : (a.idConvocatoria < b.idConvocatoria) ? -1 : 0);
            }
            setResultadoBusqueda([...orderResult]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);



    const handleFilterTags = (data) => {
        let filters = [];
        if (data.idCategoria) {
            filters.push({ id: data.idCategoria, nombre: data.categoria, input: "categoria" });
        }

        return filters;
    }

    const handleInitialParams = () => {
        return {
            texto_buscar: searchParams.get('palabra') ? decodeURIComponent(searchParams.get('palabra')) : null,
            id_categoria: searchParams.get('categoria'),
        }

    }


    const handlePageClick = (data) => {
        const page = data.selected + 1;

        setCurrentPage(page);
    }



    return (
        <Fragment>
            <Titulo>
                <div className="breadcrumbs">
                    <ul>
                        <li><Link to="/">Inicio</Link></li>
                        <li><Link className="bc" style={{ borderRight: 'solid 0px #ccc !important' }} to="/fondos-pasantias">Convocatorias</Link></li>
                    </ul>
                </div>

                <div className="clear"></div>
                <span className="t1b">Convocatorias</span>

            </Titulo>



            <div className="gris2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <FiltroForm
                                categoria={searchParams.get('categoria')}
                                palabra={searchParams.get('palabra') ? decodeURIComponent(searchParams.get('palabra')) : null}
                                setOrder={setOrder}
                                filtros={filtros}
                                loading={loading} />
                        </div>
                        <div className="col-md-9">
                            <div className="rb">
                                <div className="rbl">
                                    <span>Resultados de busqueda</span>
                                    <p>{resultadoBusqueda.length} resultados</p>
                                </div>
                                <SelectOrden handleOrdering={setOrder} order={order} />
                            </div>
                            <div className="clear"></div>

                            {loading ? <div className="mt-4">
                                <div style={{ textAlign: "center" }} >
                                    <Spinner ></Spinner>
                                </div>
                            </div> :
                                <div>
                                    <div className="row mt-4">


                                        {resultadoBusqueda.slice((currentPage - 1) * 9, currentPage * 9).map((oferta, index) => <FondoConvoItem
                                            key={index}
                                            id={oferta.idConvocatoria}
                                            nombre={oferta.convocatoriaNombre}
                                            descripcion={oferta.descripcionCorta}
                                            fechaInicio={oferta.fechaInicio}
                                            fechaFin={oferta.fechaFin}
                                            categoria={oferta.categoria}

                                        />)

                                        }
                                    </div>

                                    <Paginacion
                                        pageCount={Math.ceil(resultadoBusqueda.length / 9)}
                                        currentpage={currentPage}
                                        handlePageClick={handlePageClick}
                                    />
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>



    );
}

export default Convocatorias;

Convocatorias.propTypes = {
    title: PropTypes.string,
}

Convocatorias.defaultProps = {
}