import axios from "axios";


export const sha256 = () => {

    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode();

    // hash the message
    const hashBuffer = crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string                  
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

export const setTitle = (newTitle) => {
    if (typeof newTitle !== 'undefined' && newTitle !== '') {
        newTitle = ` - ${newTitle}`;
    } else {
        newTitle = '';
    }
    document.title = `Catálogo Digital ${newTitle}`;
}



export const validadorEmail = (email) => {
    const validRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

    if (email.match(validRegex)) {
        return true;
    }
    return false;
}

export const regexTelefono = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);


export const handleInputRut = (evt) => {
    (!["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-", ".", "k", "K"].includes(evt.key) && ![8, 9, 13, 37, 38, 39, 40].includes(evt.keyCode)) && evt.preventDefault()
};


export const regexRut = new RegExp(/^\d{1,2}\.?\d{3}\.?\d{3}[-]?[0-9kK]{1}$/g);

export const regexURL = new RegExp(/^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?$/gi);

export const download = (url, name) => {
    if (!url) {
        throw new Error("Resource URL not provided! You need to provide one");
    }

    axios.get(url)
        .then(response => {
            const blobURL = URL.createObjectURL(response.blob());
            const a = document.createElement("a");
            a.href = blobURL;
            a.style = "display: none";

            if (name && name.length) { a.download = name; }
            document.body.appendChild(a);
            a.click();
        })
        .catch((error) => console.log(error));
};


export const getBase64 = file => {
    return new Promise(resolve => {
        let baseURL = "";
        // Make new FileReader
        const reader = new FileReader();

        // Convert the file to base64 text
        reader.readAsDataURL(file);

        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};



export const checkIfFileIsTooBig = (file) => {
    let valid = true
    if (file) {
        const size = file.size / 1024 / 1024
        if (size > 5) {
            valid = false
        }
    }
    return valid
}

export const validateImageType = (file) => {
    return checkIfFileIsCorrectType(file, ['image/jpeg', 'image/pjpeg', 'image/gif', 'image/png']);
}

export const validateDocType = (file) => {
    return checkIfFileIsCorrectType(file, ["application/pdf", "application/x-pdf"]);
}


const checkIfFileIsCorrectType = (file, types) => {
    let valid = true
    if (file && !types.includes(file.type)) {
        valid = false
    }
    return valid
}



export const forEachValues = async (iterable) => {
    for (const x of iterable) {
        await x();
    }
}

export const truncateString = (str, num) => {
    if (str && str.length > num) {
        return str.slice(0, num) + "...";
    } else {
        return str;
    }
}


// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
const dec2hex = (dec) => {
    return dec.toString(16).padStart(2, "0")
}

// generateId :: Integer -> String
export const generateId = (len) => {
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
}