import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

const FullPageLoader = () => {
    const { loading } = useSelector((state) => state.PrincipalSlice)

    if (!loading) {
        document.body.style.overflow = "visible"

        return null};

    document.body.style.overflow = "hidden"

    return (
        <div className="loader-container">
            <Spinner  animation="border" role="status" variant="primary" className="loader" style={{width:"5rem", height:"5rem"}}>
            <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}


export default FullPageLoader;