import { faFileLines, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Accordion, Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import axiosApi from "../api/axios/axiosApi";
import { CONVOCATORIA_URI } from "../api/axios/constants";
import SesionModal from "../components/convocatoriaAssets/SesionModal";
import Map from "../components/utiles/Map";
import Titulo from "../components/utiles/Titulo";
import { getDateFormatNoticia } from "../constants/utiles/DateFunctions";
import { setTitle, truncateString } from "../constants/utiles/Functions";
import { useAddConvoGuardadasMutation, useGetConvoGuardadasQuery } from "../redux/features/ApiSlice";


const Convocatoria = () => {

    const { idDirectorio } = useSelector((state) => state.userActive);
    const { id_convocatoria } = useParams();

    let skip = false;
    if (!idDirectorio) {
        skip = true;
    }


    const { data = [] } = useGetConvoGuardadasQuery(idDirectorio, { skip });
    const [addConvoGuardadas] = useAddConvoGuardadasMutation();
    const [loading, setLoading] = useState(true);
    const [convocatoria, setConvocatoria] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setTitle()
        const cancelToken = axios.CancelToken.source();
        setLoading(true)
        axiosApi.get(CONVOCATORIA_URI(id_convocatoria), { cancelToken: cancelToken.token }).then(response => {
            setConvocatoria(response?.data?.data[0])
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log(error);
            } else {
                console.error(error);
                setLoading(false)
            }
        });
        return () => {
            cancelToken.cancel();
        }
    }, [id_convocatoria])


    const handleGuardarConvo = () => {

        if (idDirectorio) {
            const toastId = toast.loading('Cargando...');
            addConvoGuardadas({
                id_convocatoria: id_convocatoria,
                id_directorio: idDirectorio
            }).unwrap().then((response) => {
                toast.success(response.message, {
                    id: toastId,
                })
            }).catch(error => {
                toast.error(error.message, {
                    id: toastId,
                })
            });
        } else {
            setOpen(true)
        }


    }

    const fecha_inicio = dayjs(convocatoria.fechaInicio, 'DD/MM/YYYY').locale("es");
    const fecha_fin = dayjs(convocatoria.fechaFin, 'DD/MM/YYYY').locale("es");
    const fecha_format = getDateFormatNoticia(convocatoria.fechaInicio);

    return (<div>
        <Titulo>
            <div className="breadcrumbs">
                <ul>
                    <li><Link to="/">Inicio</Link></li>
                    <li><Link to="/convocatorias">Convocatorias</Link></li>
                    <li><Link className="bc" style={{ borderRight: 'solid 0px #ccc !important' }}>{truncateString(convocatoria.convocatoriaNombre, 50)}</Link></li>
                </ul>
            </div>
            <div className="clear"></div>
            <span className="t1b">{convocatoria.convocatoriaNombre}</span>
        </Titulo>
        <div className="gris2">
            <div className="container">

                {
                    loading ? <div className="mt-5 mb-5 ">
                        <div style={{ textAlign: "center" }} >
                            <Spinner ></Spinner>
                        </div>
                    </div> :
                        <div className="row">
                            <div className="col">
                                <div className="personal rounded">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <span className="fecha1 rounded-end">{fecha_format}</span>
                                            <div className="clear"></div>
                                            <div className="single-ref rounded">
                                                <div className="inicio rounded">
                                                    <span className="t">Fecha Inicio</span>
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-6" style={{ borderRight: "solid 1px #7561d8" }}>
                                                                <div>
                                                                    <span>{fecha_inicio.format('DD')}</span>
                                                                    {fecha_inicio.format('MMM')}
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div>
                                                                    <span>{convocatoria.HoraInicio}</span>
                                                                    Horas (AM)
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="fin rounded">
                                                    <span className="t">Fecha Fin</span>
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-6" style={{ borderRight: "solid 1px #daa726" }}>
                                                                <div>
                                                                    <span>{fecha_fin.format('DD')}</span>
                                                                    {fecha_fin.format('MMM')}
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div>
                                                                    <span>{convocatoria.HoraFin}</span>
                                                                    Horas (AM)
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    convocatoria.enlace && <div className="enlace rounded">
                                                        <span>Enlace</span>
                                                        <a style={{ textDecoration: "none", backgroundColor: "#2F80ED", textAlign: "left", paddingLeft: "0px" }} href={convocatoria.enlace} target='_blank'>Click aquí para ver</a>

                                                    </div>
                                                }


                                                {
                                                    data.find(element => element == id_convocatoria) ? <a className="rounded" >Convocatoria Guardada</a> : <a className="rounded" style={{ cursor: 'pointer' }} onClick={handleGuardarConvo}>Guardar convocatoria</a>
                                                }


                                            </div>
                                        </div>

                                        <div className="col-md-9">
                                            <Accordion defaultActiveKey={[1, 2]} alwaysOpen>


                                                <Accordion.Item eventKey={1} className="accordion">
                                                    <Accordion.Header>
                                                        <div className="rounded-circle icon-acc"><FontAwesomeIcon icon={faFileLines} />  </div><span className="acc-tit"> Descripción general</span>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="card">
                                                            <div className="card-header">
                                                                {convocatoria.categoria}
                                                            </div>
                                                            <div className="card-body">
                                                                <p className="card-text">{convocatoria.descripcion}</p>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey={2} className="accordion">
                                                    <Accordion.Header>
                                                        <div className="rounded-circle icon-acc"><FontAwesomeIcon icon={faLocationDot} /> </div> <span className="acc-tit"> Ubicación</span>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="card">
                                                            <div className="card-header">
                                                                {convocatoria.direccion}, {convocatoria.comuna}, {convocatoria.region}
                                                            </div>
                                                            <div className="card-body">
                                                                <Map direccion={`${convocatoria.direccion}, ${convocatoria.comuna}, ${convocatoria.region}`}></Map>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>




                                            <div className="clear"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }

            </div>
        </div>
        <SesionModal open={open} setOpen={setOpen} />
    </div>)
}

export default Convocatoria;