import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ConvocatoriaItem from "./ConvocatoriaItem";

import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import axios from "axios";
import axiosApi from "../../api/axios/axiosApi";
import { CONVOCATORIAS_URI } from "../../api/axios/constants";
import { ESTADO_ABIERTO_STRING } from "../../constants/Constants";
import { Spinner } from "react-bootstrap";

const Convocatorias = () => {
    const [arrayConvo, setArrayConvo] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        axiosApi.get(CONVOCATORIAS_URI, { cancelToken: cancelToken.token }).then(response => {
            setArrayConvo(response?.data?.data)
            setLoading(false)
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log(error);
            } else {
                console.error(error);
                setLoading(false);
            }

        });
        return () => {
            cancelToken.cancel();
        }
    }, [])

    return (
        <div className="blanco blanco-fondo">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <span className="t1b">Próximas convocatorias</span>
                        <p className="p1b">No te pierdas de participar en convocatorias vinculadas a la cultura y las artes</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <Link to="/convocatorias" className="masofertas rounded">Más convocatorias <FontAwesomeIcon style={{ marginLeft: '10px' }} icon={faArrowRight} ></FontAwesomeIcon></Link>
                    </div>
                </div>


                <div className="row">

                    {loading ? <div className="mt-5 mb-5 ">
                        <div style={{ textAlign: "center" }} >
                            <Spinner ></Spinner>
                        </div>
                    </div> :
                        arrayConvo.slice(0, 3).map((value, index) => <ConvocatoriaItem
                            key={index}
                            id={value.idConvocatoria}
                            nombre={value.convocatoriaNombre}
                            categoria={value.categoria}
                            fechaInicio={value.fechaInicio}
                            horaInicio={value.HoraInicio}
                            fechaPlazo={value.fechaFin}
                            horaPlazo={value.HoraFin}
                            estado={ESTADO_ABIERTO_STRING}
                        />
                        )
                    }

                </div>


            </div>
        </div>

    );
}

export default Convocatorias;

Convocatorias.propTypes = {
    convocatorias: PropTypes.array.isRequired,
}

Convocatorias.defaultProps = {
    convocatorias: [],
}