import axiosApi from "../api/axios/axiosApi";

const axiosBaseQuery =
    ({ baseUrl } = { baseUrl: '' }) =>
        async ({ url, method, data, params }) => {
            try {
                const result = await axiosApi({ url: baseUrl + url, method, data, params })

                if(result.data.error === true ){
                    throw result;
                }
                return { data: result.data }
            } catch (axiosError) {
                const err = axiosError
                return {
                    error: {
                        status: err.response?.status || err.data?.code,
                        data: err.response?.data || err.message || err.data?.message,
                    },
                }
            }
        }

export default axiosBaseQuery;