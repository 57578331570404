import Footer from './components/Footer';
import Header from './components/Header';
import { Fragment, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Menu from './components/Menu';
import SiteRoutes from './routes/SiteRoutes';
import './App.css';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import fetchInitialData from './redux/fetchInitialData';
import FullPageLoader from './components/utiles/FullPageLoader';

import IdleTimerComponent from './components/utiles/IdleTimer';

// Default theme
import '@splidejs/react-splide/css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




const App = () => {

  

  const { isLogin } = useSelector((state) => state.userActive)
  const dispatch = useDispatch()
  useEffect(() => {
    fetchInitialData(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);





  return (
    <Fragment>
      {isLogin ? <IdleTimerComponent /> : ""}
      <Header />
      <Menu />
      <SiteRoutes />
      <Footer />
      <Toaster />
      {
        typeof window !== 'undefined' ? <FullPageLoader /> : ""
      }


    </Fragment>


  );
}

export default App;
