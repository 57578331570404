import { Accordion, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TAB_CONTACTO } from "../../constants/ConstantsTabsAyuda";
import { useGetFaqsQuery } from "../../redux/features/ApiSlice";
import FaqAdjuntos from "./FaqAdjuntos";

const Faq = () => {
    const {
        data: data,
        isLoading,
        isError
    } = useGetFaqsQuery()

    let content = ''

    if (isLoading) {
        content = <div className="mt-2 mb-4 ">
            <div style={{ textAlign: "center" }} >
                <Spinner ></Spinner>
            </div>
        </div>
    } else if (isError) {
        content = 'Ocurrió un error, favor contactar con administrador'
    } else {
        content = <Accordion defaultActiveKey={["0"]} alwaysOpen>
            {
                data.data.map((item, index) =>
                    <Accordion.Item key={index} eventKey={index} className='animation_input'>
                        <Accordion.Header >
                            <div className="fw-semibold">{item.titulo}</div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div dangerouslySetInnerHTML={{ __html: item.detalle }}></div>
                            <FaqAdjuntos arrayAdjuntos={item.adjuntos}></FaqAdjuntos>
                        </Accordion.Body>
                    </Accordion.Item>
                )
            }
        </Accordion>
    }





    return (
        <div className="row">
            <div className="form rounded mb-4">
                <span className="tform">Preguntas frecuentes</span>
                <span className="pform"></span>
                <div className="row justify-content-md-center mt-4">
                    <div className="col-md-10">
                        {content}
                    </div>
                </div>

                <div style={{ display: "block", marginTop: "60px" }}>
                    <span className="tform">Todavía tengo preguntas</span>
                    <p className="p-mens">Si no pudimos resolver tus dudas en esta sección, siempre puedes <Link to={`/ayuda/${TAB_CONTACTO}`}>Contactarte con nosotros</Link>. ¡Te responderemos a la brevedad!</p>
                </div>

            </div>
        </div>
    )
}

export default Faq;