import axios from 'axios';

import {
  BASE_URI,
  APP_URL
} from './constants';

let axiosApi = axios.create({
  headers: {
    common: {}
  }
});

axiosApi.defaults.baseURL = `${APP_URL}/${BASE_URI}`;

axiosApi.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.REACT_APP_TOKEN;


axiosApi.interceptors.response.use(response => {
  return response;
}, error => {
  throw error;
});

export default axiosApi;