import { ErrorMessage, Field } from "formik";
import ValidationError from "../../utiles/ValidationError";

const DetallesOferta = (props) => {
    return (<div className="card">
        <div className="card-header">
            <strong>Detalle Información</strong>
        </div>
        <div className="card-body">
            <div className="col-md-12">
                <label htmlFor="nombreOferta" className="col-sm-5 col-form-label">Nombre oferta<span className="rojo">*</span></label>
                <Field type="text" className='form-control' id="nombreOferta" name='nombreOferta' />
                <ErrorMessage name="nombreOferta" component={ValidationError} />
            </div>



            <div className="col-12">
                <label htmlFor="descripcion" className="form-label">Descripción</label>
                <Field as="textarea" className='form-control' id="descripcion" name="descripcion" />
                <ErrorMessage name="descripcion" component={ValidationError} />
            </div>
        </div>
    </div>)

}

export default DetallesOferta;