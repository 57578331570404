import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { getYearsSinceStartYear } from '../../constants/utiles/DateFunctions';
import { Spinner } from 'react-bootstrap';


const FiltroForm = (params) => {
    const [anio, setAnio] = useState(params.anio || '');
    const [palabra, setPalabra] = useState(params.palabra || '');
    const [reload, setReload] = useState(false);

    const navigate = useNavigate();
    const years = getYearsSinceStartYear(process.env.REACT_APP_FILTER_YEAR || 2023);

    useEffect(() => { if (reload === true) { handleSubmit(); setReload(false); } }, [reload])


    const handleLimpiar = () => {
        setAnio('');
        setPalabra('');

        setReload(true);
    }

    const handleSubmit = () => {
        let data = {}
        if (anio) {
            data.anio = anio
        }
        if (palabra) {
            data.palabra = encodeURIComponent(palabra)
        }
        params.setOrder('desc');
        navigate({
            pathname: "/recursos",
            search: createSearchParams(data).toString()
        });
    }

    const handleTags = () => {

        return (<ul>
            {
                params.filtros ? params.filtros.map((values, index) => {
                    return <li key={index} className="rounded">{values.nombre} <a onClick={() => removeTag(values.input)} style={{ cursor: 'pointer' }}>x</a></li>
                }) : ""
            }
        </ul>)
    }

    const removeTag = (input) => {
        switch (input) {
            case "anio":
                setAnio('');
                break;
            default:
                break;
        }

        setReload(true);

    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className="filtros rounded">
            <div className="ft">
                <span><i className="fa-solid fa-filter"></i> Filtros</span>
            </div>

            <div className='animation_input'>
                <div className="loading_class" style={{ display: params.loading ? "" : "none" }}>
                    <div style={{ position: "relative", top: "50%" }}>
                        <div style={{ textAlign: "center" }} >
                            <Spinner ></Spinner>
                        </div>
                    </div>
                </div>
                <div className="ft2">
                    <p>Palabra clave</p>
                    <input type="text" className="form-control" name="palabra" id="palabra" placeholder="Texto a buscar" value={palabra} onChange={e => setPalabra(e.target.value)} onKeyDown={handleKeyDown} />
                </div>
                <div className="ft2">
                    <span onClick={handleSubmit} style={{ cursor: 'pointer' }} >Aplicar filtros</span>
                    <a className="ft2l pe-auto" style={{ cursor: 'pointer' }} onClick={handleLimpiar} >Limpiar todo</a>
                    <div className="clear"></div>
                    {
                        handleTags()
                    }

                    <div className="clear"></div>
                </div>
                <div className="ft3">
                    <div className="col-auto">
                        <select className="form-select" aria-label="Default select example" value={anio} onChange={e => { setAnio(e.target.value); }}>
                            <option value={""}>Año</option>
                            {years.map((value, index) => <option value={value} key={index}>{value}</option>)}
                        </select>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default FiltroForm;

FiltroForm.propTypes = {
    arrayCategorias: PropTypes.array,
    loading: PropTypes.bool
}

FiltroForm.defaultProps = {
    arrayCategorias: [],
    loading: false
}