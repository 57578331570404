



import PropTypes from 'prop-types';
import DirectorioItem from "./DirectorioItem";
import { Spinner } from "react-bootstrap";
import Slider from "react-slick";


const Directorio = ({ classDiv, children, backgroundStyle, backgroundImg, listado }) => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className={classDiv}>
            <div className="container">
                {children}

                <div className="row">

                    <Slider {...settings}>
                        {
                            listado.length === 0 ? <div className="mt-4 mb-5 ">
                                <div style={{ textAlign: "center" }} >
                                    <Spinner variant="light" ></Spinner>
                                </div>
                            </div> :
                                listado.slice(0, 8).map((value, index) => {
                                    return (<DirectorioItem
                                        key={index}
                                        id_directorio={value.idDirectorio}
                                        nombre={value.nombreCompleto}
                                        agente={value.tipoUsuario}
                                        img={value.imagen_perfil}
                                        
                                    />
                                    )
                                })
                        }


                    </Slider>
                </div>
            </div>
            {
                (backgroundImg != null) ? <img style={backgroundStyle} src={backgroundImg} alt="" /> : ""
            }
        </div>
    );
}
export default Directorio;

Directorio.propTypes = {
    classDiv: PropTypes.string.isRequired,
    backgroundStyle: PropTypes.object,
    backgroundImg: PropTypes.string,
    listado: PropTypes.array,
}

Directorio.defaultProps = {
    classDiv: 'blanco',
    listado: []
}