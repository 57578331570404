import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";

import { checkIfFileIsTooBig, forEachValues, getBase64, validateDocType } from "../../constants/utiles/Functions";

import * as Yup from 'yup';
import { toast } from "react-hot-toast";
import ValidationError from "../utiles/ValidationError";

import { Spinner } from "react-bootstrap";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GET_DETALLE_ESPACIO_URI, POST_DETALLE_ESPACIO_URI, PUT_DETALLE_ESPACIO_URI } from "../../api/axios/constants";
import axiosApi from "../../api/axios/axiosApi";
import { TAB_MI_PERFIL } from "../../constants/ConstantsTabsPrivado";
import { useNavigate } from "react-router";

const EspacioCulturalEdit = ({ user }) => {
    const [fileFicha, setFileFicha] = useState(null);
    const [fileDossier, setFileDossier] = useState(null);
    const [userLoad, setUserLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [detalle, setDetalle] = useState({});
    const [isUpdate, setIsUpdate] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (user.idDirectorio) {
            axiosApi.get(GET_DETALLE_ESPACIO_URI(user.idDirectorio))
                .then(response => {

                    if (response.data.error === false) {
                        setDetalle(response.data.data[0])
                        setFileFicha(response.data.data[0].ficha || null)
                        setFileDossier(response.data.data[0].dossier || null)
                        setIsUpdate(true);
                    }
                    setLoading(false);
                    setUserLoad(false);
                }).catch(error => {

                    setLoading(false)
                })
        }
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);



    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const initialValues = {
        nombre: detalle.nombreEspacioCultural || "",
        ficha: null,
        dossier: null,
        web: detalle.web,
        facebook: detalle.facebook,
        instagram: detalle.instagram,
        otra_rrss: detalle.otra_rrss,
    };


    const validationSchema = Yup.object({
        nombre: Yup.string().required('Campo requerido').max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres'),
        ficha: Yup.mixed()
            .nullable()
            .test('ficha-tamanio', 'El archivo no puede sobrepasar los 5 mb', checkIfFileIsTooBig)
            .test(
                'ficha-formato',
                'Solo se aceptan los siguientes formatos de archivo: .pdf',
                validateDocType
            ),
        dossier: Yup.mixed()
            .nullable()
            .test('dossier-tamanio', 'El archivo no puede sobrepasar los 5 mb', checkIfFileIsTooBig)
            .test(
                'dossier-formato',
                'Solo se aceptan los siguientes formatos de archivo: .pdf',
                validateDocType
            ),
        web: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        facebook: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 200 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        instagram: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
        otra_rrss: Yup.string().max(100, 'El texto ingresado no debe sobrepasar los 100 caracteres').url('Formato incorrecto de URL (Debe iniciar con http:// o https://)').nullable(),
    })

    const handleOnSubmit = (values) => {
        setLoading(true);
        const toastId = toast.loading('Cargando...');
        const data = {
            nombre_espacio_cultural: values.nombre,
            web: values.web || null,
            facebook: values.facebook || null,
            instagram: values.instagram || null,
            otra_rrss: values.otra_rrss || null
        }
        let arrayFiles = [];

        if (values.ficha) {
            const ficha_func = () => getBase64(values.ficha)
                .then(result => {
                    const base64result = result.split(',')[1];
                    data['ficha_name'] = values.ficha.name;
                    data['ficha_data'] = base64result;
                    data['ficha_mime_type'] = values.ficha.type;

                })
                .catch(err => {
                    console.error(err);
                });


            arrayFiles.push(ficha_func);
        }

        if (values.dossier) {
            const dossier_func = () => getBase64(values.dossier)
                .then(result => {
                    const base64result = result.split(',')[1];
                    data['dossier_name'] = values.dossier.name;
                    data['dossier_data'] = base64result;
                    data['dossier_mime_type'] = values.dossier.type;
                })
                .catch(err => {
                    console.error(err);
                });
            arrayFiles.push(dossier_func);
        }



        forEachValues(arrayFiles)
            .then(() => {
                let query;
                if (isUpdate) {
                    query = axiosApi.put(PUT_DETALLE_ESPACIO_URI(detalle.idDetalleEc), data)
                } else {
                    query = axiosApi.post(POST_DETALLE_ESPACIO_URI(user.idDirectorio), data)
                }

                query.then(response => {
                    if (response.data.error === false) {
                        toast.success(response.data.message, {
                            id: toastId,
                        });
                        navigate('/home-privado/' + TAB_MI_PERFIL);
                    } else {
                        toast.error('Ocurrio un error al guardar los datos del espacio cultural, intente nuevamente', {
                            id: toastId,
                        });
                    }

                    setLoading(false)
                }).catch(error => {
                    toast.error('Ocurrio un error al guardar los datos del espacio cultural, intente nuevamente', {
                        id: toastId,
                    });
                    setLoading(false)
                })

            })


    }




    return (<div className="col-md-9">

        <div className="rb">
            <div className="rbl mb-4">
                <span>Datos espacio cultural </span>
            </div>
        </div>

        <div className="clear"></div>

        {
            userLoad ? <div className="mt-1 mb-5 ">
                <div style={{ textAlign: "center" }} >
                    <Spinner ></Spinner>
                </div>
            </div> :

                <div className="animation_input">
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleOnSubmit}
                        validationSchema={validationSchema}

                    >
                        {props => (
                            <Form className="">
                                <div>
                                    <div className="row mb-2">
                                        <label htmlFor="nombre" className="col-sm-5 col-form-label">Nombre del espacio de uso cultural al que se vincula <span className="rojo">*</span></label>
                                        <div className="col-sm-7">
                                            <Field type="text" className='form-control' id="nombre" name='nombre' />
                                        </div>
                                        <ErrorMessage name="nombre" component={ValidationError} />
                                    </div>

                                    <div className="row mb-2">
                                        <label htmlFor="web" className="col-sm-5 col-form-label" >Página Web </label>
                                        <div className="col-sm-7">
                                            <Field type="text" className='form-control' id="web" name="web" placeholder="https://" />
                                        </div>
                                        <ErrorMessage name="web" component={ValidationError} />
                                    </div>

                                    <div className="row mb-2">
                                        <label htmlFor="facebook" className="col-sm-5 col-form-label" >Perfil de Facebook </label>
                                        <div className="col-sm-7">
                                            <Field type="text" className='form-control' id="facebook" name="facebook" placeholder="https://www.facebook.com/" />
                                        </div>
                                        <ErrorMessage name="facebook" component={ValidationError} />
                                    </div>

                                    <div className="row mb-2">
                                        <label htmlFor="instagram" className="col-sm-5 col-form-label" >Perfil de instagram</label>
                                        <div className="col-sm-7">
                                            <Field type="text" className='form-control' id="instagram" name="instagram" placeholder="https://www.instagram.com/" />
                                        </div>
                                        <ErrorMessage name="instagram" component={ValidationError} />
                                    </div>

                                    <div className="row mb-2">
                                        <label htmlFor="otra_rrss" className="col-sm-5 col-form-label" >Otra RRSS </label>
                                        <div className="col-sm-7">
                                            <Field type="text" className='form-control' id="otra_rrss" name="otra_rrss" placeholder="https://" />
                                        </div>
                                        <ErrorMessage name="otra_rrss" component={ValidationError} />
                                    </div>

                                    <div className="row mb-2">
                                        <label htmlFor="ficha" className="col-sm-5 col-form-label">Subir Ficha técnica</label>
                                        <div className="col-sm-7">
                                            <input id="ficha" name="ficha" type="file" className={`form-control  ${props.errors.ficha ? "is-invalid" : ""}`} onChange={(event) => {
                                                props.touched.ficha = true;
                                                props.setFieldValue("ficha", event.currentTarget.files[0]);
                                                setFileFicha(URL.createObjectURL(event.target.files[0]));
                                            }} accept="application/pdf, application/x-pdf" />
                                            <ErrorMessage name="ficha" component={ValidationError} />
                                            {
                                                (props.errors.ficha && props.touched.ficha) || !fileFicha ? "" :
                                                    <div className="pt-2 mx-auto" >
                                                        <a href={fileFicha} target={'_blank'}> <FontAwesomeIcon className={'mx-1'} icon={faFile} /> Documento Ficha técnica </a>
                                                    </div>

                                            }
                                        </div>
                                    </div>

                                    <div className="row mb-2">
                                        <label htmlFor="dossier" className="col-sm-5 col-form-label">Subir Dossier, memoria o portafolio </label>
                                        <div className="col-sm-7">
                                            <input id="dossier" name="dossier" type="file" className={`form-control  ${props.errors.dossier ? "is-invalid" : ""}`} onChange={(event) => {
                                                props.touched.dossier = true;
                                                props.setFieldValue("dossier", event.currentTarget.files[0]);
                                                setFileDossier(URL.createObjectURL(event.target.files[0]));
                                            }} accept="application/pdf, application/x-pdf" />
                                            <ErrorMessage name="dossier" component={ValidationError} />
                                            {
                                                (props.errors.dossier && props.touched.dossier) || !fileDossier ? "" :

                                                    <div className="pt-2 mx-auto" >
                                                        <a href={fileDossier} target={'_blank'}> <FontAwesomeIcon className={'mx-1'} icon={faFile} /> Documento </a>
                                                    </div>

                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="submit" className="btn btn-danger btn-lg" disabled={loading}>Guardar <i className="fa-solid fa-arrow-right"></i></button>
                                </div>
                            </Form>
                        )}

                    </Formik>
                </div >
        }


        <div className="clear"></div>
    </div >)
}

export default EspacioCulturalEdit;