import { ErrorMessage, Field } from "formik";
import ValidationError from "../../utiles/ValidationError";

const TransporteOferta = (props) => {
    return (<div className="card">
        <div className="card-header">
            <strong>Detalle Información</strong>
        </div>
        <div className="card-body">
            <div className="row g-3">
                <div className="col-md-12">

                    <label htmlFor="pasajeros" className="form-label">Pasajeros <span className="rojo">*</span></label>
                    <Field name="pasajeros" as="select" className="form-control" >
                        <option value=''>Seleccionar</option>
                        <option value={'1'}>Si</option>
                        <option value={'0'}>No</option>
                    </Field>
                    <ErrorMessage name="pasajeros" component={ValidationError} />
                </div>

                <div className="col-md-12">
                    <label htmlFor="carga" className="form-label">Carga <span className="rojo">*</span></label>
                    <Field name="carga" as="select" className="form-control" >
                        <option value=''>Seleccionar</option>
                        <option value={'1'}>Si</option>
                        <option value={'0'}>No</option>
                    </Field>
                    <ErrorMessage name="carga" component={ValidationError} />
                </div>
            </div>
        </div>
    </div>)

}

export default TransporteOferta;