import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

const OfertaHeader = ({ oferta }) => {
    return (<div className="not-dest">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <img className="not-dest-img" src={oferta.imagen} alt="" />
                </div>

                <div className="col-md-6">
                    <span className="t-dest" style={{ marginBottom: "5px" }}>{oferta.nombreOferta}</span>
                    <ul className="cat-of" style={{ marginBottom: "20px", overflow: "hidden" }}>
                        {oferta.categorias ? oferta.categorias.map((values, index) => <li key={index}><Link to={`/busqueda?categoria=${values.id_categoria}&modo_categoria=true`} className='cat-of-a'>{values.categoria}</Link></li>) : ""}
                    </ul>

                    <p className="p-dest">{oferta.descripcion}</p>
                    <div className="ref-dest">
                        <img className="rounded-circle" src={oferta.imagen_perfil} alt="" />
                        <Link to={`/detalle-usuario/${oferta.idDirectorio}`} className="text-decoration-none">
                            <div>
                                <span>{oferta.creadoPor}</span>
                                {oferta.creadoPorEmail}
                            </div>
                        </Link>

                    </div>
                </div>
            </div>
        </div>

    </div>);
}

export default OfertaHeader;

OfertaHeader.propTypes = {
    oferta: PropTypes.object,
}

OfertaHeader.defaultProps = {
}