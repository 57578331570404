import { Fragment, useEffect, useState } from "react";

import backgroundSecciones2 from "../img/f2.png";
import Categorias from "../components/indexAssets/Categorias";
import Buscador from "../components/indexAssets/Buscador";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Convocatorias from "../components/indexAssets/Convocatorias";
import Ofertas from "../components/indexAssets/Ofertas";
import Cartelera from "../components/indexAssets/Cartelera";
import Estadisticas from "../components/indexAssets/Estadisticas";
import { setTitle } from "../constants/utiles/Functions";
import PropTypes from 'prop-types';
import { useGetCategoriasQuery, useGetRegionesQuery } from "../redux/features/ApiSlice";
import axiosApi from "../api/axios/axiosApi";
import { BUSQUEDA_GENERAL_URI, DIRECTORIO_TIPO_URI } from "../api/axios/constants";
import { TIPO_USUARIO_ORGANIZACION, TIPO_USUARIO_PERSONA } from "../constants/Constants";
import axios from "axios";
import Directorio from "../components/indexAssets/Directorio";


const Home = (props) => {
    const [listadoPersonas, setListadoPersonas] = useState([]);
    const [listadoOrganizaciones, setListadoOrganizaciones] = useState([]);
    const [ofertas, setOfertas] = useState([]);
    const navigate = useNavigate();

    const {
        data: regiones = [],
    } = useGetRegionesQuery();

    const {
        data: categorias = [],
    } = useGetCategoriasQuery();



    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        axiosApi.get(DIRECTORIO_TIPO_URI(TIPO_USUARIO_PERSONA), { cancelToken: cancelToken.token }).then(response => {
            if (response?.data?.error == false) {
                setListadoPersonas(response?.data?.data)
            } else {
                throw new Error(response.data.message);
            }

        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log(error);
            } else {
                console.error(error);
            }
        });


        const cancelTokenOrg = axios.CancelToken.source();
        axiosApi.get(DIRECTORIO_TIPO_URI(TIPO_USUARIO_ORGANIZACION), { cancelToken: cancelTokenOrg.token }).then(response => {
            if (response?.data?.error == false) {
                setListadoOrganizaciones(response?.data?.data)
            } else {
                throw new Error(response.data.message);
            }
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.log(error);
            } else {
                console.error(error);
            }
        });

        const cancelTokenOfertas = axios.CancelToken.source();
        axiosApi.get(BUSQUEDA_GENERAL_URI, { cancelToken: cancelTokenOfertas.token })
            .then(response => {
                setOfertas(response.data.data ? response.data.data.sort((a, b) => a.idOferta < b.idOferta ? 1 : -1) : []);
            }).catch((error) => {
                if (axios.isCancel(error)) {
                    console.log("cancelado");
                } else {
                    console.error(error)
                }
            });
        return () => {
            cancelToken.cancel();
            cancelTokenOrg.cancel();
            cancelTokenOfertas.cancel();
            setTitle(props.title)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goDirectorio = (id) => {
        navigate('/directorio', { state: { 'tipo': id } });
    }


    return (
        <Fragment>

            <Buscador categorias={categorias.data} regiones={regiones.data} />
            <Categorias categorias={categorias.data} />



            <Ofertas classDiv={'blanco blanco-fondo'} arrayOfertas={(ofertas.length > 0 ? ofertas.slice(0, 4) : [])}>
                <div className="row">
                    <div className="col">
                        <span className="t1b">Ofertas culturales</span>
                        <p className="p1b">Descubre la variedad y diversidad de propuestas artístico-culturales para la programación local en el territorio</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <Link to="/busqueda" className="masofertas rounded " style={{ cursor: 'pointer', textDecoration: 'none' }}>Más ofertas <FontAwesomeIcon style={{ marginLeft: '10px' }} icon={faArrowRight} /></Link>
                    </div>
                </div>
            </Ofertas>
            {
                ofertas.length > 4 ?

                    <Ofertas classDiv={'gris2'} arrayOfertas={(ofertas.length > 0 ? ofertas.slice(4, 8) : [])}>
                        <div className="row">
                            <div className="col">
                                <span className="t2b">Ofertas culturales</span>
                                <Link to="/busqueda" className="masofertas rounded">Más ofertas <FontAwesomeIcon style={{ marginLeft: '10px' }} icon={faArrowRight} /></Link>
                            </div>
                        </div>
                    </Ofertas> : ""

            }

            <Directorio
                classDiv={'gris'}
                backgroundImg={backgroundSecciones2}
                backgroundStyle={{ margin: '-560px 0 0 0', float: 'right' }}
                listado={listadoPersonas}>
                <div className="row">
                    <div className="col">
                        <span className="t1g">Catálogo de artistas, oferentes y programadores locales</span>
                        <p className="p1g">Conoce y contacta a quienes ofertan programación artístico-cultural en el territorio nacional</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <a onClick={() => goDirectorio(TIPO_USUARIO_PERSONA)} className="masofertas rounded mob" style={{ cursor: 'pointer' }}>Más contactos <FontAwesomeIcon style={{ marginLeft: '10px' }} icon={faArrowRight}></FontAwesomeIcon></a>
                    </div>
                </div>
            </Directorio>


            <Directorio classDiv={'negro'} listado={listadoOrganizaciones}>
                <div className="row">
                    <div className="col">
                        <span className="t2n">Catálogo de artistas, oferentes y programadores locales</span>
                        <a onClick={() => goDirectorio(TIPO_USUARIO_ORGANIZACION)} className="masofertas rounded mob" style={{ cursor: 'pointer' }}>Más contactos <FontAwesomeIcon style={{ marginLeft: '10px' }} icon={faArrowRight}></FontAwesomeIcon></a>
                    </div>
                </div>
            </Directorio>



            <Convocatorias />
            {/*<Cartelera />*/}
            <Estadisticas />

        </Fragment>
    )
}

export default Home;

Home.propTypes = {
    title: PropTypes.string,
}

Home.defaultProps = {
}