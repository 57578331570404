import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const HeaderUser = (props) => {
    const { isLogin } = useSelector((state) => state.userActive)


    return (<div className="row g-3 align-items-center">
        {
            isLogin === true ? <Fragment>
                <div className="col-auto">
                    <Link className="btn btn-outline-primary" to="/home-privado/inicio">Mi perfil </Link>
                </div>
            </Fragment> : <Fragment>
                <div className="col-auto">
                    <Link className="regt col-form-label" to="/registrar-usuario">Regístrate</Link>
                </div>
                <div className="col-auto">
                    <Link className="btn btn-outline-danger" to="/login">Ingresa</Link>
                </div>
            </Fragment>
        }


    </div>);

}


export default HeaderUser;


HeaderUser.propTypes = {
    isUserLogin: PropTypes.bool.isRequired,
}

HeaderUser.defaultProps = {
    isUserLogin: false,
}