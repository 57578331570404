import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useGetCategoriasQuery, useGetPreciosQuery, useGetRegionesQuery, useGetTipoPerfilesQuery } from '../../redux/features/ApiSlice';
import InputComuna from '../form/InputComuna';
import { Spinner } from 'react-bootstrap';


const FiltroForm = (params) => {
    const [categoria, setCategoria] = useState(params.categoria || '');
    const [region, setRegion] = useState(params.region || '');
    const [tipoPerfil, setTipoPerfil] = useState(params.agente || '');
    const [comuna, setComuna] = useState(params.comuna || '');
    const [palabra, setPalabra] = useState(params.palabra || '');
    const [rangoPrecio, setRangoPrecio] = useState(params.rangoPrecio || '');
    const [reload, setReload] = useState(false);

    const navigate = useNavigate();

    useEffect(() => { if (reload === true) { handleSubmit(); setReload(false); } }, [reload])

    const {
        data: arrayCategorias = [],
        isSuccess: categoriasSuccess
    } = useGetCategoriasQuery();

    const {
        data: arrayPrecios = [],
        isSuccess: preciosSuccess,
    } = useGetPreciosQuery()

    const {
        data: regiones = [],
        isSuccess: regionesSuccess,
    } = useGetRegionesQuery();

    const {
        data: perfiles = [],
        isSuccess: perfilesSuccess,
    } = useGetTipoPerfilesQuery();

    const handleLimpiar = () => {
        setCategoria('');
        setRegion('');
        setTipoPerfil('');
        setComuna('');
        setPalabra('');
        setRangoPrecio('');

        setReload(true);
    }

    const handleSubmit = () => {
        let data = {}
        if (tipoPerfil) {
            data.agente = tipoPerfil;
        }
        if (categoria) {
            data.categoria = categoria;
        }
        if (region) {
            data.id_region = region;
        }
        if (comuna) {
            data.comuna = comuna;
        }
        if (palabra) {
            data.palabra = encodeURIComponent(palabra);
        }

        if (rangoPrecio) {
            data.rango_precio = rangoPrecio;
        }

        params.setOrder('desc');
        navigate({
            pathname: "/busqueda",
            search: createSearchParams(data).toString()
        });
    }

    const handleTags = () => {

        return (<ul>
            {
                params.filtros ? params.filtros.map((values, index) => {
                    return <li key={index} className="rounded">{values.nombre} <a onClick={() => removeTag(values.input)} style={{ cursor: 'pointer' }}>x</a></li>
                }) : ""
            }
        </ul>)
    }

    const removeTag = (input) => {
        switch (input) {
            case "tipoPerfil":
                setTipoPerfil('');
                break;
            case "categoria":
                setCategoria('');
                break;
            case "region":
                setRegion('');
            case "comuna":
                setComuna('');
                break;
            case "rangoPrecio":
                setRangoPrecio('');
                break;
            default:
                break;
        }

        setReload(true);

    }


    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <div className="filtros rounded" >


            <div className="ft">
                <span><i className="fa-solid fa-filter"></i> Filtros</span>
            </div>
            <div className='animation_input'>
                <div className="loading_class" style={{ display: params.loading ? "" : "none" }}>
                    <div style={{ position: "relative", top: "50%" }}>
                        <div style={{ textAlign: "center" }} >
                            <Spinner ></Spinner>
                        </div>
                    </div>
                </div>
                <div className="ft2">
                    <p>Palabra clave</p>
                    <input type="text" className="form-control" name="palabra" id="palabra" placeholder="Texto a buscar" value={palabra} onChange={e => setPalabra(e.target.value)} onKeyDown={handleKeyDown} />
                </div>
                <div className="ft2">
                    <span onClick={handleSubmit} style={{ cursor: 'pointer' }} >Aplicar filtros</span>
                    <a className="ft2l pe-auto" style={{ cursor: 'pointer' }} onClick={handleLimpiar} >Limpiar todo</a>
                    <div className="clear"></div>
                    {
                        handleTags()
                    }


                    <div className="clear"></div>
                </div>


                <div className="ft3">

                    <select className="form-select" aria-label="Default select example" value={tipoPerfil} onChange={e => { setTipoPerfil(e.target.value); }}>
                        <option value={''}>Tipo de perfil</option>
                        {
                            perfilesSuccess ? perfiles.data.map((perfil, index) =>
                                <option key={index}
                                    value={perfil.idTipoPerfil}
                                >{perfil.tipoPerfil}</option>
                            ) : ""
                        }
                    </select>

                    <select className="form-select" aria-label="Default select example" value={categoria} onChange={e => { setCategoria(e.target.value); }}>
                        <option value={''}>Categoría</option>
                        {
                            categoriasSuccess ? arrayCategorias.data.map((categorias, index) =>
                                <option key={index}
                                    value={categorias.idCategoria}
                                >{categorias.categoria}</option>
                            ) : ""
                        }
                    </select>

                    <select className="form-select" aria-label="Default select example" value={region} onChange={e => {
                        setRegion(e.target.value);
                        setComuna('');
                    }}>
                        <option value={''}>Región</option>
                        {
                            regionesSuccess ? regiones.data.map((values, index) =>
                                <option key={index}
                                    value={values.idRegion}
                                >{values.region}</option>
                            ) : ""
                        }

                    </select>

                    <InputComuna region={region} handleComuna={setComuna} comuna={comuna}></InputComuna>

                    <select className="form-select" aria-label="Default select example" value={rangoPrecio} onChange={e => {
                        setRangoPrecio(e.target.value);
                    }}>
                        <option value={''}>Rango de precio</option>
                        {
                            preciosSuccess ? arrayPrecios.data.map((rango, index) =>
                                <option key={index}
                                    value={rango.idRango}
                                >{rango.rango}</option>
                            ) : ""
                        }

                    </select>
                </div>
            </div>

        </div>
    );
}

export default FiltroForm;

FiltroForm.propTypes = {
    arrayCategorias: PropTypes.array,
    loading: PropTypes.bool
}

FiltroForm.defaultProps = {
    arrayCategorias: [],
    loading: false
}