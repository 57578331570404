import { Route, RouterProvider, Routes } from "react-router-dom";
import Directorio from "../pages/Directorio";

import Home from "../pages/Home";
import NotFoundPage from "../pages/NotFoundPage";
import ResultadosBusqueda from "../pages/ResultadosBusqueda";
import Convocatorias from "../pages/Convocatorias";
import HomePrivado from "../pages/HomePrivado";
import Registro from "../pages/Registro";
import Login from "../pages/Login";
import ProtectedRoute from "./ProtectedRoutes";
import Ayuda from "../pages/Ayuda";
import Wrapper from "./Wrapper";
import Password from "../pages/Password";
import RegistrarUsuario from "../pages/RegistrarUsuario";
import LoginUsuario from "../pages/LoginUsuario";
import CatalogoNoticias from "../pages/CatalogoNoticias";
import Noticia from "../pages/Noticia";
import Catalogos from "../pages/Catalogos";
import Recursos from "../pages/Recursos";
import Convocatoria from "../pages/Convocatoria";
import UsuarioDetalle from "../pages/UsuarioDetalle";
import Oferta from "../pages/Oferta";
import OfertaAdmin from "../pages/OfertaAdmin";


const SiteRoutes = () => {
    return (
        <Routes>
            <Route element={<Wrapper />}>
                <Route path='/' element={<Home />} />
                <Route path='/directorio' element={<Directorio title='Directorio' />} />
                <Route path='/convocatorias' element={<Convocatorias title='Convocatorias' />} />
                <Route path='/convocatoria/:id_convocatoria' element={<Convocatoria title='Convocatoria' />} />
                <Route path='/agenda' element={<Home title='Agenda' />} />
                <Route path='/contenidos-apoyo' element={<Home title='Contenidos de apoyo' />} />
                <Route path='/novedades' element={<Home title='Novedades' />} />
                <Route path='/busqueda' element={<ResultadosBusqueda title='Busqueda' />} />
                <Route path='/catalogo-noticias' element={<CatalogoNoticias title='Catalogo de Noticias' />} />
                <Route path='/noticia/:id_noticia' element={<Noticia title='Noticia' />} />
                <Route path='/catalogos' element={<Catalogos title='Catalogos' />} />
                <Route path='/detalle-usuario/:id_directorio' element={<UsuarioDetalle title='Usuario' />} />
                <Route path='/oferta/:id_oferta' element={<Oferta title='Oferta' />} />



                <Route element={<ProtectedRoute />}>
                    <Route path="/home-privado/:active_tab" element={<HomePrivado />} />
                    <Route path="/home-privado/:active_tab/:id_extra" element={<HomePrivado />} />
                    <Route path="/home-privado" element={<HomePrivado />} />
                </Route >


                <Route path='/conocenos' element={<Home />} />
                <Route path='/recursos' element={<Recursos title='Recursos' />} />
                <Route path='/ayuda/:active_tab' element={<Ayuda />} />

            </Route>

            <Route element={<ProtectedRoute />}>
                <Route path='/registrarse' element={<Registro title='Registro usuario' />} />
            </Route >
            <Route path='/login' element={<Login title='Iniciar sesión' />} />
            <Route path='/login-usuario' element={<LoginUsuario title='Iniciar sesión' />} />
            <Route path='/registrar-usuario' element={<RegistrarUsuario title='Registro usuario' />} />
            <Route path='/recuperar' element={<Password title='Recuperar contraseña' />} />
            

            <Route path='/admin/ver-pre-oferta/:id_oferta' element={<OfertaAdmin title='Visualización oferta' />} />
            

            <Route path='*' element={<NotFoundPage />} />


        </Routes>
    );
}
export default SiteRoutes;